<div class="createTemplate">
    <div class="heading blue">
        <i class="fa fa-th clickable" routerLink="/admin"></i>
        <span class="clickable" routerLink="/admin/template">{{'TEMPLATE.TITLE' | translate}}</span>
        <span *ngIf="location.path().indexOf('create') > -1">
      <i class="fa fa-caret-right"></i>
      <span>{{'TEMPLATE.CREATE_HEADER' | translate}}</span>
        </span>
        <span *ngIf="location.path().indexOf('edit') > -1">
      <i class="fa fa-caret-right"></i>
      <span>{{'TEMPLATE.EDIT' | translate}}</span>
    </span>
        <span *ngIf="templateService.templateType">
      <i class="fa fa-caret-right"></i>
      <span>{{'TEMPLATE.' + templateService.templateType?.toUpperCase() | translate}}</span>
    </span>
    </div>
    <div class="panel-content center-content white-background">
        <div class="row">
            <div class="col">
                <label class="font-weight-bold">{{'TEMPLATE.' + templateService.templateType?.toUpperCase() |
                    translate}}</label>
                <form name="templateForm" [formGroup]="templateForm">
                <app-material-form-input [text]="newTemplate?.ipc"
                                         [showValidation]="true"
                                         [validationErrorMessage]="'VALIDATION.ERROR_NOT_EMPTY' | translate"
                                         (textChange)="newTemplate.ipc = $event"
                                         [label]="'CART.ADD_ARTICLE_POPUP.NUMBER' | translate">
                </app-material-form-input>
                <app-material-form-input [text]="newTemplate?.description"
                                         [showValidation]="true"
                                         [validationErrorMessage]="'VALIDATION.ERROR_NOT_EMPTY' | translate"
                                         (textChange)="newTemplate.description = $event"
                                         [label]="'CART.ADD_ARTICLE_POPUP.DESCRIPTION' | translate">
                </app-material-form-input>
                </form>
                <div class="row">
                    <div class="col">
                        <form name="templateForm" [formGroup]="templateForm">
                        <app-material-form-input [text]="newTemplate?.quantity"
                                                 [showValidation]="true"
                                                 [validationErrorMessage]="'VALIDATION.ERROR_NOT_EMPTY' | translate"
                                                 [type]="'number'"
                                                 (textChange)="checkValidQuantity($event)"
                                                 [label]="'CART.ADD_ARTICLE_POPUP.QUANTITY' | translate">
                        </app-material-form-input>
                        </form>
                    </div>
                    <div class="col dropdown-padding-top"
                         *ngIf="newTemplate?.type === 'ARTICLE' || newTemplate?.type === 'TEXT'">
                        <div class="custom-dropdown clickable no-margin">
                            <select class="clickable" [(ngModel)]="newTemplate.quantityUnit">
                                <option selected [value]="'PIECE'">{{'CART.PIECE' | translate}}</option>
                                <option [value]="'LITER'">{{'CART.LITER' | translate}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col dropdown-padding-top" *ngIf="newTemplate?.type === 'AW'">
                        <div class="custom-dropdown clickable no-margin">
                            <select class="clickable" [(ngModel)]="newTemplate.quantityUnit">
                                <option selected [value]="'HOUR'">{{'CART.HOUR_FULL' | translate}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <form name="templateForm" [formGroup]="templateForm">

                <app-material-form-input [text]="newTemplate?.sellOutNetPerPiece"
                                         *ngIf="newTemplate?.type !== 'AW'"
                                         [showValidation]="true"
                                         [validationErrorMessage]="'VALIDATION.ERROR_NOT_EMPTY_NUMBER' | translate"
                                         (textChange)="checkValidPrice($event)"
                                         [type]="'number'"
                                         [label]="'CART.ADD_ARTICLE_POPUP.SINGLE_PRICE' | translate">
                </app-material-form-input>
                </form>

                <div class="col dropdown-padding-top type-aw" *ngIf="newTemplate?.type === 'AW' && firmData">
                    <div class="custom-dropdown clickable no-margin">
                        <select class="clickable" [(ngModel)]="newTemplate.sellOutNetPerPiece">
                            <option [value]="undefined" selected>{{'GENERAL.PLEASE_SELECT' | translate }}</option>
                            <option [value]="firmData.hourlyWageRate">{{firmData.hourlyWageRate | currency:helperService.getCurrency(firmData.hourlyWageRate)}} {{'USER_PANEL.HOURLY_RATE' | translate}}</option>
                            <option [value]="firmData.hourlyWageRateChassis">{{firmData.hourlyWageRateChassis | currency:helperService.getCurrency(firmData.hourlyWageRateChassis)}} {{'USER_PANEL.HOURLY_RATE_CHASSIS' | translate}}</option>
                            <option [value]="firmData.hourlyWageRateElectronic">{{firmData.hourlyWageRateElectronic | currency:helperService.getCurrency(firmData.hourlyWageRateElectronic)}} {{'USER_PANEL.HOURLY_RATE_ELECTRONIC' | translate}}</option>
                            <option [value]="firmData.hourlyWageRateMechanic">{{firmData.hourlyWageRateMechanic | currency:helperService.getCurrency(firmData.hourlyWageRateMechanic)}} {{'USER_PANEL.HOURLY_RATE_MECHANIC' | translate}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col buttons">
                <button class="base-btn pull-right" routerLink="/admin/template/{{templateService.templateType}}">
                    <span>{{'PROMPTS.BUTTONS.GO_BACK' | translate}}</span>
                </button>
                <button class="base-btn success mt-0" (click)="templateService.createNewTemplate(newTemplate)">
                    <span>{{'PROMPTS.BUTTONS.SAVE' | translate}}</span>
                </button>
            </div>
        </div>
    </div>
</div>
