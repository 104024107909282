import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {ICarSearchFields} from './interfaces/car-search-fields';
import {CarRestService} from '../../services/car/car.rest.service';
import {CarService} from '../../services/car/car.service';
import {IModalOptions} from '../../interfaces/modal-options';
import {Router} from '@angular/router';
import {ArticlesService} from '../../../articles/services/articles.service';
import {Observable} from 'rxjs';
import {UserService} from '../../services/user/user.service';
import {ConfigService} from '../../services/config.service';
import {DmsDataService} from '../../services/dms-data.service';
import {CustomerDataService} from '../../services/customerData.service';
import {ThemeService} from '../../services/theme.service';
import {AutovistaRestService} from '../../../graphical-part-search/services/autovista.rest.service';
import {ICar} from '../../interfaces/DTO/car/car';
import {IVinCarWrapper} from '../../interfaces/DTO/car/vinSearchResponse';
import {CartService} from '../../../cart/services/cart.service';
import {HelperService} from '../../services/helper.service';

@Component({
    selector: 'app-car-selection',
    templateUrl: './car-selection.component.html',
    styleUrls: ['./car-selection.component.scss']
})

export class CarSelectionComponent implements AfterViewInit {

    @Input() newRoute: string;
    @Input() noCarSelected: boolean;
    @Input() noMaintenance: boolean | false;
    @Output() onChange: any;

    private carSearchFields: ICarSearchFields = {
        hsn: '',
        tsn: '',
        producer: null,
        model: null,
        type: null,
        vin: '',
        motorCode: '',
        tecDocNumber: null
    };


    public selected$: Observable<string>;

    private before: any = undefined;
    public vinCarWrapper: IVinCarWrapper;
    public carList: ICar[] = [];
    public showCarSelectionModal: boolean = false;
    public showCarSelectionModalVIN: boolean = false;

    public tsnFocus: boolean = false;

    constructor(private router: Router,
                private carRestService: CarRestService,
                public carService: CarService,
                private articlesService: ArticlesService,
                public configService: ConfigService,
                public userService: UserService,
                public dmsDataService: DmsDataService,
                public dataService: CustomerDataService,
                private helperService: HelperService,
                public themeService: ThemeService,
                public cartService: CartService,
                private autovistaRestService: AutovistaRestService) {

        this.carService.setCarExternal$.subscribe((data: any) => {
            this.carService.fromDMS = true;
            if (data) {
                if (data.vin) {
                    this.dataService.setVin(data.vin);
                }
                if (data.typenumber) {
                    this.dataService.setTypenumber(data.typenumber);
                }
                if (data.natcode) {
                    this.dataService.setNatcode(data.natcode);
                }
                if (data.kTypeId) {
                    this.dataService.setKTypeId(parseInt(data.kTypeId));
                }
                if (data.hsn && data.tsn) {
                    this.dataService.setHsn(data.hsn);
                    this.dataService.setTsn(data.tsn);
                }
                if (data.numberplate) {
                    this.dataService.setNumberplate(data.numberplate);
                }
                if (data.registrationDate) {
                    const date = new Date(data.registrationDate);
                    this.dataService.setRegistrationDate(date);
                }
            } else {
                this.carService.fromDMS = false;
            }
        });

        this.carService.showModalEmitter.subscribe(
            (show) => {
                this.showCarSelectionModal = show;
                this.carService.modalHidden = !show;
            }
        )
    }

    ngAfterViewInit() {
    }


    // HELPER
    public checkSwitch(hsn: any, tsn: any, hsnTsn: any) {
        this.tsnFocus = false;
        hsn.inputField.nativeElement.maxLength = 4;
        if (hsn.text.length === 4) {
            this.tsnFocus = true;
        }
        this.activateButton(hsnTsn, hsnTsn);
    }

    public activateButton(button: any, button_old: any) {
        if (this.before === undefined) {
            this.before = button_old;
            this.before.disabled = true;
        } else {
            this.before.disabled = true;
        }
        button.disabled = false;
        this.before = button;
    }

    private resetFields() {
        this.carSearchFields = {
            hsn: '',
            tsn: '',
            producer: null,
            model: null,
            type: null,
            vin: '',
            motorCode: '',
            tecDocNumber: null
        };
    }

    private resetModal() {
        this.resetFields();
        this.showCarSelectionModalVIN = false;
        this.showCarSelectionModal = false;
    }

    private hideModal() {
        this.showCarSelectionModal = false;
        this.carService.modalHidden = true;
    }

    // END HELPER


    public getMode() {
        return this.carService.dmsNoCatalog ? 'DMS' : 'STANDARD';
    }

    public selectVinCar(vinCarWrapper: IVinCarWrapper) {
        this.showCarSelectionModalVIN = true;
        this.vinCarWrapper = vinCarWrapper;
    }

    public applyVinCarSelection(car: any) {
        this.showCarSelectionModalVIN = false;
        setTimeout(() => {
            car.vin = this.vinCarWrapper.vin;
            car.ikeCode = this.vinCarWrapper.ikeCode;
            car.natCode = this.vinCarWrapper.natCode;
            car.typeId = this.vinCarWrapper.typeId;
            this.carService.setSelectedVinCar.emit(car);
        }, 50);
    }


    public applyCar(car: ICar) {
        if (car) {
            this.showCarSelectionModal = false;
            this.carService.selectCar(car);
            if (this.newRoute && !(this.newRoute.trim() === '')) {
                this.router.navigate([this.newRoute]);
            } else {
                if (this.configService.getEntryPoint()) {
                   void this.router.navigate([this.configService.getEntryPoint()]);
                } else {
                   void this.router.navigate(['/articles']);
                }
            }
            if (this.carService.carHistory) {
                this.carService.addHistoryEntry(car);
            } else {
                this.carService.toCarHistory = car;
            }
        }
    }

    public applyCars(cars: ICar[], fromDMS?: boolean) {
        if (!cars.length) {
            cars = cars['carDtos'];
        }
        if (cars && cars.length === 0) {
            this.helperService.showNotification('TOAST_MESSAGES.ERROR_NO_CAR_FOUND', 'error');
            this.carService.noCarData = true;
            return;
        }

        if (cars && cars.length === 1) {
            this.carService.noCarData = false;
            this.showCarSelectionModal = false;
            this.carService.selectCar(cars[0]);
            if (this.newRoute && !(this.newRoute.trim() === '')) {
                this.router.navigate([this.newRoute]);
            } else if (!this.userService.getCatalogMode() || (this.userService.getCatalogMode() && !this.carService.selectedCar.fromDMS) ||
                (this.userService.getCatalogMode() && this.carService.selectedCar.autovistaVinResponse)) {
                if (this.configService.getEntryPoint()) {
                    this.router.navigate([this.configService.getEntryPoint()]);
                } else {
                    this.router.navigate(['/articles']);
                }
            }
            if (this.carService.carHistory) {
                this.carService.addHistoryEntry(cars[0]);
            } else {
                this.carService.toCarHistory = cars[0];
            }
        } else if (cars && cars.length > 1) {
            this.carList = cars.sort(function (a, b) {
                return (a.displayName > b.displayName) ? 1 : ((b.displayName > a.displayName) ? -1 : 0);
            }) || [];

            if (this.carList.length === 0) {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_NO_CAR_FOUND', 'error');
            } else {
                if (!this.carService.fromDMS && !this.carService.modalHidden) {
                    this.showCarSelectionModal = true;
                }
            }
        }
        this.resetFields();
    }
}
