<div class="heading blue" *ngIf="location.path().startsWith('/company-admin/orderhistory')">
    <span class="clickable" (click)="orderHistoryService.reset()" routerLink="/company-admin/orderhistory">
      <i class="fa fa-th"></i>
      <span>{{ 'CART.ORDER_HISTORY' | translate }}</span>
    </span>
    <span *ngIf="orderHistoryService?.selectedHistory !== 'orderhistory'"class="clickable"
          [routerLink]="'/company-admin/orderhistory/' + orderHistoryService?.selectedHistory">
      <i class="fa fa-caret-right"></i>
      <span>{{ 'CART.HISTORY.' + orderHistoryService?.selectedHistory?.toUpperCase() | translate }}</span>
    </span>
    <span class="clickable">
      <i class="fa fa-caret-right"></i>
      <span>{{ orderHistoryService?.selectedCart?.name }}
          ({{ orderHistoryService?.selectedCart?.created | date:'dd.MM.yyyy' }})</span>
    </span>
</div>

<div class="panel-content center-content print-content" style="overflow-y: scroll !important;">
    <div class="text-left" style="position: absolute; right: 10px;">
        <button class="base-btn" (click)="downloadPdf()">
            <i class="fa fa-save"></i> <span>{{ 'PRINT_VIEW.GENERATE_PDF' | translate }}</span>
        </button>

        <button class="base-btn" *ngIf="!helperService.browserIsEdge() && !helperService.browserIsIE()"
                (click)="printPdf()" style="margin-left: -3px;">
            <i class="fa fa-print"></i> <span>{{ 'PRINT_VIEW.PRINT' | translate }}</span>
        </button>

        <button class="base-btn" (click)="back()" style="margin-left: -3px; width: 40px;">
            <i class="fa fa-times"></i>
        </button>
    </div>

    <div class="print-preview" style="top: 20px !important;">
        <app-default-loading-page *ngIf="pdfLoading"></app-default-loading-page>
        <pdf-viewer
                [src]="pdf"
                [autoresize]="false"
                [render-text]="false"
                [show-all]="true"
                style="display: block;"
        ></pdf-viewer>
    </div>
</div>
