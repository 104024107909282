import {Component, OnInit, Input, EventEmitter, Output} from '@angular/core';

@Component({
    selector: 'app-toggle-switch',
    templateUrl: './toggle-switch.component.html',
    styleUrls: ['./toggle-switch.component.scss']
})
export class ToggleSwitchComponent implements OnInit {
    @Input() public isChecked: boolean = false;
    @Input() public textLeft: string;
    @Input() public textRight: string;
    @Input() public isDisabled: boolean = false;
    @Input() public size?: string = 'DEFAULT';
    @Output() private onChange: EventEmitter<boolean> = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

    public onValueChange() {
        if (!this.isDisabled) {
            this.isChecked = !this.isChecked;
            this.onChange.emit(this.isChecked);
        }
    }
}
