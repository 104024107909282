import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {fadeSmallAnimation} from "../../shared/animations/fadesmall.animation";
import {CompanyAdminService} from './services/company-admin.service';
import {OrderHistoryService} from './order-history/services/order-history.service';
import {UserService} from '../../shared/services/user/user.service';
import {AdminService} from '../club/services/admin.service';
import {ConfigService} from '../../shared/services/config.service';
import {AssortmentService} from "../assortment/services/assortment.service";
import {Location} from '@angular/common';

@Component({
    selector: 'app-company-admin',
    templateUrl: './company-admin.component.html',
    animations: [fadeSmallAnimation]
})
export class CompanyAdminComponent implements OnInit {
    constructor(private router: Router,
                public companyAdminService: CompanyAdminService,
                public orderHistoryService: OrderHistoryService,
                public assortmentService: AssortmentService,
                public userService: UserService,
                private adminService: AdminService,
                public location: Location,
                public configService: ConfigService) {
        this.userService.accountData$.subscribe((acc) => {
            if(acc) {
                this.companyAdminService.getFirmVinStatistics();
            }
        });
    }

    public getRouteAnimation(outlet) {
        return outlet.activatedRouteData.state;
    }

    ngOnInit() {
        this.companyAdminService.firmStatistics = undefined;
        this.adminService.getClub();
        this.companyAdminService.updateFirmData();
        this.companyAdminService.getFirmVinStatistics();
    }
}
