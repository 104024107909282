import {EventEmitter, Injectable} from '@angular/core';
import {UserService} from '../../shared/services/user/user.service';
import {CarService} from '../../shared/services/car/car.service';
import {TecRmiRestService} from '../../shared/services/tecrmi/tecrmi.rest.service';
import {CartRestService} from '../../cart/services/cart.rest.service';
import {TranslateService} from '@ngx-translate/core';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import {CartService} from '../../cart/services/cart.service';
import {ICooGroups} from '../interfaces/cooGroups';
import {IServiceProposal} from '../interfaces/serviceProposal';
import {ArticlesRestService} from '../../articles/services/articles.rest.service';
import {ArticlesDetailsService} from '../../shared/services/articles-details.service';
import {PdfService} from '../../shared/services/pdf.service';
import {CompanyAdminService} from '../../admin/company/services/company-admin.service';
import {Router} from '@angular/router';
import {IArticlesWorkId} from '../../articles/interfaces/articlesWorkId';
import {CustomerDataService} from '../../shared/services/customerData.service';
import {ICar} from '../../shared/services/car/interfaces/car';
import {HelperService} from '../../shared/services/helper.service';
import {IMaintenancePlanMailRequest} from '../../shared/interfaces/DTO/maintenancePlanMailRequest';
import {IManualMainGroup} from '../../shared/interfaces/DTO/manualMainGroup';
import {ICartLabourWithParts} from '../../shared/interfaces/DTO/cart/cartLabourWithParts';
import {IMdSubGroupWorks} from '../../shared/interfaces/DTO/mdSubGroupWorks';
import {IVehicleSpecSubGroup} from '../../shared/interfaces/DTO/car/vehicleSpecSubGroup';
import {ICalculationCost} from '../../shared/interfaces/DTO/calculationCost';
import {IItem} from '../../articles/interfaces/article';
import {StateService} from '../../shared/services/state.service';
import {ToastrService} from 'ngx-toastr';
import {ConfigService} from '../../shared/services/config.service';
import {ArticlesService} from "../../articles/services/articles.service";


@Injectable()
export class MaintenanceService extends ArticlesDetailsService {

    private readonly defaultPageSize: number = 20;
    private mailTime: Date;
    private onCartChangeTime: Date;
    private posByCartTime: Date;
    private updateFilterTime: Date;
    private isInPseudoView: boolean;
    public nxDetails: boolean;
    public workPos: any;
    public showPdf: boolean;
    public pdfLoading: boolean;
    public pdf: any;
    public showAdditionalWork = true;
    public servicePlanHTML: any;
    public manual: IManualMainGroup[];
    public loadMaintenance: boolean;
    public maintenance: IMdSubGroupWorks;
    public maintenanceSteps: any;
    public activeMaintenanceStep: any;
    public selectedOption: any;
    public activeMaintenance: any;
    public manualUrl: any;
    public maintenanceLoading: any = false;
    public serviceplanUrl: string;
    public tecRMICalculationUrl: string;
    public posByCar: ICartLabourWithParts[];
    public gearBox: string[];
    public hasArticles: boolean;
    public selectedItem: any;
    public articleCount: number = 0;
    public maintenanceSpecs: IVehicleSpecSubGroup[];
    public foldedCollapsePanel: string[] = [];
    public isAvailable: boolean;
    public loadAvailable: boolean;
    public selectNewStep: EventEmitter<any> = new EventEmitter<any>();
    public onLoadedManuals: EventEmitter<any> = new EventEmitter<any>();
    public onToggleState: EventEmitter<any> = new EventEmitter<any>();
    public showGallery: boolean;
    public selectedUrl: any;
    public maintenanceHeaderOpen: boolean = true;
    public selectedAmount: any;
    public articleList = [];
    public articles: IArticlesWorkId = {
        adcTypeId: 0,
        workId: 0,
        pageSize: 0,
        linkingTargetType: '',
        itemMpId: 0,
        kTypeId: 0,
        page: 1
    };
    public showButton = false;
    public showModal = false;
    public showDependencyError = false;
    public tempWork: any;
    public filterGroupNames: string[] = [];
    public showLetterHead: boolean = false;
    public showCarGraphic: boolean = false;
    public cooGroups: ICooGroups[];
    public cooGroupsError: boolean;
    public serviceProposal: IServiceProposal;
    public selectedGear: string;
    public activeCoo: ICooGroups;
    public selectedCarInfos: any;
    public lastState: string = '';
    public maintenanceChangeInterval: IVehicleSpecSubGroup[];
    public selectedTab: any;
    public selectFirstTabEmitter: EventEmitter<any> = new EventEmitter<any>();
    public showDocumentWithLink: any;
    public byWorkIdTimer: number;
    public selectedPseudoArticle: IItem;
    public showPseudoArticle = false;
    public loadServiceplan = false;
    public calculationCostHolder: Map<number, ICalculationCost> = new Map<number, ICalculationCost>();


    constructor(public userService: UserService,
                public carService: CarService,
                private tecRmiRestService: TecRmiRestService,
                private cartRestService: CartRestService,
                private translate: TranslateService,
                private toastr: ToastrService,
                public cartService: CartService,
                public pdfService: PdfService,
                private articlesRestService: ArticlesRestService,
                private companyAdmin: CompanyAdminService,
                private router: Router,
                private customerDataService: CustomerDataService,
                private stateService: StateService,
                private helperService: HelperService,
                private config: ConfigService,
                private articleService: ArticlesService) {
        super(articlesRestService, carService, userService, companyAdmin, translate, toastr, config);

        this.userService.accountData$.subscribe((step) => {
            if (step) {
                this.fullReset();
                this.startUpCheck();
            }
        });

        this.articleService.isInPseudoArticleView$.subscribe((isPseudovView) => this.isInPseudoView = isPseudovView);

        this.cartService.dateEmitter.subscribe((date) => {
            if (this.customerDataService.getDataHolder()) {
                this.customerDataService.setRegistrationDate(date);
            }
        });

        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        this.startUpCheck();
        this.expanded_right = false;
        this.carService.selectedCarChanged$.subscribe(state => {
            this.getManualAvailable();
            this.calculationCostHolder.clear();
            if (state === null) {
                this.fullReset();
            } else {
                this.loadMaintenance = true;
                this.stateService.changeMaintenanceContent('LOADING');
                this.startUpCheck();
            }
            this.servicePlanHTML = '';
            this.serviceplanUrl = '';
            this.tecRMICalculationUrl = '';
            this.showPdf = false;

        });

        this.cartService.onCartChange.subscribe((change) => {
            if (!this.onCartChangeTime) {
                this.onCartChangeTime = new Date();
                if (change) {
                    this.reset();
                } else {
                    this.showModal = false;
                    this.getPosByCart();
                }
            } else {
                const temp = new Date();
                if (temp.getTime() - this.onCartChangeTime.getTime() > 500) {
                    this.onCartChangeTime = temp;
                    if (change) {
                        this.reset();
                    } else {
                        this.showModal = false;
                        this.getPosByCart();
                    }
                }
            }

        });
        this.cartService.onItemRemove.subscribe((step) => {
            if (this.router.url.indexOf('/plan') > -1) {
                this.getPosByCart();
            }
        });

        this.carService.selectedCarChanged$.subscribe((change) => {
            if (this.router.url.indexOf('/maintenance') > -1 && !this.router.url.endsWith('/maintenance')) {
                this.router.navigate(['/maintenance']);
            }
            this.fullReset();
        });

        this.companyAdmin.firmSettingEmitter.subscribe((response) => {
            const firmSettings = response;
            if (firmSettings && firmSettings.servicePlanShowHeader.value === true) {
                this.showLetterHead = true;
            }
            if (firmSettings && firmSettings.servicePlanShowCar.value === true) {
                this.showCarGraphic = true;
            }
        });
    }

    public openCatData(data: any) {
        this.selectedUrl = this.tecRmiRestService.getTechnicalcManual(this.carService.getAdcTypeId(), data.manualId);
    }

    private validMail(mail: string) {
        if (!mail || mail.trim().length === 0) {
            return false;
        }

        if (mail.indexOf('@') < 0) {
            return false;
        }

        let tempAt = mail.substring(mail.indexOf('@') + 1);
        if (tempAt.indexOf('.') < 0) {
            return false;
        }

        let tempDot = mail.substring(mail.lastIndexOf('.') + 1);
        if (tempDot.trim().length === 0) {
            return false;
        }

        return true;
    }

    public getBacklink() {
        return '/maintenance/plan'
    }

    public sendPerMail(mail: string) {

        if (!this.mailTime || new Date().getTime() - this.mailTime.getTime() > 500) {

            this.mailTime = new Date();

            if (!mail || mail.trim().length === 0) {
                mail = this.userService.accountData.email;
            }

            if (this.validMail(mail)) {
                const serviceplan: IMaintenancePlanMailRequest = {
                    kTypeId: this.carService.getKTypeId(),
                    adcTypeId: this.carService.getAdcTypeId(),
                    customer: this.customerDataService.getCustomerFirstName() + ' ' + this.customerDataService.getCustomerLastName(),
                    numberplate: this.customerDataService.getNumberplate(),
                    mileage: (this.customerDataService.getMileage() ? this.customerDataService.getMileage() : '').toString(),
                    mdQualColId: 0,
                    ordernumber: this.customerDataService.getOrderNumber().toString(),
                    sendTo: mail,
                    showLetterHead: this.showLetterHead,
                    showCarGraphic: this.showCarGraphic,
                    phone: this.customerDataService.getCustomerPhone()
                };

                this.tecRmiRestService.sendWorkPlanByMail(serviceplan).subscribe(
                    (success) => {
                        this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_SEND_MAIL', 'success');
                    },
                    (error) => {
                        this.helperService.showNotification('TOAST_MESSAGES.ERROR_SEND_MAIL', 'error');
                    }
                );
            } else {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_MAIL_NOT_VALID', 'error');
            }

        }
    }

    public resetSelectedUrl() {
        this.selectedUrl = undefined;
    }

    public getMaintenanceManualTree() {
        if (this.userService.allow('PRO')) {
            this.tecRmiRestService.getMaintenanceManualTree(this.carService.getAdcTypeId()).subscribe(
                (response) => {
                    this.manual = response;
                    this.onLoadedManuals.emit();
                    this.stateService.changeMaintenanceContent('CONTENT');
                },
                (error) => {
                    this.stateService.maintenanceContent.next('TECHNICAL_ERROR');
                });
        }
    }

    public hasSelectedMaintenance() {
        if (this.activeMaintenanceStep) {
            return true;
        }
        return false;
    }

    public getFilterGroupNames() {
        this.filterGroupNames = [];
        for (const filter of this.searchResult.activeFilters) {
            if (this.filterGroupNames.indexOf(filter.groupName) < 0) {
                this.filterGroupNames.push(filter.groupName);
            }
        }

        this.filterGroupNames.sort();
    }


    public hasSelectedAdditionalMaintenance() {
        if (this.hasSelectedMaintenance()) {
            if (this.maintenance && this.maintenance.mdAddItemMpKorWorksDTO) {
                for (const korWork of this.maintenance.mdAddItemMpKorWorksDTO) {
                    for (const subWork of korWork.mdWorkPos) {
                        if (this.isChecked(subWork) > -1) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

    public getMaintenanceDetails(_selectedItem: any) {
        this.userService.setNavigationRight();
        this.selectedItem = _selectedItem;
        this.tecRmiRestService.getMaintenanceSteps(this.carService.getAdcTypeId(), _selectedItem.itemMpId, _selectedItem.korId, 0).subscribe(
            (response) => {
                for (const workPos of response) {
                    if (this.selectedItem && workPos.itemQualifierId === this.selectedItem.qualColId) {
                        this.selectedItem.workPositions = workPos.workPositions;
                        this.selectedItem.optionalWorkPositions = workPos.optionalWorkPositions;
                    }
                }
            });


        this.tecRmiRestService.getWorkManuals(this.carService.selectedCar.adcTypeId, this.selectedItem.workId).subscribe((workManuals) => {
            if (this.selectedItem) {
                this.selectedItem.documents = workManuals;
            }
        });
    }

    public onMessageBtnClick(event: any, isDelete: boolean) {
        if (isDelete) {
            switch (event.index) {
                // Abbrechen
                case 0: {
                    this.showDependencyError = false;
                    break;
                }
                // Nein
                case 1: {
                    this.showDependencyError = false;
                    this.showModal = false;
                    this.removeWorkFromCart(this.tempWork, 'DELETE_WITHOUT_DEPENDENT');
                    break;
                }
                // Ja
                case 2: {
                    this.showDependencyError = false;
                    this.showModal = false;
                    this.removeWorkFromCart(this.tempWork, 'DELETE_WITH_DEPENDENT');
                    break;
                }
            }
        } else {
            switch (event.index) {
                // Nein
                case 0: {
                    this.showModal = false;
                    break;
                }
                // Ja
                case 1: {
                    this.showModal = false;
                    this.router.navigate(['/cart']);
                    break;
                }
            }
        }
    }

    public setSelectedCarInfos(carInfos: any) {
        this.selectedCarInfos = carInfos;
    }

    public showArticle() {
        this.selectedTab = undefined;
        const tempArticleList = this.articleList;
        this.articleList = [];
        for (const pos of this.posByCar) {
            for (const article of pos.partGroup) {
                tempArticleList.forEach(function (element) {
                    if (element.mountPositionId === article.mountPositionId && element.workId === article.workId) {
                        if (element.disabled === true) {
                            article.disabled = true;
                        }
                    }
                });
                this.articleList.push(article);
            }
        }
        if (this.articleList.length === 0) {
            this.showModal = true;
        }
        this.showAdditionalWork = false;
    }

    public reset() {
        this.selectedItem = undefined;
        this.loadMaintenance = false;
        this.maintenanceSteps = undefined;
        this.selectedOption = '';
        this.resultItems = [];
        this.infinityLoading = false;
        this.showModal = false;
        this.articleList = [];
        this.showDependencyError = false;
        this.activeMaintenanceStep = undefined;
        this.activeMaintenance = undefined;
        this.showAdditionalWork = true;
        this.maintenanceChangeInterval = undefined;
    }

    public loadNext(event: any) {

        setTimeout(() => {
            if (this.isInPseudoView) {
                return
            }
            if (event !== 0 && !this.infinityLoading && this.searchResult) {
                if (this.userService.enableInfiniteScroll && (this.searchResult.count / this.defaultPageSize) > (this.searchResult.page)) {
                    this.updateArticleSearch(this.searchResult.page + 1, this.searchResult.activeFilters, this.searchResult.searchForAllArticles);
                }
            }
        }, 250)
    }

    public resetSuggestion() {
        this.cooGroups = [];
        this.activeCoo = undefined;
        this.activeCoo = undefined;
        this.serviceProposal = undefined;
        this.selectedGear = '';
    }

    public fullReset() {
        this.lastState = '/maintenance';
        this.reset();
    }

    public getServiceplan() {
        this.showLoadingAnimation();
        this.serviceplanUrl = this.tecRmiRestService.getWorkPlan(this.carService.getKTypeId(),
            this.carService.getAdcTypeId(),
            this.customerDataService.getCustomerFirstName() + ' ' + this.customerDataService.getCustomerLastName(),
            this.customerDataService.getNumberplate(),
            (this.customerDataService.getMileage() ? this.customerDataService.getMileage() : ''),
            0,
            this.customerDataService.getOrderNumber(), this.customerDataService.getCustomerPhone(), this.showLetterHead, this.showCarGraphic, this.translate.instant('GENERAL.BACK'));
    }

    private showLoadingAnimation() {
        this.loadServiceplan = true;
        setTimeout(() => {
            this.loadServiceplan = false;
        }, 1500);
    }

    public getTecRMICalculation() {
        this.tecRMICalculationUrl = this.tecRmiRestService.getTecRMICalculation(this.carService.getKTypeId(),
            this.carService.getAdcTypeId(),
            this.customerDataService.getCustomerFirstName() + ' ' + this.customerDataService.getCustomerLastName(),
            this.customerDataService.getNumberplate(),
            (this.customerDataService.getMileage() ? this.customerDataService.getMileage() : ''),
            this.customerDataService.getOrderNumber(), this.customerDataService.getCustomerPhone(), this.showLetterHead);
    }


    public toggleFolded(event: any, name: string) {
        if (!event) {
            if (this.foldedCollapsePanel.indexOf(name) < 0) {
                this.foldedCollapsePanel.push(name);
            }
        } else {
            this.foldedCollapsePanel.splice(this.foldedCollapsePanel.indexOf(name), 1);
        }
    }


    public selectOption(option: any) {
        this.selectedOption = option;
    }

    public getManualAvailable() {
        this.loadAvailable = false;
        this.loadMaintenance = false;
        if (!this.carService.getAdcTypeId()) {
            this.isAvailable = false;
        } else {
            this.isAvailable = true;
        }
    }

    public checkStep(work: any, step: any) {
        if (this.isChecked(step) > -1) {
            this.activeMaintenanceStep = step;
            this.activeMaintenance = work;
            this.maintenanceSteps = work;
        }
    }

    public isChecked(subWork: any) {
        let found = -1;
        if (this.posByCar) {
            for (const pos of this.posByCar) {
                if (pos.position.workId === subWork.workId || pos.position.workId === subWork.workId * -1) {
                    found = pos.position.posId;
                }
            }
        }
        return found;
    }

    public toggleActiveMaintenance(step: any) {

        if (step) {
            this.showButton = false;
            this.selectedTab = undefined;
            if (this.activeMaintenanceStep === step) {
                this.removeWorkFromCart(this.isChecked(step));
                this.activeMaintenanceStep = undefined;
                this.activeMaintenance = undefined;
                this.maintenanceSteps = undefined;
                this.showAdditionalWork = false;
            } else {
                this.articleList = [];
                this.showAdditionalWork = true;
                this.addWorkToCart(step, false);
                this.activeMaintenance = this.maintenanceSteps;
                this.activeMaintenanceStep = step;
                this.selectedItem = undefined;
                this.selectNewStep.emit(null);
            }
        }
    }

    public loadSuggestion() {
        this.tecRmiRestService.getGearBoxCodes(this.carService.getAdcTypeId()).subscribe(
            (success) => {
                this.gearBox = success;
            }
        );
    }

    public calcDate() {
        if (this.customerDataService.getRegistrationDate().toString().length < 11) {
            return this.customerDataService.getRegistrationDate().toString();
        } else {
            return this.customerDataService.getRegistrationDate().getFullYear() + '-' + (this.customerDataService.getRegistrationDate().getMonth() + 1) + '-' + this.customerDataService.getRegistrationDate().getDate();
        }
    }

    public getCooGroups(gearBoxCode: string, initialRegistrationDate: string, emissionStandard: number) {
        this.tecRmiRestService.getCooGroups(this.carService.getAdcTypeId(), gearBoxCode, initialRegistrationDate, emissionStandard).subscribe(
            (success) => {
                this.cooGroups = success;
                this.cooGroupsError = !(success && success.length >= 1);
            },
            (error) => {
                this.cooGroupsError = true;
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_CARDATA', 'error');
            }
        );

    }

    public getServiceProposal(mdBodyQualColId: number, cooId: number, mileage: number, initialRegistrationDate: string, emissionStandard: number) {
        this.tecRmiRestService.getServiceProposal(this.carService.getAdcTypeId(), mdBodyQualColId, cooId, mileage, initialRegistrationDate, emissionStandard).subscribe(
            (success) => {
                this.serviceProposal = success;
            },
            (error) => {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_CARDATA', 'error');
            }
        );
    }

    public setServiceProposal(mdBodyQualColId: number,
                              cooId: number,
                              mileage: number,
                              initialRegistrationDate: string,
                              emissionStandard: number,
                              next: boolean,
                              carProducerName: string,
                              mainWorkId: number): void {
        this.maintenanceLoading = (next ? 1 : 2);
        this.tecRmiRestService
            .setServiceProposal(
                this.carService.getKTypeId(),
                this.carService.getAdcTypeId(),
                mdBodyQualColId,
                cooId, mileage,
                initialRegistrationDate,
                emissionStandard,
                next,
                carProducerName,
                this.carService.getVin(),
                mainWorkId)
            .subscribe(
                (success) => {
                    this.reset();
                    this.cartService.updateCartCount();
                    this.selectOption('MAIN_WORK');
                    this.maintenanceLoading = false;
                    void this.router.navigate(['/maintenance/plan']);
                    this.showButton = true;
                    this.stateService.changeMaintenanceContent('CONTENT');
                },
                (error) => {
                    this.maintenanceLoading = false;
                    this.stateService.updateMaintenanceContent();
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_SERVICES', 'error');
                }
            );
    }

    public getMaintenanceSpec(car?: ICar) {
        let temp = this.carService.getAdcTypeId();
        if (car) {
            temp = car.adcTypeId;
        }
        if (this.userService.allow('MAINTENANCE-DATA')) {
            this.tecRmiRestService.getOperatingMaterials(temp).subscribe(
                (response) => {
                    this.maintenanceSpecs = response;
                }
            );
        }
    }

    public startUpCheck() {
        if (this.router.url.indexOf('/maintenance') > -1 && this.userService.allow('MAINTENANCE-DATA') && this.customerDataService.getDataHolder()) {
            this.resetSuggestion();
            this.reset();
            this.nxDetails = false;
            this.updateSelectedCar();
            if (this.carService.getAdcTypeId() > 0) {
                this.getManual();
                this.getMaintenanceSpec();
                this.loadSuggestion();
                this.getMaintenanceManualTree();
            }
        }
        this.stateService.updateMaintenanceContent();
    }

    private updateSelectedCar() {
        if (this.carService.selectedCar && this.carService.selectedCar.adcTypeId) {
            if (this.carService.getAdcTypeId() > 0) {
                this.getMaintenance();
            }
        }
    }

    public getManual(car?: ICar) {
        let temp = this.carService.getAdcTypeId();
        if (car) {
            temp = car.adcTypeId;
        }
        this.tecRmiRestService.getTechnicalLinkedManual(temp).subscribe(
            (response) => {
                if (response) {
                    const manual = response[0];
                    if (manual !== undefined) {
                        this.manualUrl = this.tecRmiRestService.getCarOverview(temp, manual.manualId);
                    } else {
                        this.manualUrl = false;
                    }
                }
            }
        );
    }

    public removeWorkFromCart(podId: number, deleteState?: string) {
        if (podId >= 0) {
            this.cartRestService.deletePos(podId, deleteState).subscribe(
                (response) => {
                    this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_DELETE_CART_POS', 'success');
                    this.cartService.updateCartCount();
                    this.showModal = false;
                },
                (error) => {
                    if (error.status === 424) {
                        this.showDependencyError = true;
                        this.tempWork = podId;
                    }
                }
            )
        }
    }

    public addWorkToCart(work: any, additional: boolean) {
        this.workPos = work;
        this.workPos.repairId = work.korId ? work.korId : 0;
        this.workPos.adcTypeId = this.carService.getAdcTypeId();
        this.workPos.kTypeId = this.carService.getKTypeId();
        this.workPos.description = work.itemMpText + ' ' + work.korText;
        work.vin = this.carService.getVin();
        work.producerName = this.carService.getCarProducerName();

        if (work.qualColText !== 'Standard') {
            this.workPos.description += (' ' + work.qualColText);
        }
        this.workPos.labourTime = work.workTime;
        this.workPos.workId = work.workId;
        if (additional) {
            this.workPos.workType = 'ADDITIONAL_MAINTENANCE';
        } else {
            this.workPos.workType = 'MAIN_MAINTENANCE';
        }

        this.cartRestService.addWorkToCart(this.workPos).subscribe(
            (success) => {
                this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_ADD_TO_CART', 'success');
                this.cartService.updateCartCount();
            }
        );
    }

    public getPosByCart() {
        if (this.carService.selectedCar && this.router.url.indexOf('/maintenance') > -1) {
            if (!this.posByCartTime) {
                this.posByCartTime = new Date();
                this.getCartPositionByCar();
            } else {
                const temp = new Date();
                if (temp.getTime() - this.posByCartTime.getTime() > 500) {
                    this.posByCartTime = temp;
                    this.getCartPositionByCar();
                }
            }
        }
    }

    private getCartPositionByCar() {
        if (this.userService.allow('MAINTENANCE-DATA')) {
            if (this.carService.getAdcTypeId() > 0) {
                this.articleCount = 0;
                this.tecRmiRestService.getCartMaintenancePositionByCar(this.carService.getAdcTypeId(), this.carService.getKTypeId(), 0, this.carService.getVin()).subscribe(
                    (response) => {

                        this.posByCar = response;
                        if (this.activeMaintenanceStep && !this.showAdditionalWork) {
                            this.showArticle();
                        }
                        if (!this.activeMaintenanceStep) {
                            if (this.posByCar?.length) {
                                for (let pos of this.posByCar) {
                                    this.checkStep(undefined, pos.position);
                                }
                            }
                        }
                        if (this.hasArticles) {
                            this.hasArticles = false;
                        }
                        if (this.posByCar?.length) {
                            for (const pos of this.posByCar) {
                                if (pos.partGroup.length > 0) {
                                    this.articleCount = this.articleCount + pos.partGroup.length;
                                    this.hasArticles = true;
                                }
                            }
                        }
                        if (this.posByCar?.length > 0 && this.maintenance && this.maintenance.mdItemKorWorksDTO) {
                            for (const work of this.maintenance.mdItemKorWorksDTO) {
                                for (const subWork of work.mdWorkPos) {
                                    this.checkStep(work, subWork);
                                }
                            }
                        }
                    },
                    (error) => {
                        if (error.status === 500) {
                            this.stateService.maintenanceContent.next('TECHNICAL_ERROR');
                        }
                        this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_MAINTENANCE', 'error');
                    }
                )
            }
        }
    }

    public getMaintenance() {
        if (this.userService.allow('MAINTENANCE-DATA') &&
            (!this.maintenance || this.maintenance && this.maintenance.adcTypeId != this.carService.getAdcTypeId())) {
            this.loadMaintenance = true;
            this.tecRmiRestService.getMaintenanceFullTree(this.carService.getAdcTypeId(), 0).subscribe(
                (response) => {
                    this.maintenanceSteps = undefined;
                    this.loadMaintenance = false;
                    this.maintenance = response;
                    this.maintenance.adcTypeId = this.carService.getAdcTypeId();
                    this.getPosByCart();
                    this.stateService.changeMaintenanceContent('CONTENT');
                },
                (error) => {
                    this.maintenanceSteps = undefined;
                    this.loadMaintenance = false;
                    this.stateService.maintenanceContent.next('TECHNICAL_ERROR');
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_GET', 'error');
                });
        }
    }

    public getChangeInterval(car?: ICar) {
        let temp = this.carService.getAdcTypeId();
        if (car) {
            temp = car.adcTypeId;
        }
        this.tecRmiRestService.getChangeIntervals(temp).subscribe(
            (changeInterval) => {
                this.maintenanceChangeInterval = changeInterval;
            }
        )
    }

    public toggleActiveFilter(filter: any) {

        const found = this.findFilter(filter, false);

        if (found >= 0) {
            this.searchResult.activeFilters.splice(found, 1);
        } else {
            if (this.searchResult.activeFilters) {
                this.searchResult.activeFilters.push(filter);
            } else {
                this.searchResult.activeFilters = [];
                this.searchResult.activeFilters.push(filter);
            }
        }
    }

    public updateFilter() {
        const time: Date = new Date();
        if ((!this.updateFilterTime || time.getTime() - this.updateFilterTime.getTime() > 500) && !this.articlesLoading && !this.infinityLoading) {
            this.articles.kTypeId = this.carService.getKTypeId();
            this.articles.adcTypeId = this.carService.getAdcTypeId();
            this.articles.itemMpId = this.selectedTab.mountPositionId;
            this.articles.linkingTargetType = 'P';
            this.articles.pageSize = this.defaultPageSize;
            this.articles.page = 1;
            this.articles.workId = this.selectedTab.workId;
            this.articles.activeFilters = this.searchResult.activeFilters;
            this.articles.searchForAllArticles = this.searchResult.searchForAllArticles;

            const aktTime = new Date().getTime();

            if (!this.byWorkIdTimer || aktTime - this.byWorkIdTimer > 500) {
                this.byWorkIdTimer = aktTime;
                this.updateArticlesByWorkId(this.articles);
            }
        }
    }

    /*
    the display value is to translate the return value to a true / false value instead a number
    this is needed for the checkbox
     */
    public findFilter(filter: any, display: boolean) {

        let found: any = -1;

        if (this.searchResult.activeFilters && this.searchResult.activeFilters.length > 0) {
            for (let i = 0; i < this.searchResult.activeFilters.length; i++) {
                if (JSON.stringify(this.searchResult.activeFilters[i]) === JSON.stringify(filter)) {
                    found = i;
                }
            }
        } else {
            found = -1;
        }

        if (found === -1 && display) {
            found = false;
        } else if (found >= 0 && display) {
            found = true;
        }

        return found;
    }

    public resetFilter() {
        this.searchResult.activeFilters = [];
        this.updateArticleSearch(1, this.searchResult.activeFilters);
    }

    public showAllArticles() {
        if (this.activeSubscription) {
            this.activeSubscription.unsubscribe();
        }
        this.updateArticleSearch(1, this.searchResult.activeFilters, true);
    }

    public generatePdf(content: any) {

        const details = content,
            docDefinition: any = this.pdfService.buildDocDefinition(false, this.carService.selectedCar);
        let bodyElements: any[] = [];
        for (let i = 0; i < details.length; i++) {

            docDefinition.content.push(
                {
                    text: details[i].name,
                    margin: [0, 10, 0, 10],
                    fontSize: 12
                }
            );


            let spechText = '';
            for (let o = 0; o < details[i].specItems.length; o++) {

                for (let county = 0; county < details[i].specItems[o].specValues.length; county++) {

                    spechText = spechText + details[i].specItems[o].specValues[county].valueText;
                    spechText = spechText + ' ' + details[i].specItems[o].specValues[county].quantityText;
                    spechText = spechText + ' ' + details[i].specItems[o].specValues[county].qualifierText;

                    if (details[i].specItems[o].specValues.length > county + 1) {
                        spechText = spechText + '\n ';
                    }
                }

                bodyElements.push([
                    {text: details[i].specItems[o].mountPositionText, alignment: 'left'},
                    {text: spechText, alignment: 'left'}
                ]);
                spechText = '';
            }


            docDefinition.content.push(
                {
                    style: 'cartTable',
                    table: {
                        widths: ['*', '*'],
                        body: bodyElements
                    }
                }
            );
            bodyElements = [];
        }


        return docDefinition;
    }

    public createPdf(content: any) {
        this.showPdf = true;
        this.pdfLoading = true;
        this.getPdf(this.generatePdf(content)).then(
            (pdf: any) => {
                this.pdfLoading = false;
                this.pdf = pdf;
            },
            (error: any) => {
                console.warn(error);
                this.pdfLoading = false;
            }
        );
    }

    public downloadPdf(content: any) {
        return pdfMake.createPdf(this.generatePdf(content)).download();
    }

    public printPdf(content: any) {
        return pdfMake.createPdf(this.generatePdf(content)).print();
    }

    public getPdf(docDefinition: any) {
        return <any>new Promise(function (resolve: any, reject: any) {
            pdfMake.createPdf(docDefinition, 'filename').getBase64(function (pdfBase64: any) {
                var raw = atob(pdfBase64);
                var uint8Array = new Uint8Array(raw.length);
                for (var i = 0; i < raw.length; i++) {
                    uint8Array[i] = raw.charCodeAt(i);
                }
                resolve(uint8Array);
            });
        });
    }
}
