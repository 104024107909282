// tree.component.ts
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {CommonModule} from "@angular/common";
import {IGenArtTreeNode, INodeChanged} from "../../../shared/interfaces/DTO/genArtDataTree";

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  animations: [
    trigger('expandCollapse', [
      state('open', style({height: '*', opacity: 1, paddingTop: '*', paddingBottom: '*'})),
      state('closed', style({height: '0', opacity: 0, paddingTop: '0', paddingBottom: '0'})),
      transition('open <=> closed', [animate('0.3s ease-in-out')])
    ])
  ]

})
export class TreeComponent implements OnInit {
  //VARIABLES
  public isExpanded: Record<number, boolean> = {};
  public randomNumberForUniqueIdInForm: number = 0;

  //OTHERS
  @Input() tree: IGenArtTreeNode[] = [];
  @Output() nodeChanged: EventEmitter<INodeChanged> = new EventEmitter<INodeChanged>();


  constructor() {
    this.tree.forEach((node: IGenArtTreeNode) => {
      this.isExpanded[node.id] = false;
    });
  }

  ngOnInit(): void {
    this.randomNumberForUniqueIdInForm = Math.random();
  }

  public toggle(id: number): void {
    this.isExpanded[id] = !this.isExpanded[id];
  }

  public setAllChildrenActive(node: IGenArtTreeNode, isChecked: boolean): void {
    if (node.children) {
      node.children.forEach((child: IGenArtTreeNode) => {
        if (child.active !== isChecked) {
          child.active = isChecked;
          this.setAllChildrenActive(child, isChecked);
        }
      });
    }
  }

  public onCheckboxChange(node: IGenArtTreeNode, event: Event): void {
    const isChecked: boolean = (event.target as HTMLInputElement).checked;
    node.active = isChecked;
    this.setAllChildrenActive(node, isChecked);
    this.nodeChanged.emit({ids: node.genericArticleIds, type: (isChecked ? 'add' : 'remove')});
  }
}
