<div *ngIf="!editing" class="firm-calculation">
    <div class="heading blue">
       <span class="clickable" routerLink="/company-admin">
            <i class="fa fa-th" aria-hidden="true"></i>
           {{ 'ADMIN_PANEL.OVERVIEW' | translate }}
        </span>
        <span>
            <i class="fa fa-caret-right"></i>
            {{ 'FIRM_CALCULATION.CALCULATION' | translate }}
        </span>
        <div class="float-right">
            <span class="clickable" (click)="editCalculation(undefined)">
                <i class="fa fa-plus"></i>
                {{ 'FIRM_CALCULATION.NEW_CALCULATION' | translate }}
            </span>
        </div>
    </div>
    <div class="panel-content center-content white-background firm-calculation">
        <ng-container *ngIf="loading">
            <app-default-loading-page></app-default-loading-page>
        </ng-container>
        <ng-container *ngIf="!loading">
            <ng-container>
                <div class="row">
                    <div class="col headerType">
                        <h1>
                            {{ 'FIRM_CALCULATION.CALCULATION' | translate }}
                        </h1>
                    </div>
                </div>
                <div class="row mb-5 mt-2">
                    <div class="col d-flex align-items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                             stroke-width="1.5"
                             stroke="currentColor" class="hero-icon highlight-color size-3 mr-2">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                  d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"/>
                        </svg>
                        <div>
                            {{ 'FIRM_CALCULATION.ORDER_INFO' | translate }}
                        </div>
                    </div>
                </div>
                <div class="row my-1">
                    <div class="col d-flex align-items-center">
                        <app-toggle-switch [isChecked]="adminService.selectedCalculationType === 'AFTER_MARKET'"
                                           [textLeft]="'FIRM_CALCULATION.OE_ARTICLE' | translate"
                                           [textRight]="'FIRM_CALCULATION.AFTER_MARKET' | translate"
                                           (onChange)="filterCalculationsByCalcType($event)"></app-toggle-switch>
                    </div>
                </div>

                <div class="row my-1">
                    <div class="col-6 ">
                        <app-material-form-input
                                (textChange)="searchTermSupplier = $event;filterCalculationsBySearch()"
                                [placeholder]="'FIRM_CALCULATION.SUPPLIER_SEARCH' | translate">
                        </app-material-form-input>
                    </div>
                </div>
                <div class="row my-1">
                    <div class="col-6">
                        <app-material-form-input
                                (textChange)="searchTermGenArt = $event;filterCalculationsBySearch()"
                                [placeholder]="'FIRM_CALCULATION.GENART_SEARCH' | translate">
                        </app-material-form-input>
                    </div>
                </div>

                <div class="row calc-head">
                    <div class="col-2">
                        {{ 'FIRM_CALCULATION.SUPPLIER' | translate }}
                    </div>
                    <div class="col-4">
                        {{ 'FIRM_CALCULATION.GENART' | translate }}
                    </div>
                    <div class="col-2">
                        {{ 'FIRM_CALCULATION.CALCULATION_TYPE' | translate }}
                    </div>
                    <div class="col-2">
                        {{ 'FIRM_CALCULATION.SURCHARGE' | translate }}
                    </div>
                    <div class="col-2">
                        {{ 'ADMIN_PANEL.OPTIONS' | translate }}
                    </div>

                </div>

                <ng-container
                        *ngIf="filterCalculationsBySelectedType(adminService.firmCalculations) && filterCalculationsBySelectedType(adminService.firmCalculations).length > 0">
                    <div
                            class="list-wrapper drag-list" cdkDropList
                            [cdkDropListDisabled]="disableDragDrop"
                            (cdkDropListDropped)="drop($event)">
                        <div class="row drag-box"
                             *ngFor="let calc of filterCalculationsBySelectedType(adminService.firmCalculations)"
                             [class.cursor-move]="!disableDragDrop"
                             [ngClass]="{'calc-highlighted': (adminService.highlightedCheckedCalculationId$ | async) === calc.id}"
                             [cdkDragDisabled]="disableDragDrop"
                             cdkDrag>
                            <div class="col-2">
                                <ng-container *ngFor="let brandId of calc.brandIds; let i = index">
                                    <span *ngIf="i < 3">{{ getSupplierNameById(brandId, calc.type) }}{{ i < calc.brandIds?.length - 1 && i < 2 ? ', ' : '' }}</span>
                                    <span *ngIf="i === 3">...</span>
                                </ng-container>
                            </div>
                            <div class="col-4">
                                <ng-container *ngIf="calc.allGenArtsCarRelated && !calc.allGenArtsUniversalRelated">
                                    {{ 'FIRM_CALCULATION.ALL_ARTICLES_VEHICLE' | translate }}
                                </ng-container>
                                <ng-container *ngIf="calc.allGenArtsUniversalRelated && !calc.allGenArtsCarRelated">
                                    {{ 'FIRM_CALCULATION.ALL_ARTICLES_UNIVERSAL' | translate }}
                                </ng-container>
                                <ng-container *ngIf="calc.allGenArtsCarRelated && calc.allGenArtsUniversalRelated">
                                    {{ 'FIRM_CALCULATION.ALL_ARTICLES_VEHICLE' | translate }}
                                    , {{ 'FIRM_CALCULATION.ALL_ARTICLES_UNIVERSAL' | translate }}
                                </ng-container>
                                <ng-container *ngIf="!calc.allGenArtsCarRelated && !calc.allGenArtsUniversalRelated">
                                    <ng-container *ngFor="let genArtId of calc.genArtIds; let i = index">
                                        <span *ngIf="i < 2">{{ getGenArtNameById(genArtId) }}{{ i < calc.genArtIds.length - 1 && i < 2 ? ', ' : '' }} </span>
                                        <span *ngIf="i === 2">...</span>
                                    </ng-container>
                                </ng-container>
                            </div>
                            <div class="col-2">
                                {{ 'FIRM_CALCULATION.' + calc.type | translate }}
                            </div>
                            <div class="col-2">
                                {{ calc.formattedValue }}
                            </div>
                            <div class="col-2">
                                <button class="icon-btn" (click)="deleteCalculation(calc.id)"><i
                                        class="fa fa-trash"></i>
                                </button>
                                <button class="icon-btn" (click)="editCalculation(calc)"><i class="fa fa-pencil"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-container>


            </ng-container>
            <ng-container
                    *ngIf="!filterCalculationsBySelectedType(adminService.firmCalculations) || filterCalculationsBySelectedType(adminService.firmCalculations).length === 0">
                <div class="alert alert-info text-center">
                    <i class="fa fa-info-circle mr-2"></i>
                    <span>
                {{ 'FIRM_CALCULATION.EMPTY_CALCULATIONS' | translate }}
                </span>
                </div>
            </ng-container>
            <div class="row" *ngIf="calculationMoved">
                <div class="col text-left">
                    <button class="base-btn success mt-2"
                            (click)="updateCalculations()">
                        {{ 'PROMPTS.BUTTONS.SAVE_CHANGES' | translate }}
                    </button>
                </div>
                <div class="col text-right">
                    <button class="base-btn mt-2" (click)="revertChanges()">
                        {{ 'PROMPTS.BUTTONS.RESET' | translate }}
                    </button>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<ng-container *ngIf="editing">
    <app-firm-edit-calculation [calculation]="selectedCalculation"
                               (closeEditing)="editing = false; resetFilter()"></app-firm-edit-calculation>
</ng-container>
