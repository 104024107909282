import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppComponent} from './app.component';
import {RouterModule} from '@angular/router';
import {appRoutes} from './app.routes';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {
    MissingTranslationHandler,
    MissingTranslationHandlerParams,
    TranslateLoader,
    TranslateModule
} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {RootComponent} from './root/root.component';
import {AuthModule} from './auth/auth.module';
import {HomeComponent} from './home/home.component';
import {TabComponent} from './shared/components/tabset/tab/tab.component';
import {TabsetComponent} from './shared/components/tabset/tabset.component';
import {CheckboxComponent} from './shared/components/checkbox/checkbox.component';
import {DropdownComponent} from './shared/components/dropdown/dropdown.component';
import {HeaderComponent} from './root/header/header.component';
import {CartComponent} from './cart/cart.component';
import {CartService} from './cart/services/cart.service';
import {CartRestService} from './cart/services/cart.rest.service';
import {UserService} from './shared/services/user/user.service';
import {ArticlesComponent} from './articles/articles.component';
import {CarSelectionComponent} from './shared/components/car-selection/car-selection.component';
import {CarRestService} from './shared/services/car/car.rest.service';
import {CarHistoryComponent} from './root/header/car-history/car-history.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FocusDirective} from './shared/directives/focus.directive';
import {LimitToDirective} from './shared/directives/limit-to.directive';
import {ArticlesRestService} from './articles/services/articles.rest.service';
import {ArticlesService} from './articles/services/articles.service';
import {ArticlesSearchResultComponent} from './articles/articles-search-result/articles-search-result.component';
import {ArticlesRowComponent} from './articles/articles-search-result/articles-row/articles-row.component';
import {ArticleDetailsComponent} from './articles/article-details/article-details.component';
import {CollapsiblePanelComponent} from './shared/components/collapsible-panel/collapsible-panel.component';
import {KeysPipe} from './shared/pipes/keys.pipe';
import {CatPipe} from './shared/pipes/cat.pipe';
import {IframePipe} from './shared/pipes/iframe.pipe';
import {ToggleSwitchComponent} from './shared/components/toggle-switch/toggle-switch.component';
import {CartAmountInputComponent} from './shared/components/cart-amount-input/cart-amount-input.component';
import {UserMenuComponent} from './root/header/user-menu/user-menu.component';
import {OrderRestService} from './shared/services/order/order.rest.service';
import {CartTableComponent} from './cart/cart-table/cart-table.component';
import {CartRowComponent} from './cart/cart-table/cart-row/cart-row.component';
import {CartListComponent} from './cart/cart-list/cart-list.component';
import {DirectSearchComponent} from './root/header/direct-search/direct-search.component';
import {SearchHistoryService} from './shared/services/search-history.service';
import {EllipsisPipe} from './shared/pipes/ellipsis.pipe';
import {CarInfoComponent} from './root/header/car-history/car-info/car-info.component';
import {AssemblyCategoriesComponent} from './articles/assembly-categories/assembly-categories.component';
import {CarDetailsComponent} from './shared/components/car-details/car-details.component';
import {CarDetailsEquipmentComponent} from './shared/components/car-details-equipment/car-details-equipment.component';
import {AssemblyCategoriesService} from './articles/services/assembly-categories.service';
import {AddCustomArticleComponent} from './cart/add-custom-article/add-custom-article.component';
import {ThemeService} from './shared/services/theme.service';
import {TechnicalDataComponent} from './technical-data/technical-data.component';
import {TechnicalDataService} from './technical-data/technical-data.service';
import {TecRmiRestService} from './shared/services/tecrmi/tecrmi.rest.service';
import {CookieService} from 'ngx-cookie-service';
import {DmsDataService} from './shared/services/dms-data.service';
import {WorkDataComponent} from './work-data/work-data.component';
import {WorkItemComponent} from './shared/components/work-item/work-item.component';
import {ProfileComponent} from './admin/profile/profile.component';
import {UserdataComponent} from './admin/profile/userdata/userdata.component';
import {AccountRestService} from './admin/profile/services/account.rest.service';
import {FirmAdminRestService} from './shared/services/firm-admin.rest.service';
import {MaintenanceComponent} from './maintenance/maintenance.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {BodygraphicsComponent} from './graphical-part-search/bodygraphics/bodygraphics.component';
import {CarWarningComponent} from './shared/components/car-warning/car-warning.component';
import {DatePipe, registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {AvailabilityComponent} from './shared/components/availability/availability.component';
import {WorkService} from './work-data/services/work.service';
import {WorkDetailsComponent} from './shared/components/work-details/work-details.component';
import {AdministrationComponent} from './admin/club/administration.component';
import {CompanyAdminComponent} from './admin/company/company-admin';
import {CompanyAdminService} from './admin/company/services/company-admin.service';
import {FirmdataComponent} from './admin/company/firmdata/firmdata.component';
import {UsermanagementComponent} from './admin/company/usermanagement/usermanagement.component';
import {EdituserComponent} from './admin/company/usermanagement/edituser/edituser.component';
import {FirmDashboardComponent} from './admin/company/dashboard/dashboard.component';
import {AdminDashboardComponent} from './admin/club/dashboard/dashboard.component';
import {CompanymanagementComponent} from './admin/club/companymanagement/companymanagement.component';
import {AdminRestService} from './shared/services/admin.rest.service';
import {EditcompanyComponent} from './admin/club/companymanagement/editcompany/editcompany.component';
import {NoResultsComponent} from './shared/components/no-results/no-results.component';
import {ErrorHandlerComponent} from './shared/components/error-handler/error-handler.component';
import {ErrorHandlerService} from './shared/components/error-handler/services/error-handler.service';
import {SuppliersComponent} from './admin/company/suppliers/suppliers.component';
import {EditsuppliersComponent} from './admin/company/suppliers/editsuppliers/editsuppliers.component';
import {LockedComponent} from './shared/components/locked/locked.component';
import {LeftTreeComponent} from './shared/components/left-tree/left-tree.component';
import {SearchBoxComponent} from './shared/components/search-box/search-box.component';
import {PaginationComponent} from './shared/components/pagination/pagination.component';
import {DebounceInputComponent} from './shared/components/debounce-input/debounce-input.component';
import {MaintenanceDataComponent} from './maintenance/maintenance-data/maintenance-data.component';
import {MaintenanceDetailsComponent} from './maintenance/maintenance-details/maintenance-details.component'
import {MaintenanceService} from './maintenance/services/maintenance.service';
import {XhrHttpInterceptor} from './xhr-http-interceptor';
import {SupplierLogoDirective} from './shared/directives/supplier-logo.directive';
import {ArticlesForWorkComponent} from './shared/components/articles-for-work/articles-for-work.component';
import {WageRateComponent} from './admin/company/settings/wage-rate/wage-rate.component';
import {SettingsComponent} from './admin/company/settings/settings.component';
import {GlobalComponent} from './admin/global/global.component';
import {CheckoutComponent} from './checkout/checkout.component';
import {CheckoutService} from './checkout/service/checkout.service';
import {PartnerComponent} from './partner/partner.component';
import {PartnerService} from './shared/services/partner/partner.service';
import {PartnerRestService} from './partner/services/partner.rest.service';
import {PartnermanagementComponent} from './admin/club/partnermanagement/partnermanagement.component';
import {EditpartnerComponent} from './admin/club/partnermanagement/editpartner/editpartner.component';
import {SuccessComponent} from './checkout/response/success/success.component';
import {ngxLoadingAnimationTypes, NgxLoadingModule} from 'ngx-loading';
import {RetryComponent} from './checkout/response/retry/retry.component';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from '@danielmoncada/angular-datetime-picker';
import {TyreComponent} from './tyre/tyre.component';
import {TyreService} from './shared/services/tyre.service';
import {ArticleToggleComponent} from './shared/components/articles-for-work/article-toggle/article-toggle.component';
import {ArticleDashboardComponent} from './shared/components/articles-for-work/article-dashboard/article-dashboard.component';
import {OfferComponent} from './cart/offer/offer.component';
import {PdfService} from './shared/services/pdf.service';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {LogoComponent} from './admin/company/settings/logo/logo.component';
import {OrderPrintComponent} from './admin/company/order-history/order-print/order-print.component';
import {MaintenanceDashboardComponent} from './maintenance/dashboard/maintenance-dashboard.component';
import {MaintenanceInfoDashboardComponent} from './maintenance/information/dashboard/maintenance-info-dashboard.component';
import {MaintenanceManufacturerInfoComponent} from './maintenance/information/maintenance-manufacturer-info/maintenance-manufacturer-info.component';
import {MaintenanceServiceInfoComponent} from './maintenance/information/maintenance-service-info/maintenance-service-info.component';
import {CalculationComponent} from './maintenance/calculation/calculation.component';
import {MaintenanceServiceSpecsComponent} from './maintenance/information/maintenance-service-specs/maintenance-service-specs.component';
import {MaintenanceCarWarningsComponent} from './maintenance/information/maintenance-car-warnings/maintenance-car-warnings.component';
import {InfiniteScrollerDirective} from './shared/directives/infinite-scroller.directive';
import {FirmDataComponent} from './admin/shared/firm-data/firm-data.component';
import {UserDataComponent} from './admin/shared/user-data/user-data.component';
import {UserPasswordComponent} from './admin/shared/user-password/user-password.component';
import {FirmSupplierComponent} from './admin/shared/firm-supplier/firm-supplier.component';
import {FirmUserComponent} from './admin/club/companymanagement/firm-user/firm-user.component';
import {EditFirmUserComponent} from './admin/club/companymanagement/firm-user/edit-firm-user/edit-firm-user.component';
import {EditFirmSupplierComponent} from './admin/club/companymanagement/firm-supplier/edit-firm-supplier/edit-firm-supplier.component';
import {FirmSupplierClubComponent} from './admin/club/companymanagement/firm-supplier/firm-supplier.component';
import {CreateCompanyComponent} from './admin/club/companymanagement/create-company/create-company.component';
import {ClubSettingComponent} from './admin/club/club-setting/club-setting.component';
import {CarByMotorcodeComponent} from './shared/components/car-selection/car-by-motorcode/car-by-motorcode.component';
import {CarByKtypeidComponent} from './shared/components/car-selection/car-by-ktypeid/car-by-ktypeid.component';
import {CarByVinComponent} from './shared/components/car-selection/car-by-vin/car-by-vin.component';
import {CarByModelComponent} from './shared/components/car-selection/car-by-model/car-by-model.component';
import {CarByKbaComponent} from './shared/components/car-selection/car-by-kba/car-by-kba.component';
import {CarByLicenseVweComponent} from './shared/components/car-selection/car-by-license-vwe/car-by-license-vwe.component';
import {GalleryComponent} from './shared/components/gallery/gallery.component';
import {AdvancedSettingsComponent} from './admin/company/settings/advanced-settings/advanced-settings.component';
import {GlobalSupplierComponent} from './admin/global/global-mapping-import/global-supplier/global-supplier.component';
import {GlobalAdcTypeMappingComponent} from './admin/global/global-mapping-import/global-adcTypeMapping/global-adcTypeMapping.component';
import {OrderHistoryComponent} from './admin/company/order-history/order-history.component';
import {OrderHistoryService} from './admin/company/order-history/services/order-history.service';
import {OrderDetailsComponent} from './admin/company/order-history/order-details/order-details.component';
import {OrderItemComponent} from './admin/company/order-history/order-item/order-item.component';
import {OrderRowComponent} from './admin/company/order-history/order-row/order-row.component';
import {TyreInformationComponent} from './tyre/information/tyre-information/tyre-information.component';
import {NoDataComponent} from './shared/components/no-data/no-data.component';
import {ChipComponent} from './shared/components/chip/chip.component';
import {MaintenanceWizardComponent} from './maintenance/maintenance-data/maintenance-wizard/maintenance-wizard.component';
import {WizardComponent} from './shared/components/wizard/wizard.component';
import {WizardStepComponent} from './shared/components/wizard/wizard-step/wizard-step.component';
import {MaintenanceHeaderComponent} from './maintenance/maintenance-data/maintenance-header/maintenance-header.component';
import {MessageComponent} from './shared/components/message/message.component';
import {MessageHandlerService} from './shared/services/messageHandler.service';
import {CustomerDataService} from './shared/services/customerData.service';
import {WorkHeaderComponent} from './work-data/work-header/work-header.component';
import {HelperService} from './shared/services/helper.service';
import {SelloutCheckComponent} from './cart/offer/sellout-check/sellout-check.component';
import {HintsComponent} from './maintenance/hints/hints.component';
import {BodygraphicsService} from './graphical-part-search/services/bodygraphics.service';
import {TooltipDirective} from './shared/directives/tooltip.directive';
import {GlobalOrderhistoryComponent} from './admin/global/global-orderhistory/global-orderhistory.component';
import {GlobalCommunicationsListComponent} from './admin/global/global-communications-list/global-communications-list.component';
import {GlobalCommunicationsListRowComponent} from './admin/global/global-communications-list/global-communications-list-row/global-communications-list-row.component';
import {GlobalCommunicationsListDataComponent} from './admin/global/global-communications-list/global-communications-list-row/global-communications-list-data/global-communications-list-data.component';
import {GlobalVinlogComponent} from './admin/global/global-vinlog/global-vinlog.component';
import {GlobalAdminService} from './admin/global/services/global-admin.service';
import {GlobalAdminRestService} from './admin/global/services/global-admin.rest.service';
import {GlobalClubComponent} from './admin/global/global-club/global-club.component';
import {GlobalOrderDetailsComponent} from './admin/global/global-orderhistory/global-order-details/global-order-details.component';
import {GlobalOrderDataComponent} from './admin/global/global-orderhistory/global-order-data/global-order-data.component';
import {GlobalVinlogDataComponent} from './admin/global/global-vinlog/global-vinlog-row/global-vinlog-data/global-vinlog-data.component';
import {TransferlogComponent} from './admin/global/global-orderhistory/global-order-details/transferlog/transferlog.component';
import {GlobalDashboardComponent} from './admin/global/global-dashboard/global-dashboard.component';
import {GlobalOrderRowComponent} from './admin/global/global-orderhistory/global-order-row/global-order-row.component';
import {GlobalVinlogRowComponent} from './admin/global/global-vinlog/global-vinlog-row/global-vinlog-row.component';
import {FirmSettingsComponent} from './admin/club/companymanagement/firm-settings/firm-settings.component';
import {MaintenanceChangeIntervalComponent} from './maintenance/information/maintenance-change-interval/maintenance-change-interval.component';
import {TemplateComponent} from './admin/club/companymanagement/template/template.component';
import {TemplateService} from './admin/club/companymanagement/template/services/template.service';
import {CreateTemplateComponent} from './admin/club/companymanagement/template/create-template/create-template.component';
import {TyreDetailsComponent} from './tyre/tyre-details/tyre-details.component';
import {LogComponent} from './admin/club/companymanagement/log/log.component';
import {AutovistaComponent} from './graphical-part-search/autovista/autovista.component';
import {AutovistaRestService} from './graphical-part-search/services/autovista.rest.service';
import {AutovistaService} from './graphical-part-search/services/autovista.service';
import {AutovistaDetailsComponent} from './graphical-part-search/autovista/autovista-details/autovista-details.component';
import {AutovistaLeftTreeComponent} from './graphical-part-search/autovista/autovista-left-tree/autovista-left-tree.component';
import {SvgDirective} from './graphical-part-search/autovista/directives/svg.directive';
import {GraphicalPartSearchComponent} from './graphical-part-search/graphical-part-search.component';
import {TabsComponent} from './shared/components/tabs/tabs.component';
import {FilterComponent} from './shared/components/filter/filter.component';
import {CheckGenartComponent} from './cart/check-genart/check-genart.component';
import {StatisticsDashboardComponent} from './admin/global/statistics-dashboard/statistics-dashboard.component';
import {StatisticsClubComponent} from './admin/global/statistics-club/statistics-club.component';
import {SvgOldDirective} from './graphical-part-search/autovista/directives/svgOld.directive';
import {ClublogoConfigComponent} from './admin/club/club-setting/clublogo-config/clublogo-config.component';
import {TecdocConfigComponent} from './admin/club/club-setting/tecdoc-config/tecdoc-config.component';
import {LanguageConfigComponent} from './admin/club/club-setting/language-config/language-config.component';
import {CarByAutovistaVinComponent} from './shared/components/car-selection/car-by-autovista-vin/car-by-autovista-vin.component';
import {VinSearchWrapperComponent} from './shared/components/car-selection/vin-search-wrapper/vin-search-wrapper.component';
import {ProfitCheckComponent} from './cart/offer/profit-check/profit-check.component';
import {GlobalGriphistoryComponent} from './admin/global/global-griphistory/global-griphistory.component';
import { CarByLicenseFrComponent } from './shared/components/car-selection/car-by-license-fr/car-by-license-fr.component';
import { CarByLicenseSwissComponent } from './shared/components/car-selection/car-by-license-swiss/car-by-license-swiss.component';
import { NumberplateSearchWrapperComponent } from './shared/components/car-selection/numberplate-search-wrapper/numberplate-search-wrapper.component';
import { GripOrderRowComponent } from './admin/global/global-griphistory/grip-order-row/grip-order-row.component';
import { GripOrderDetailsComponent } from './admin/global/global-griphistory/grip-order-details/grip-order-details.component';
import { TransferlogGripComponent } from './admin/global/global-griphistory/grip-order-details/transferlog-grip/transferlog-grip.component';
import { TransferlogGripSupplierComponent } from './admin/global/global-griphistory/grip-order-details/transferlog-grip-supplier/transferlog-grip-supplier.component';
import { EmailComponent } from './admin/profile/email/email.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { GlobalMappingImportComponent } from './admin/global/global-mapping-import/global-mapping-import.component';
import { GlobalCustomidComponent } from './admin/global/global-mapping-import/global-customId/global-customid.component';
import { PageMaintenanceComponent } from './shared/components/page-maintenance/page-maintenance.component';
import {ImageCropperComponent} from './admin/company/settings/logo/image-cropper/image-cropper.component';
import { ClubSupplierConfigComponent } from './admin/club/club-setting/club-supplier-config/club-supplier-config.component';
import { AssortmentComponent } from './admin/assortment/assortment.component';
import { AssortmentViewComponent } from './admin/assortment/assortment-view/assortment-view.component';
import { AssortmentSupplierComponent } from './admin/assortment/assortment-supplier/assortment-supplier.component';
import { AssortmentArticleComponent } from './admin/assortment/assortment-article/assortment-article.component';
import { AssortmentSpecificSupplierComponent } from './admin/assortment/assortment-specific-supplier/assortment-specific-supplier.component';
import { DmsCarSelectionComponent } from './shared/components/dms-car-selection/dms-car-selection.component';
import {ExternalNotificationService} from './shared/services/external-notification.service';
import { UserNotifcationsComponent } from './admin/profile/user-notifcations/user-notifcations.component';
import { ClubNotificationsComponent } from './admin/club/club-notifications/club-notifications.component';
import { GlobalNotificationsComponent } from './admin/global/global-notifications/global-notifications.component';
import { AddCustomCommentComponent } from './cart/add-custom-comment/add-custom-comment.component';
import { ExternalToolsComponent } from './admin/club/external-tools/external-tools.component';
import { ExternalToolsDetailsComponent } from './admin/club/external-tools/external-tools-details/external-tools-details.component';
import { GlobalCountryConfigComponent } from './admin/global/global-country-config/global-country-config.component';
import { GlobalEditCountryComponent } from './admin/global/global-country-config/global-edit-country/global-edit-country.component';
import { ExternalToolsWrapperComponent } from './shared/components/external-tools-wrapper/external-tools-wrapper.component';
import { DynamicSettingWrapperComponent } from './admin/club/club-setting/dynamic-setting-wrapper/dynamic-setting-wrapper.component';
import { EditExternalToolComponent } from './admin/company/external-firm-tools/edit-external-tool/edit-external-tool.component';
import { ExternalFirmToolsComponent } from './admin/company/external-firm-tools/external-firm-tools.component';
import { NewAssortmentComponent } from './admin/assortment/new-assortment/new-assortment.component';
import { SettingListComponent } from './admin/club/club-setting/dynamic-setting-wrapper/setting-list/setting-list.component';
import { DynamicSettingDragListComponent } from './admin/club/club-setting/dynamic-setting-wrapper/dynamic-setting-drag-list/dynamic-setting-drag-list.component';
import { SettingExternalToolComponent } from './admin/club/club-setting/dynamic-setting-wrapper/setting-external-tool/setting-external-tool.component';
import { CartLimitConfigComponent } from './admin/club/club-setting/cart-limit-config/cart-limit-config.component';
import { CarByLicenseUkComponent } from './shared/components/car-selection/car-by-license-uk/car-by-license-uk.component';
import { CountryConfigComponent } from './admin/club/club-setting/country-config/country-config.component';
import { ArticleSelectionComponent } from './cart/article-selection/article-selection.component';
import { PreloadCarSelectionComponent } from './shared/components/car-selection/preload-car-selection/preload-car-selection.component';
import { CarByNatcodeComponent } from './shared/components/car-selection/car-by-natcode/car-by-natcode.component';
import { ContentOverlayComponent } from './shared/components/content-overlay/content-overlay.component';
import { AssortmentSpecificArticleComponent } from './admin/assortment/assortment-specific-article/assortment-specific-article.component';
import { SceletonListComponent } from './shared/components/sceleton-list/sceleton-list.component';
import { AssortmentNoResultsComponent } from './admin/assortment/assortment-no-results/assortment-no-results.component';
import {ToastrModule} from 'ngx-toastr';
import { CartSupplierChangeComponent } from './cart/cart-supplier-change/cart-supplier-change.component';
import { GlobalOeSupplierComponent } from './admin/global/global-mapping-import/global-oe-supplier/global-oe-supplier.component';
import { DefaultLoadingPageComponent } from './shared/components/default-loading-page/default-loading-page.component';
import { GlobalOrdermailComponent } from './admin/global/global-orderhistory/global-ordermail/global-ordermail.component';
import {NotificationService} from './notification/notification.service';
import { SsoErrorComponent } from './sso-error/sso-error.component';
import {CarByLicenseEsComponent} from './shared/components/car-selection/car-by-license-es/car-by-license-es.component';
import {CarByLicenseNlComponent} from "./shared/components/car-selection/car-by-license-nl/car-by-license-nl.component";
import {CarByLicensePtComponent} from "./shared/components/car-selection/car-by-license-pt/car-by-license-pt.component";
import { RawToolsComponent } from './cart/raw-tools/raw-tools.component';
import {TyreRestService} from "./shared/rest-services/tyre.rest.service";
import {CarByLicenseChComponent} from "./shared/components/car-selection/car-by-license-ch/car-by-license-ch.component";
import {CarByLicenseItComponent} from "./shared/components/car-selection/car-by-license-it/car-by-license-it.component";
import { ClickOutsideDirective } from './shared/directives/click-outside.directive';
import { SupplierBlacklistComponent } from './admin/company/settings/supplier-blacklist/supplier-blacklist.component';
import {FirmCalculationComponent} from "./admin/company/firm-calculation/firm.calculation";
import {FirmEditCalculationComponent} from "./admin/company/firm-edit-calculation/firm-edit-calculation";
import {TreeComponent} from "./admin/shared/tree/tree.component";
import { FirmCheckCalculationComponent } from './admin/company/firm-check-calculation/firm-check-calculation.component';


registerLocaleData(localeDe);

// AoT requires an exported function for factories
export function translateHttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
export class MyMissingTranslationHandler implements MissingTranslationHandler {
    handle(params: MissingTranslationHandlerParams) {
        return params.key;
    }
}

@NgModule({
    imports: [
        // Base Modules
        RouterModule.forRoot(appRoutes, {useHash: true}),
        // BusyModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        NgbModule,
        // Custom Modules
        AuthModule,
        HttpClientModule,
        // Datepicker
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        // Third Party
        ToastrModule.forRoot({
            timeOut: 5000,
            positionClass: 'toast-bottom-right',
            newestOnTop: false,
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true
        }),
        PdfViewerModule,
        // BusyModule,
        TranslateModule.forRoot({
            missingTranslationHandler: {provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler},
            useDefaultLang: false,
            loader: {
                provide: TranslateLoader,
                useFactory: translateHttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        DragDropModule,
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.circleSwish,
            backdropBackgroundColour: '#fafafa',
            backdropBorderRadius: '0px',
            primaryColour: '#a2a2a2',
            secondaryColour: '#a2a2a2',
            tertiaryColour: '#a2a2a2'
        }),
        ReactiveFormsModule,
        TranslateModule,
    ],
    declarations: [
        // Main
        AppComponent,
        RootComponent,
        UserMenuComponent,
        DirectSearchComponent,

        // Shared
        DropdownComponent,
        CheckboxComponent,
        TabsetComponent,
        TabComponent,
        HeaderComponent,
        ToggleSwitchComponent,
        FocusDirective,
        LimitToDirective,
        CollapsiblePanelComponent,
        InfiniteScrollerDirective,
        DefaultLoadingPageComponent,

        // Pipes
        KeysPipe,
        EllipsisPipe,
        CatPipe,
        IframePipe,

        // Home
        HomeComponent,

        // Cart
        CartComponent,
        CartAmountInputComponent,
        CartTableComponent,
        CartRowComponent,
        CartListComponent,
        OrderHistoryComponent,
        AddCustomArticleComponent,

        // Car
        CarDetailsComponent,
        CarInfoComponent,
        CarSelectionComponent,
        CarHistoryComponent,
        CarDetailsEquipmentComponent,

        // Articles
        ArticlesComponent,
        ArticlesSearchResultComponent,
        ArticlesRowComponent,
        ArticleDetailsComponent,
        AssemblyCategoriesComponent,

        // Technical Data
        TechnicalDataComponent,

        WorkDataComponent,

        WorkItemComponent,

        ProfileComponent,

        UserdataComponent,

        MaintenanceComponent,

        WorkDetailsComponent,

        BodygraphicsComponent,

        CarWarningComponent,

        AvailabilityComponent,

        WorkDetailsComponent,

        AdministrationComponent,

        CompanyAdminComponent,

        FirmdataComponent,

        UsermanagementComponent,

        EdituserComponent,

        FirmDashboardComponent,
        AdminDashboardComponent,
        CompanymanagementComponent,
        EditcompanyComponent,
        NoResultsComponent,
        ErrorHandlerComponent,
        SuppliersComponent,
        EditsuppliersComponent,
        LockedComponent,
        LeftTreeComponent,
        SearchBoxComponent,
        PaginationComponent,
        DebounceInputComponent,
        MaintenanceDataComponent,
        MaintenanceDetailsComponent,
        SupplierLogoDirective,
        ArticlesForWorkComponent,
        WageRateComponent,
        SettingsComponent,
        GlobalComponent,
        CheckoutComponent,
        PartnerComponent,
        PartnermanagementComponent,
        EditpartnerComponent,
        SuccessComponent,
        RetryComponent,
        TyreComponent,
        ArticleToggleComponent,
        ArticleDashboardComponent,
        OfferComponent,
        LogoComponent,
        OrderPrintComponent,
        MaintenanceDashboardComponent,
        MaintenanceInfoDashboardComponent,
        MaintenanceManufacturerInfoComponent,
        MaintenanceServiceInfoComponent,
        MaintenanceServiceSpecsComponent,
        MaintenanceCarWarningsComponent,
        CalculationComponent,
        FirmDataComponent,
        UserDataComponent,
        UserPasswordComponent,
        FirmSupplierComponent,
        FirmSupplierClubComponent,
        FirmUserComponent,
        EditFirmUserComponent,
        EditFirmSupplierComponent,
        CreateCompanyComponent,
        ClubSettingComponent,
        CarByMotorcodeComponent,
        CarByKtypeidComponent,
        CarByVinComponent,
        CarByModelComponent,
        CarByKbaComponent,
        CarByLicenseVweComponent,
        GalleryComponent,
        AdvancedSettingsComponent,
        GlobalSupplierComponent,
        GlobalAdcTypeMappingComponent,
        OrderHistoryComponent,
        OrderDetailsComponent,
        OrderItemComponent,
        OrderRowComponent,
        TyreInformationComponent,
        NoDataComponent,
        ChipComponent,
        MaintenanceWizardComponent,
        WizardComponent,
        WizardStepComponent,
        MaintenanceHeaderComponent,
        MessageComponent,
        WorkHeaderComponent,
        SelloutCheckComponent,
        HintsComponent,
        TooltipDirective,
        GlobalOrderhistoryComponent,
        GlobalCommunicationsListComponent,
        GlobalCommunicationsListRowComponent,
        GlobalCommunicationsListDataComponent,
        GlobalVinlogComponent,
        GlobalOrderDetailsComponent,
        GlobalOrderDataComponent,
        GlobalVinlogDataComponent,
        TransferlogComponent,
        GlobalDashboardComponent,
        GlobalClubComponent,
        GlobalOrderRowComponent,
        GlobalVinlogRowComponent,
        FirmSettingsComponent,
        MaintenanceChangeIntervalComponent,
        TemplateComponent,
        CreateTemplateComponent,
        TyreDetailsComponent,
        LogComponent,
        AutovistaComponent,
        AutovistaDetailsComponent,
        AutovistaLeftTreeComponent,
        SvgDirective,
        SvgOldDirective,
        GraphicalPartSearchComponent,
        TabsComponent,
        FilterComponent,
        CheckGenartComponent,
        StatisticsDashboardComponent,
        StatisticsClubComponent,
        ClublogoConfigComponent,
        TecdocConfigComponent,
        LanguageConfigComponent,
        CarByAutovistaVinComponent,
        VinSearchWrapperComponent,
        ProfitCheckComponent,
        CarByLicenseFrComponent,
        CarByLicenseSwissComponent,
        NumberplateSearchWrapperComponent,
        GlobalGriphistoryComponent,
        GripOrderRowComponent,
        GripOrderDetailsComponent,
        TransferlogGripComponent,
        TransferlogGripSupplierComponent,
        EmailComponent,
        GlobalMappingImportComponent,
        GlobalCustomidComponent,
        PageMaintenanceComponent,
        ImageCropperComponent,
        ClubSupplierConfigComponent,
        GlobalVinlogRowComponent,
        AssortmentComponent,
        AssortmentViewComponent,
        AssortmentSupplierComponent,
        AssortmentArticleComponent,
        AssortmentSpecificSupplierComponent,
        DmsCarSelectionComponent,
        GlobalVinlogRowComponent,
        UserNotifcationsComponent,
        ClubNotificationsComponent,
        GlobalNotificationsComponent,
        OrderItemComponent,
        AddCustomCommentComponent,
        GlobalCountryConfigComponent,
        GlobalEditCountryComponent,
        ExternalToolsComponent,
        ExternalToolsDetailsComponent,
        ExternalToolsWrapperComponent,
        DynamicSettingWrapperComponent,
        EditExternalToolComponent,
        ExternalFirmToolsComponent,
        ExternalToolsWrapperComponent,
        NewAssortmentComponent,
        CarByLicenseUkComponent,
        CarByLicenseNlComponent,
        CountryConfigComponent,
        SettingListComponent,
        DynamicSettingDragListComponent,
        SettingExternalToolComponent,
        CartLimitConfigComponent,
        CarByLicenseEsComponent,
        ArticleSelectionComponent,
        PreloadCarSelectionComponent,
        CarByNatcodeComponent,
        ContentOverlayComponent,
        AssortmentSpecificArticleComponent,
        SceletonListComponent,
        AssortmentNoResultsComponent,
        CartSupplierChangeComponent,
        GlobalOeSupplierComponent,
        GlobalOrdermailComponent,
        SsoErrorComponent,
        RawToolsComponent,
        CarByLicensePtComponent,
        CarByLicenseChComponent,
        CarByLicenseItComponent,
        ClickOutsideDirective,
        SupplierBlacklistComponent,
        FirmCalculationComponent,
        FirmEditCalculationComponent,
        TreeComponent,
        FirmCheckCalculationComponent
    ],
    providers: [
        {provide: LOCALE_ID, useValue: 'de-DE'}, // set de-DE as default locale
        {
            provide: HTTP_INTERCEPTORS,
            useClass: XhrHttpInterceptor,
            multi: true
        },
        DatePipe,
        ThemeService,
        TyreService,
        CookieService,
        PdfService,
        HttpClientModule,
        CartService,
        CartRestService,
        CheckoutService,
        ArticlesService,
        ArticlesRestService,
        CarRestService,
        UserService,
        TechnicalDataService,
        TecRmiRestService,
        WorkService,
        OrderRestService,
        SearchHistoryService,
        AssemblyCategoriesService,
        DmsDataService,
        AccountRestService,
        FirmAdminRestService,
        AdminRestService,
        CompanyAdminService,
        ErrorHandlerService,
        MaintenanceService,
        PartnerService,
        PartnerRestService,
        OrderHistoryService,
        MessageHandlerService,
        CustomerDataService,
        HelperService,
        BodygraphicsService,
        GlobalAdminService,
        GlobalAdminRestService,
        TemplateService,
        AutovistaService,
        AutovistaRestService,
        ExternalNotificationService,
        TyreRestService,
        NotificationService
    ],
    exports: [
        AddCustomArticleComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    private static PaginationCompinent: any;
}
