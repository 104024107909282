export enum LicencesEnum {
    ROLE_USER_PLUS_AW = 'ROLE_USER_PLUS_AW',
    ROLE_USER_PLUS_AW_TEC = "ROLE_USER_PLUS_AW_TEC",
    ROLE_USER_PLUS = "ROLE_USER_PLUS",
    ROLE_USER_LIGHT = "ROLE_USER_LIGHT"
}

export enum RolesEnum {
    ROLE_GLOBAL_ADMIN = 'ROLE_GLOBAL_ADMIN',
    ROLE_ADMIN = 'ROLE_ADMIN',
    ROLE_FIRM_ADMIN = 'ROLE_FIRM_ADMIN',
    ROLE_USER = 'ROLE_USER',
}


export enum PrivilegesEnum {
    ROLE_ORDER = 'ROLE_ORDER',
    ROLE_SELLIN = 'ROLE_SELLIN',
    SUPPLIER_MANAGEMENT = 'SUPPLIER_MANAGEMENT',
    USER_MANAGEMENT = 'USER_MANAGEMENT',
    FIRM_STATISTIC = 'FIRM_STATISTIC',
    SETTINGS_MANAGEMENT = 'SETTINGS_MANAGEMENT',
    FIRM_CALCULATION_MANAGEMENT='FIRM_CALCULATION_MANAGEMENT',
    ASSORTMENT_PRIORITISATION = 'ASSORTMENT_PRIORITISATION',
    ORDER_HISTORY = 'ORDER_HISTORY',
    CHANGE_EMAIL = "CHANGE_EMAIL",
    CHANGE_PASSWORD = "CHANGE_PASSWORD",
    AUTOVISTA_VIN_SEARCH_ALLOW_CHARGEABLE = "AUTOVISTA_VIN_SEARCH_ALLOW_CHARGEABLE",
}
