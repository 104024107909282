<div class="panel-content center-content white-background">
    <app-error-handler [hasCancel]="errorHandlerService?.errorCode === 409" (onBtnPress)="saveFirm($event)"
                       *ngIf="errorHandlerService.newError"></app-error-handler>
    <div class="firmdataCSS" *ngIf="firmData">
        <div class="row">

            <!-- Linke Tabelle -->
            <div class="col-sm-12">
                <div class="row">
                    <div class="col headerType">
                        <h1 class="selection title" *ngIf="!isCreate"><b>{{firmData?.name}}</b></h1>
                        <h1 class="selection title" *ngIf="isCreate"><b>{{'ADMIN_PANEL.NEW_COMPANY' | translate}}</b>
                        </h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="row rowHead">
            <!-- Tabelle linke Seite -->
            <div class="col-12 col-sm-6">
                <div class="row">
                    <div class="col headerType">
                        <h1 class="selection title">{{'USER_PANEL.FIRM_DATA' | translate}}</h1>
                    </div>
                </div>
                <form name="firmNameForm" [formGroup]="firmNameForm">
                    <div class="row">
                        <div class="col">
                            <app-material-form-input [label]="'USER_PANEL.DISPLAY_NAME' | translate"
                                                     [showValidation]="true"
                                                     [showError]="(adminService.validationErrorFirm$ | async ).includes('NAME')"
                                                     [validationErrorMessage]="(adminService.validationErrorFirm$ | async ).includes('NAME')? ('VALIDATION.ERROR_UNIQUE_NAME' | translate) :('VALIDATION.ERROR_NOT_EMPTY' | translate)"
                                                     [disabled]="!isAdmin"
                                                     [(text)]="firmData.name"></app-material-form-input>
                        </div>
                    </div>
                </form>
                <div class="row">
                    <div class="col">
                        <app-material-form-input [label]="'USER_PANEL.PHONE' | translate"
                                                 [disabled]="!isAdmin"
                                                 [(text)]="firmData.phone"></app-material-form-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <app-material-form-input [label]="'USER_PANEL.FAX' | translate"
                                                 [disabled]="!isAdmin"
                                                 [(text)]="firmData.fax"></app-material-form-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <app-material-form-input [label]="'USER_PANEL.EXTERNAL_ID' | translate"
                                                 [showValidation]="true"
                                                 [showError]="(adminService.validationErrorFirm$ | async ).includes('EXTERNAL_ID')"
                                                 [validationErrorMessage]="(adminService.validationErrorFirm$ | async ).includes('EXTERNAL_ID')? ('VALIDATION.ERROR_UNIQUE_EXTERNAL_ID' | translate) :('VALIDATION.ERROR_NOT_EMPTY' | translate)"
                                                 [disabled]="!isAdmin"
                                                 [(text)]="firmData.externalId"></app-material-form-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <app-material-form-input [label]="'USER_PANEL.MAIL' | translate" [disabled]="!isAdmin"
                                                 [showError]="(adminService.validationErrorFirm$ | async ).includes('EMAIL')"
                                                 [showValidation]="true"
                                                 [validationErrorMessage]="(adminService.validationErrorFirm$ | async ).includes('EMAIL')? ('VALIDATION.ERROR_UNIQUE_EMAIL' | translate) :('VALIDATION.ERROR_NOT_EMPTY' | translate)"
                                                 [(text)]="firmData.email"></app-material-form-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label class="input-group-label">{{'ADMIN_PANEL.ENDDATE' | translate}}</label>
                        <div class="input-group" [ngClass]="{'showDisabled': !isAdmin}">
                            <ng-container *ngIf="firmData?.expirationDate">
                                <label style="margin-bottom: 0px;">
                                    <input class="datepicker" [ngModel]="firmData.expirationDate"
                                           [placeholder]="'dd.mm.yyyy'" #picker [owlDateTimeTrigger]="dt6"
                                           [owlDateTime]="dt6">
                                    <owl-date-time (afterPickerClosed)="setDate(picker.value)" [pickerType]="'calendar'"
                                                   [startView]="'year'"
                                                   #dt6></owl-date-time>
                                </label>
                                <div class="input-group-addon hover_red" (click)="resetDate()" *ngIf="isAdmin">
                                    <i class="fa fa-calendar-times-o" style="width: 1rem; height: 1rem;"></i>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="!firmData?.expirationDate">
                                <input class="form-control" type="text" [disabled]="true"
                                       value="{{'ADMIN_PANEL.INFINITE' | translate}}"
                                       [ngClass]="{'showDisabled': !isAdmin}">
                                <div class="input-group-addon" (click)="createDate()" *ngIf="isAdmin">
                                    <i class="fa fa-calendar-plus-o" style="width: 1.2rem; height: 1rem;"></i>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Tabelle rechte Seite -->
            <div class="col-12 col-sm-6">
                <div class="row">
                    <div class="col headerType">
                        <h1 class="selection title">{{'USER_PANEL.ADDRESS' | translate}}</h1>
                    </div>
                </div>
                <form name="firmAddressForm" [formGroup]="firmAddressForm">
                    <div class="row">
                        <div class="col">
                            <app-material-form-input [label]="'USER_PANEL.NAME' | translate" [disabled]="!isAdmin"
                                                     [showValidation]="true"
                                                     [validationErrorMessage]="'VALIDATION.ERROR_NOT_EMPTY' | translate"
                                                     [(text)]="firmData.address.name1"></app-material-form-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <app-material-form-input [label]="'USER_PANEL.NAME_ADDITION' | translate"
                                                     [disabled]="!isAdmin"
                                                     [(text)]="firmData.address.name2"></app-material-form-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <app-material-form-input [label]="'USER_PANEL.STREET' | translate" [disabled]="!isAdmin"
                                                     [showValidation]="true"
                                                     [validationErrorMessage]="'VALIDATION.ERROR_NOT_EMPTY' | translate"
                                                     [(text)]="firmData.address.address1"></app-material-form-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <app-material-form-input [label]="'USER_PANEL.ADDRESS_2' | translate" [disabled]="!isAdmin"
                                                     [(text)]="firmData.address.address2"></app-material-form-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <app-material-form-input [label]="'USER_PANEL.ZIP_CODE' | translate" [disabled]="!isAdmin"
                                                     [showValidation]="zipPattern === '' || firmData.address.zip"
                                                     [maxLength]="10"
                                                     [pattern]="zipPattern"
                                                     [validationErrorMessage]="'VALIDATION.ERROR_ZIP' | translate"
                                                     [(text)]="firmData.address.zip"></app-material-form-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <app-material-form-input [label]="'USER_PANEL.CITY' | translate" [disabled]="!isAdmin"
                                                     [showValidation]="true"
                                                     [validationErrorMessage]="'VALIDATION.ERROR_NOT_EMPTY' | translate"
                                                     [(text)]="firmData.address.city"></app-material-form-input>
                        </div>
                    </div>
                </form>
                <div class="row" *ngIf="firmData?.address?.country && userService.allow('CLUB-ADMIN')">
                    <div class="col">
                        <label class="dropdown-label">{{'USER_PANEL.REGION' | translate}}</label>
                        <div class="custom-dropdown" [ngClass]="{'icon_lock': !isAdmin}">
                            <select [disabled]="!isAdmin"
                                    (change)="checkZipForCountry(firmData.address.country.iso31661Alpha2)"
                                    [(ngModel)]="firmData.address.country"
                                    [compareWith]="checkId"
                                    [ngClass]="{'icon_lock': !isAdmin}">
                                <option *ngFor="let state of states"
                                        [ngValue]="state">{{ 'USER_PANEL.REGION_NAMES.' + state.name | translate}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="firmData?.address?.country && !userService.allow('CLUB-ADMIN')">
                    <div class="col">
                        <app-material-form-input [label]="'USER_PANEL.CITY' | translate" [disabled]="!isAdmin"
                                                 [(text)]="firmData.address.country.name"></app-material-form-input>
                    </div>
                </div>
            </div>
        </div>

        <br><br><br>

        <div class="row" *ngIf="isAdmin">
            <!-- Linke Tabelle -->
            <div class="col-12 col-sm-6">
                <div class="row">
                    <div class="col headerType">
                        <h1 class="selection title">{{'USER_PANEL.HOURLY_RATE' | translate}}</h1>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <app-material-form-input [type]="'number'" [label]="'USER_PANEL.HOURLY_RATE' | translate"
                                                 [(text)]="firmData.hourlyWageRate"></app-material-form-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <app-material-form-input [type]="'number'"
                                                 [label]="'USER_PANEL.HOURLY_RATE_CHASSIS' | translate"
                                                 [(text)]="firmData.hourlyWageRateChassis"></app-material-form-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <app-material-form-input [type]="'number'"
                                                 [label]="'USER_PANEL.HOURLY_RATE_ELECTRONIC' | translate"
                                                 [(text)]="firmData.hourlyWageRateElectronic"></app-material-form-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <app-material-form-input [type]="'number'"
                                                 [label]="'USER_PANEL.HOURLY_RATE_MECHANIC' | translate"
                                                 [(text)]="firmData.hourlyWageRateMechanic"></app-material-form-input>
                    </div>
                </div>
            </div>

            <!-- Rechte Tabelle -->
            <div class="col-12 col-sm-6" *ngIf="isAdmin">
                <div class="row">
                    <div class="col headerType">
                        <h1 class="selection title">{{'USER_PANEL.ROLES' | translate}}</h1>
                    </div>
                </div>
                <ng-container *ngFor="let role of rights?.roles">
                    <div class="row rowHead">
                        <div class="col">
                            <input type="checkbox" [checked]="firmData?.possibleRoles?.indexOf(role) > -1"
                                   (click)="toggleSelection('ROLE', role)"/>
                            <label class="checkboxLabel">{{'ADMIN.ROLES.' + role | translate}}</label>
                        </div>
                    </div>
                </ng-container>
                <div class="row mt-5">
                    <div class="col headerType">
                        <h1 class="selection title">{{'ADMIN.PRIVILEGES' | translate}}</h1>
                    </div>
                </div>
                <ng-container *ngFor="let privileg of rights.privileges">
                    <div class="row rowHead">
                        <div class="col">
                            <input type="checkbox" [checked]="firmData?.possiblePrivileges?.indexOf(privileg) > -1"
                                   [disabled]="privilegeIsAdminPrivilegDefaultsetted.includes(privileg)"
                                   (click)="toggleSelection('PRIVILEGE', privileg)"/>
                            <label class="checkboxLabel">{{'ADMIN.PRIVILEGES_ROLES.' + privileg | translate}}</label>
                        </div>
                    </div>
                </ng-container>
                <div class="row mt-5">
                    <div class="col headerType">
                        <h1 class="selection title">{{'ADMIN.LICENSE' | translate}}</h1>
                    </div>
                </div>
                <ng-container *ngFor="let licence of rights.licences">
                    <div class="row rowHead">
                        <div class="col">
                            <input type="radio" [checked]="firmData?.licence === licence"
                                   (click)="toggleSelection('LICENCE', licence)"/>
                            <label class="radioLabel">{{'ADMIN.ROLES.' + licence | translate}}</label>
                        </div>
                    </div>
                </ng-container>

                <div class="row rowHead isActive">
                    <div class="col">
                        <input type="checkbox" [checked]="firmData?.active"
                               (change)="firmData.active = !firmData.active"/>
                        <label class="checkboxLabel">{{'ADMIN_PANEL.ACTIVE' | translate}}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="isAdmin">
            <div class="col button">
                <button class="base-btn success" (click)="saveFirm()">
                    <span>{{ 'PROMPTS.BUTTONS.SAVE_CHANGES' | translate }}</span>
                </button>
            </div>
            <div class="col button text-right" *ngIf="adminService.GlobalAdmin() && !firmData.delete && !isCreate">
                <button class="base-btn error" (click)="deletFirm(firmData)"><i
                        class="fa fa-trash"></i> {{'ADMIN_PANEL.DELETE' | translate}}</button>
            </div>
        </div>
        <div class="row" *ngIf="adminService.GlobalAdmin()">
            <div class="col button" *ngIf="firmData.delete">
                <div class="alert alert-danger">
                    <b>{{firmData.name}}</b> {{'GENERAL.DELETE' | translate}} <br/><br/>
                    <button class="base-btn "
                            (click)="firmData.delete = false;">{{'PROMPTS.BUTTONS.CANCEL' | translate}}</button>
                    <button class="base-btn error pull-right"
                            (click)="deletFirm(firmData, true)"> {{'ADMIN_PANEL.DELETE' | translate}} </button>
                </div>
            </div>
        </div>
    </div>
</div>
