import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ISupplier, IUnassignedSuppliers} from "../../../../shared/interfaces/supplier";
import {CompanyAdminService} from "../../services/company-admin.service";
import {AdminService} from "../../../club/services/admin.service";
import {ErrorHandlerService} from "../../../../shared/components/error-handler/services/error-handler.service";
import {UserService} from "../../../../shared/services/user/user.service";
import {ArticlesService} from '../../../../articles/services/articles.service';
import {ISupplierLoginData} from '../../../../shared/interfaces/DTO/firm/supplierLoginData';

@Component({
  selector: 'app-editsupplier',
  templateUrl: './editsuppliers.component.html',
  styleUrls: ['./editsuppliers.component.scss']
})
export class EditsuppliersComponent implements OnInit {

    public isAdmin: boolean;
    public create: boolean;
    public role: string;
    public supplier: ISupplier;
    public unassignedSupplier:  IUnassignedSuppliers[];
    public selected: IUnassignedSuppliers;

  constructor(public companyAdminService: CompanyAdminService,
              public adminService: AdminService,
              public userService: UserService,
              public errorHandlerService: ErrorHandlerService,
              private router: Router,
              private route: ActivatedRoute) {
      this.role = '';
      this.isAdmin = false;
      this.create = false;
      this.errorHandlerService.newError = false;
      this.supplier = {customerNo: '',supplierName: '', supplier: '', password: '', username: '', firmId: 0, id: '', sortNo: 0, active: true};
      this.companyAdminService.getUnassignedsuppliers().subscribe(
          (data) => {
              this.unassignedSupplier = data.sort((a, b) => a.name.localeCompare(b.name));
          }
      );
  }

  ngOnInit() {
      this.route.params.subscribe(
          (params) => {
              if (params['id'] !== 0) {
                  this.create = false;
                  this.companyAdminService.getSuppliersRest().subscribe(
                      (data) => {
                          for (const supplier of data) {
                              if (supplier.id === params['id']) {
                                  this.companyAdminService.supplier = supplier;
                              }
                          }
                      }
                  );
              } else {
                  this.create = true;
                  this.companyAdminService.supplier = {customerNo: '',supplierName: '',  supplier: '', password: '', username: '', firmId: 0, id: '', sortNo: 0, active: true};
              }
          }
      );
  }
    public save(supplier: ISupplierLoginData) {
      if (supplier.id.toString() === '') {
          this.companyAdminService.createSupplier(supplier);
      } else {
          this.companyAdminService.updateSupplier(supplier);
      }
      this.companyAdminService.onSupplierChange.emit();
  }
    public back() {
      this.router.navigate(['company-admin/suppliers']);
  }
}
