import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import {CartService} from "../../../../cart/services/cart.service";
import {PdfService} from "../../../../shared/services/pdf.service";
import {UserService} from "../../../../shared/services/user/user.service";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {OrderHistoryService} from '../services/order-history.service';
import {Location} from '@angular/common';
import {HelperService} from '../../../../shared/services/helper.service';

@Component({
    selector: 'app-order-print',
    templateUrl: './order-print.component.html',
    encapsulation: ViewEncapsulation.None
})
export class OrderPrintComponent implements OnInit {
    public pdfLoading: boolean = false;
    public pdf: any;

    constructor(public cartService: CartService,
                public pdfService: PdfService,
                public userService: UserService,
                public translate: TranslateService,
                private router: Router,
                public orderHistoryService: OrderHistoryService,
                public location: Location,
                public helperService: HelperService) {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
    }

    ngOnInit() {
        if (this.router.url.indexOf('company-admin') > -1) {
            if (!this.orderHistoryService.selectedHistory || !this.orderHistoryService.selectedCart) {
                this.router.navigate([this.router.url.substring(0, this.router.url.lastIndexOf('/'))]);
            }
        }
        this.pdfLoading = true;
        this.getPdf(this.generatePdf()).then(
            (pdf: any) => {
                this.pdfLoading = false;
                this.pdf = pdf;
            },
            (error: any) => {
                this.pdfLoading = false;
            }
        );
    }

    public generatePdf() {
        if (this.orderHistoryService.cartData) {
            let currentCart = this.orderHistoryService.cartData,
                tableHeaderElements: { text: string; style: string; }[] = [
                    {text: this.translate.instant('PRINT_VIEW.ARTICLE.ARTICLE_NO'), style: 'header'},
                    {text: this.translate.instant('PRINT_VIEW.ARTICLE.DESC'), style: 'header'},
                    {text: this.translate.instant('PRINT_VIEW.ARTICLE.SUPPLIER'), style: 'header'},
                    {text: this.translate.instant('PRINT_VIEW.ARTICLE.QUANTITY'), style: 'centeredHeader'},
                    {text: this.translate.instant('PRINT_VIEW.ARTICLE.UNIT'), style: 'centeredHeader'},
                    {text: this.translate.instant('PRINT_VIEW.ARTICLE.PRICE_PIECE'), style: 'rightHeader'},
                    {text: this.translate.instant('PRINT_VIEW.ARTICLE.PRICE_FULL'), style: 'rightHeader'}
                ],
                bodyElements: any[] = [],
                docDefinition: any = this.pdfService.buildDocDefinition(false, null, true);

            // date and reference fields
            let date = new Date(currentCart.created);
            let today = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear();

            docDefinition.content.push(
                {
                    text: this.translate.instant('GENERAL.DOCS.ORDER'),
                    fontSize: 14,
                    margin: [0, 40, 0, 5]
                },
                {
                    alignment: 'justify',
                    columns: [
                        {
                            text: this.translate.instant('GENERAL.DATE') + ':',
                            width: 80
                        },
                        {
                            text: today,
                            width: 70
                        }
                    ],
                    fontSize: 10
                },
                {
                    alignment: 'justify',
                    columns: [
                        {
                            text: this.translate.instant('PRINT_VIEW.FORM.REFERENCE') + ':',
                            width: 80
                        },
                        {
                            text: (this.orderHistoryService.cartData.name ? this.orderHistoryService.cartData.name : ''),
                            width: 300
                        }
                    ],
                    fontSize: 10,
                },
                {
                    alignment: 'justify',
                    columns: [
                        {
                            text: this.translate.instant('ORDER.ORDERNUMBER'),
                            width: 80
                        },
                        {
                            text: (this.orderHistoryService.cartData.platformOrderId ? this.orderHistoryService.cartData.platformOrderId : ''),
                            width: 300
                        }
                    ],
                    fontSize: 10,
                    margin: [0, 0, 0, 5]
                }
            );

            // articles table
            bodyElements.push(tableHeaderElements);
            for (var i = 0; i < currentCart.positions.length; i++) {


                bodyElements.push([
                    {
                        text: [
                            (currentCart.positions[i].dealerArticleNumber != null ? currentCart.positions[i].dealerArticleNumber + '\n' : ''),
                            {
                                text: (currentCart.positions[i].type != 'LABOUR' ? currentCart.positions[i].ipc : ''),
                                fontSize: 8
                            }
                        ]
                    }
                    ,
                    currentCart.positions[i].description,
                    currentCart.positions[i].supplierName,
                    {text: currentCart.positions[i].quantity.toString(), alignment: 'center'},
                    {
                        text: this.translate.instant('GENERAL.UNITS.' + currentCart.positions[i].quantityUnit),
                        alignment: 'center'
                    },
                    {
                        text: (currentCart.positions[i].sellInNetPerPieceFormatted ? currentCart.positions[i].sellInNetPerPieceFormatted : '0,00 ' + ' ' + this.helperService.getCurrencySymbol(currentCart.positions[i])),
                        alignment: 'right'
                    },
                    {
                        text: (currentCart.positions[i].sellInNetTotalFormatted ? currentCart.positions[i].sellInNetTotalFormatted : '0,00 ' + ' ' + this.helperService.getCurrencySymbol(currentCart.positions[i])),
                        alignment: 'right'
                    }
                ])


            }

            let width = ['16%', '27%', '10%', '10%', '10%', '12%', '15%'];


            docDefinition.content.push(
                {
                    style: 'cartTable',
                    table: {
                        headerRows: 1,
                        widths: width,
                        body: bodyElements
                    },
                    layout: {
                        hLineColor: function (i: any, node: any) {
                            return (i === 0 || i > 1 || i === node.table.body.length) ? 'white' : '';
                        },
                        vLineColor: function (i: any, node: any) {
                            return 'white';
                        }
                    }
                }
            );

            docDefinition.content.push(
                {
                    table: {
                        widths: ['*'],
                        body: [
                            [
                                {
                                    text: ""
                                }
                            ]
                        ]
                    },
                    layout: {
                        hLineColor: function (i: any, node: any) {
                            return (i <= 0) ? '#666' : 'white';
                        },
                        vLineColor: function (i: any, node: any) {
                            return (i === 0 || i === node.table.widths.length) ? 'white' : '';
                        }
                    },
                    margin: [0, 40, 0, 0]
                },
                {
                    style: 'pricesTable',
                    table: {
                        widths: [140, 100],
                        body: [
                            [
                                {
                                    text: this.translate.instant('CART.SUM'),
                                    bold: true
                                },
                                {
                                    text: (currentCart.cartCostDTO.sellInNetTotalFormatted ? currentCart.cartCostDTO.sellInNetTotalFormatted : '0,00 ' + ' ' + this.helperService.getCurrencySymbol(currentCart.positions[i])),
                                    alignment: 'right'
                                }
                            ],
                            [
                                {
                                    text: this.translate.instant('CART.VAT') + ' (' + currentCart.cartCostDTO.vat + '%)',
                                    bold: true
                                },
                                {
                                    text: (currentCart.cartCostDTO.sellInVatTotalFormatted ? currentCart.cartCostDTO.sellInVatTotalFormatted : '0,00 ' + ' ' + this.helperService.getCurrencySymbol(currentCart.positions[i])),
                                    alignment: 'right'
                                }
                            ],
                            [
                                {text: this.translate.instant('CART.TOTAL_PRICE'), bold: true, fontSize: 16},
                                {
                                    text: (currentCart.cartCostDTO.sellInGrossTotalFormatted ? currentCart.cartCostDTO.sellInGrossTotalFormatted : '0,00 ' + ' ' + this.helperService.getCurrencySymbol(currentCart.positions[i])),
                                    alignment: 'right',
                                    fontSize: 16,
                                    bold: true
                                }
                            ]
                        ],
                        alignment: 'right'
                    },
                    layout: 'noBorders'
                }
            );

            docDefinition.content.push({
                table: {
                    widths: ['*'],
                    body: [
                        [
                            {
                                text: "",
                                alignment: 'center'

                            }
                        ]
                    ]
                },
                layout: {
                    hLineColor: function (i: any, node: any) {
                        return (i <= 0) ? '#666' : 'white';
                    },
                    vLineColor: function (i: any, node: any) {
                        return (i === 0 || i === node.table.widths.length) ? 'white' : '';
                    }
                },
                style: 'carData'
            });

            return docDefinition;
        }
    }

    public downloadPdf() {
        return pdfMake.createPdf(this.generatePdf()).download();
    }

    public printPdf() {
        return pdfMake.createPdf(this.generatePdf()).print();
    }

    public back() {
        this.router.navigate(['company-admin/orderhistory/' + this.orderHistoryService.selectedHistory]);
    }

    public getPdf(docDefinition: any) {
        return <any>new Promise(function (resolve: any, reject: any) {
            pdfMake.createPdf(docDefinition, 'filename').getBase64(function (pdfBase64: any) {
                var raw = atob(pdfBase64);
                var uint8Array = new Uint8Array(raw.length);
                for (var i = 0; i < raw.length; i++) {
                    uint8Array[i] = raw.charCodeAt(i);
                }
                resolve(uint8Array);
            });
        });
    }
}
