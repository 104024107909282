import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import 'rxjs/Rx';
import 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {ICredentials} from './interfaces/credentials';
import {Router} from '@angular/router';
import {UserService} from '../../shared/services/user/user.service';
import {DmsDataService} from '../../shared/services/dms-data.service';
import {TranslateService} from '@ngx-translate/core';
import {PdfService} from '../../shared/services/pdf.service';
import {CustomerDataService} from '../../shared/services/customerData.service';
import {ThemeService} from '../../shared/services/theme.service';
import {ISessionDetails} from '../../shared/interfaces/DTO/sessionDetails';

declare let btoa;

@Injectable()
export class AuthService {
    public isLoggedIn: boolean = false;
    public error: boolean = false;
    public fromLoginComponent: boolean = false;
    public showLoginLoader: boolean | false;
    public logoutUrl: string;

    constructor(
        private customerDataService: CustomerDataService,
        private http: HttpClient,
        private router: Router,
        private userService: UserService,
        private dmsDataService: DmsDataService,
        private translate: TranslateService,
        private themeService: ThemeService,
        private pdfService: PdfService
    ) {
        this.userService.accountData = undefined;
        if (!location.href.includes('n4sso/error')) {
            this.login();
        }
    }

    public login(credentials?: ICredentials, rememberMe?: boolean, fromLoginComponent?: boolean) {
        this.showLoginLoader = true;
        {
            this.error = false;
            if (fromLoginComponent && fromLoginComponent === true) {
                this.fromLoginComponent = true;
            }
            let headers: HttpHeaders;

            if (credentials) {
                headers = new HttpHeaders({Authorization: 'Basic ' + btoa(credentials.username + ':' + credentials.password)});
            }

            this.http.get<ISessionDetails>(
                environment.apiUrl + '/session/login' + (rememberMe ? '?remember-me=true' : '')
                , {headers: headers}
            ).subscribe(
                (response: any) => {
                    this.showLoginLoader = false;
                    this.isLoggedIn = true;
                    this.error = false;
                    if (response.language) {
                        this.translate.use(response.language);
                    }
                    this.userService.accountData$.next(response);
                    this.userService.loginBehavior$.next(true);
                    if (credentials) {
                        void this.router.navigate(['/']);
                    }
                    return true;
                },
                (error) => {
                    this.userService.loginBehavior$.next(false);
                    this.showLoginLoader = false;
                    if (error.status === 403) {
                        this.userService.showRoleError = true;
                    } else {
                        this.userService.showRoleError = false;
                        this.themeService.customLogin();
                        this.isLoggedIn = false;
                        this.error = true;
                        return false;
                    }
                }
            );

        }
    }

    public logout(): void {
        this.userService.onLogOut$.emit();
        setTimeout(() => {
            this.isLoggedIn = false;
            this.dmsDataService.dmsData = '';
            this.customerDataService.resetDataHolder();
            this.logoutRest().subscribe(
                () => {
                    this.userService.showRoleError = false;
                    void this.router.navigate(['/login']);
                    this.themeService.customLogin();
                },
                () => {
                    this.userService.showRoleError = false;
                    void this.router.navigate(['/login']);
                    this.themeService.customLogin();
                }
            );
        }, 50);
    }

    private logoutRest(): Observable<any> {
        if (this.logoutUrl) {
            return this.http.get(
                this.logoutUrl
            );
        } else {
            return this.http.get(
                environment.apiUrl + '/logout'
            );
        }
    }

    public resetPW(username: string): Observable<any> {
        return this.http.put(
            `/reset-password?username=${username}`, {}
        );
    }
}
