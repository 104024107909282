<div class="saved-carts">
    <ngb-tabset #tabs (tabChange)="onTabChange()">
        <!-- Warenkörbe -->
        <ngb-tab id="cart-tab">
            <ng-template ngbTabTitle>
                <div class="row">
                    <span class="col-4"></span>
                    <span class="col-4">{{ 'CART.CARTS' | translate }}</span>
                    <span class="col-4 text-right" (click)="createNewCart()">
                        <i class="fa fa-cart-plus fa-2x"></i>
                    </span>
                </div>
            </ng-template>
            <ng-template ngbTabContent>
                <div class="carts-container">
                    <app-default-loading-page *ngIf="cartService.cartListLoading"></app-default-loading-page>
                    <div class="cartSearch ">
                        <app-material-form-input [text]="searchString" [label]="'CART.CART_SEARCH' | translate"
                                                 (textChange)="reduceCartTree($event)"></app-material-form-input>
                        <i class="fa fa-search" *ngIf="searchString?.length === 0"></i>
                        <i class="fa fa-times" *ngIf="searchString?.length > 0" (click)="reduceCartTree('')"></i>
                    </div>
                    <app-pagination page="{{cartService?.cartResponse?.page + 1}}"
                                    perPage="{{cartService?.cartResponse?.pageSize}}"
                                    count="{{cartService?.cartResponse?.totalElements}}"
                                    pagesToShow="3" (goPage)="getCartPage($event)">
                    </app-pagination>
                    <div class="scrollable-cart-list">
                        <ul class="custom-tree" *ngIf="cartList?.length > 0">
                            <li class="list">
                                <div class="clickable">
                                    <i class="folder fa fa-circle open"></i>
                                </div>
                                <span>{{ 'CART.TITLE' | translate }}</span>
                                <ul>
                                    <li *ngFor="let cart of cartList"
                                        class="list">
                                        <div class="clickable">
                                            <i class="folder fa fa-circle" (click)="selectCart(cart)"
                                               [ngClass]="{ 'open': cart.id === cartService?.currentCart?.id }"></i>
                                            <i class="fa fa-trash-o"
                                               (click)="cartService.deleteCart(cart.id, $event)"></i>
                                        </div>

                                        <span class="cartName" (click)="selectCart(cart)">
                            {{ cart.name ? cart.name : 'Warenkorb ' + ($index + 1) }}
                                            ({{ cart.created | date:'dd.MM.yyyy' }})<br/>
                                            {{ cart.count ? (cart.count + ' ' + ('CART.ARTICLES' | translate)) : ('0 ' + ('CART.ARTICLES' | translate)) }}
                            </span>
                                        <ul *ngIf="cart.id === cartService.currentCart?.id">
                                            <li *ngIf="configService.getActiveForName('EXTENDED_SETTINGS', 'FREE_ARTICLES')"
                                                class="list"
                                                [ngClass]="{ 'active': location.path().endsWith('/article')}">
                                                <div class="clickable" routerLink="/cart/article">
                                                    <i class="folder fa fa-circle"
                                                       [ngClass]="{ 'open': location.path().endsWith('/article')}"></i>
                                                </div>
                                                <span routerLink="/cart/article">
                                        {{ 'CART.ADD_ARTICLE_POPUP.TITLE' | translate }}
                                    </span>
                                            </li>
                                            <li class="list"
                                                [ngClass]="{ 'active': location.path().endsWith('/offer')}">
                                                <div class="clickable" routerLink="/cart/offer">
                                                    <i class="folder fa fa-circle"
                                                       [ngClass]="{ 'open': location.path().endsWith('/offer')}"></i>
                                                </div>
                                                <span routerLink="/cart/offer">
                                        {{ 'PRINT_VIEW.PRINT_OFFER' | translate }}
                                    </span>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div class="noResult alert alert-info text-center"
                         *ngIf="searchString.length > 0 && cartList.length === 0">
                        <i class="fa fa-info-circle mr-2"></i>
                        <span>{{ 'CART.NO_RESULT' | translate }}</span>
                    </div>
                </div>
            </ng-template>
        </ngb-tab>

        <!-- Bestellungen -->
        <ngb-tab id="orders-tab">
            <ng-template ngbTabTitle>
                <div class="row">
                    <span class="col-4"></span>
                    <span class="col-4">{{ 'CART.ORDERS' | translate }}</span>
                    <span class="col-4"></span>
                </div>
            </ng-template>
            <ng-template ngbTabContent>
                <div class="orders-container">
                    <app-default-loading-page *ngIf="cartService.loadCartOrderList"></app-default-loading-page>

                    <div class="cartSearch">
                        <app-material-form-input [text]="orderSearchString" [label]="'CART.CART_SEARCH' | translate"
                                                 (textChange)="reduceCartOrderTree($event)"></app-material-form-input>
                        <i class="fa fa-search" *ngIf="orderSearchString?.length === 0"></i>
                        <i class="fa fa-times" *ngIf="orderSearchString?.length > 0"
                           (click)="reduceCartOrderTree('')"></i>
                    </div>

                    <ng-container *ngIf="cartOrderList?.length > 0">
                        <app-pagination page="{{cartService?.cartOrderList?.page + 1}}"
                                        perPage="{{cartService?.cartOrderList?.pageSize}}"
                                        count="{{cartService?.cartOrderList?.totalElements}}"
                                        pagesToShow="3" (goPage)="getCartOrderPage($event)">
                        </app-pagination>

                        <ul class="custom-tree">
                            <li class="list">
                                <div class="clickable">
                                    <i class="folder fa fa-circle open"></i>
                                </div>
                                <span>{{ 'CART.ORDERS' | translate }}</span>
                                <ul>
                                    <li *ngFor="let order of cartOrderList" class="list"
                                        (click)="showOrderPreview(order)">
                                        <div class="clickable">
                                            <i class="folder fa fa-circle"
                                               [ngClass]="{ 'open': order.id === cartService?.currentCart?.id || order.id === cartService?.previewCart?.id}"></i>
                                        </div>

                                        <span class="cartName">
                            {{ order.name ? order.name : 'Bestellung ' + ($index + 1) }}
                                            ({{ order.created | date:'dd.MM.yyyy' }})
                            </span>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </ng-container>
                    <ng-container *ngIf="!(cartOrderList?.length > 0) && !cartService.loadCartOrderList">
                        <div class="noResult alert alert-info text-center">
                            <i class="fa fa-info-circle mr-2"></i>
                            <span>{{ 'CART.NO_ORDER_RESULT' | translate }}</span>
                        </div>
                    </ng-container>
                </div>
            </ng-template>
        </ngb-tab>
    </ngb-tabset>

</div>
