import {Injectable} from '@angular/core';
import {AssortmentRestService} from './assortment-rest.service';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {debounceTime, filter, map} from 'rxjs/operators';
import {IData} from '../interfaces/data';
import {HelperService} from '../../../shared/services/helper.service';
import {AdminService} from '../../club/services/admin.service';

@Injectable({
    providedIn: 'root'
})
export class DatamapperService {

    public genArts: BehaviorSubject<IData[]> = new BehaviorSubject<IData[]>(undefined);
    public dataSuppliers: BehaviorSubject<IData[]> = new BehaviorSubject<IData[]>(undefined);
    public loadingAssortment: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    constructor(private assortmentRestService: AssortmentRestService,
                private adminService: AdminService,
                private helperService: HelperService) {
    }


    public getData() {
        combineLatest(
            this.assortmentRestService.getGenArts(this.adminService.getClubId()),
            this.assortmentRestService.getDataSuppliers()).subscribe(([genarts, datasuppliers]) => {
            this.genArts.next(genarts);
            this.dataSuppliers.next(datasuppliers);
            if ((this.genArts && this.genArts.value.length === 0) || (this.dataSuppliers && this.dataSuppliers.value.length === 0)) {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_ASSORTMENT_DATA', 'error');
            }
            this.loadingAssortment.next(false);
        }, () => {
            this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_ASSORTMENT_DATA', 'error');
            this.loadingAssortment.next(false);
        })
    }


    public mapDataSupplier(id: number) {
        const found = this.dataSuppliers?.value.find(elem => elem.id === id)?.name;
        if (found) {
            return found
        } else {
            return '';
        }
    }


    public mapGenArt(id: number) {
        if (this.genArts.value) {
            return this.genArts.value.find(elem => elem.id === id)?.description;
        } else {
            return '';
        }
    }

    formatterBrand = (item: IData) => (item.name + ' (' + item.id + ')');
    formatterGenArt = (item: IData) => (item.description + ' (' + item.id + ')');

    searchGenArt = (text$: Observable<string>) => text$.pipe(
        debounceTime(200),
        filter(term => term.length >= 1),
        map(term => this.genArts.value.filter(v => v.description.toLowerCase().indexOf(term.toLowerCase()) > -1 || v.id === Number(term)).slice(0, 30))
    )

    searchDataSupplier = (text$: Observable<string>) => text$.pipe(
        debounceTime(200),
        filter(term => term.length >= 1),
        map(term => this.dataSuppliers.value.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1 || v.id === Number(term)).slice(0, 30))
    )

}
