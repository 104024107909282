<div class="m-3">
    <div class="lead mb-5">
        {{ 'FIRM_CALCULATION.CHECK_CALCULATION' | translate }}
    </div>
    <div class="row">
        <div class="col-12 d-flex mb-2">
                <span class="pl-2">
                    {{ 'FIRM_CALCULATION.CALCULATION_TYPE' | translate }}:
                </span>
            <span class="pl-2">
                    {{ 'FIRM_CALCULATION.' + adminService.selectedCalculationType | translate }}
                </span>
        </div>
        <div class="col-12">
            <fieldset class="material-form-input">
                <input id="supplier" type="text"
                       placeholder="{{'FIRM_CALCULATION.SUPPLIER_SEARCH' | translate}}"
                       [(ngModel)]="manufacturer"
                       [ngbTypeahead]="searchDataSupplier"
                       [inputFormatter]="formatterBrand"
                       [resultFormatter]="formatterBrand"
                       [container]="'body'"
                       [editable]='false'/>
                <hr>
                <i class="fa fa-search search-icon"></i>
            </fieldset>
        </div>
        <div class="col-12">
            <fieldset class="material-form-input">
                <input id="genArt" type="text"
                       placeholder="{{'FIRM_CALCULATION.ARTICLES_SEARCH' | translate}}"
                       [(ngModel)]="genArt"
                       [ngbTypeahead]="searchGenArt"
                       [inputFormatter]="formatterGenArt"
                       [resultFormatter]="formatterGenArt"
                       [container]="'body'"
                       [editable]='false'/>
                <hr>
                <i class="fa fa-search search-icon"></i>
            </fieldset>
        </div>
        <div class="col-12">
            <div class="row">
                <div class="col-11">
                    <app-material-form-input
                            [label]="'FIRM_CALCULATION.PRICE' | translate"
                            [type]="'number'"
                            [(text)]="sellInToCalculate">
                    </app-material-form-input>
                </div>
                <div class="col-1 d-flex align-items-center pl-0">
                        <span class="mt-3">
                            {{ helperService.getCurrencySymbol(undefined) }}
                        </span>
                </div>
            </div>
        </div>

        <div class="col-12">
            <button class="base-btn success mr-3"
                    [disabled]="!(manufacturer && genArt && sellInToCalculate)"
                    (click)="checkCalculation()">
                {{ 'FIRM_CALCULATION.CHECK_CALCULATION' | translate }}
            </button>
            <button class="base-btn  ml-3"
                    (click)="resetCheck()">
                {{ 'PROMPTS.BUTTONS.RESET' | translate }}
            </button>
        </div>
    </div>

    <div class="row mt-5" *ngIf="checkResult">
        <div class="col-12">
            <span class="pl-2">
                {{ 'FIRM_CALCULATION.PRICE' | translate }}
            </span>
            <span class="font-weight-bold pl-2">
                    {{ checkResult.formattedSellIn }}
            </span>
        </div>
        <div class="col-12">
            <span class="pl-2">
                {{ 'FIRM_CALCULATION.CALCULATED_PRICE' | translate }}
            </span>
            <span class="font-weight-bold pl-2">
                    {{ checkResult.formattedSellOut }}
             </span>
        </div>
    </div>
</div>