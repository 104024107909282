<div class="list-wrapper drag-list" cdkDropList
     (cdkDropListDropped)="cdkDropListDropped($event); configService.addUnsavedSetting(settingName)"
     [cdkDropListDisabled]="!(configService.getSettingByName(settingName)?.sortable)">

    <ng-container *ngFor="let setting of settingList" >
        <div class="row drag-box"
             [ngClass]="{'clickable': configService.isSelectableSetting(setting)}" cdkDrag
             (click)="configService.setSelectedSetting(setting)">
            <div class="col-12 d-flex">
                <span class="setting-name"
                          *ngIf="settingName !== 'QUICKLINKS'">{{'CLUB_SETTINGS.' + settingName + '.' + setting.name | translate}}</span>
                <span class="setting-name"
                      *ngIf="settingName === 'QUICKLINKS'">{{getTranslation(setting.name)}}</span>
                <div class="ml-auto">
                    <span class="multiple-settings-icon" *ngIf="configService.isSelectableSetting(setting)">
                      <i class="fa fa-cog" [ngClass]="{'active': configService.selectedSetting === setting}"></i>
                    </span>
                    <app-checkbox [isChecked]="setting.active" (onChange)="changeActive(setting, $event)"
                                  [labelText]="'GENERAL.ACTIVE' | translate">
                    </app-checkbox>
                </div>
            </div>
        </div>
    </ng-container>
</div>
