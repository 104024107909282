import {EventEmitter, Injectable} from '@angular/core';
import {UserService} from '../../../shared/services/user/user.service';
import {FirmAdminRestService} from '../../../shared/services/firm-admin.rest.service';
import {AccountRestService} from '../../profile/services/account.rest.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {ErrorHandlerService} from '../../../shared/components/error-handler/services/error-handler.service';
import {AdminService} from '../../club/services/admin.service';
import {Router} from '@angular/router';
import {ISupplierLoginData} from '../../../shared/interfaces/DTO/firm/supplierLoginData';
import {ISupplier} from '../../../shared/interfaces/DTO/firm/supplier';
import {IFirm, IFirmSetting} from '../../../shared/interfaces/DTO/firm/firm';
import {IFirmVinStatistics} from '../../../shared/interfaces/DTO/firm/firmVinStatistics';
import {ConfigService} from '../../../shared/services/config.service';
import {AutovistaRestService} from '../../../graphical-part-search/services/autovista.rest.service';
import {HelperService} from '../../../shared/services/helper.service';
import {IAccountData} from "../../../shared/services/user/interfaces/account-data";
import {error} from "protractor";


@Injectable()

export class CompanyAdminService {
    public firmData: IFirm;
    public firmData$: EventEmitter<IFirm> = new EventEmitter<IFirm>();
    public newUserData$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    public userData: any;
    public supplier: any;
    public onSupplierChange: EventEmitter<any> = new EventEmitter<any>();
    public firmSettings: Map<string, IFirmSetting>;
    public firmSettingEmitter: EventEmitter<any> = new EventEmitter<any>();
    public hasChanges = false;
    public hasChangesBlacklist = false;
    public hasLogoChanges = false;
    public firmStatistics: IFirmVinStatistics;
    public currentLogo = '';

    constructor(public userService: UserService,
                private firmAdminRestService: FirmAdminRestService,
                private accountRestService: AccountRestService,
                private helperService: HelperService,
                private http: HttpClient,
                private errorHandlerService: ErrorHandlerService,
                private autovistaRestService: AutovistaRestService,
                private adminService: AdminService,
                private router: Router,
                private configService: ConfigService) {

        this.userService.accountData$.subscribe((step) => {
            if (step) {
                if (this.userService.allow('FIRM-ADMIN')) {
                    this.getFirmSettings().subscribe((response) => {
                        this.firmSettings = response;
                        this.firmSettingEmitter.emit(this.firmSettings);
                        this.checkFirmSettingValues();
                    });
                } else {
                    this.userService.getFirmSettings().subscribe((settings) => {
                        this.firmSettings = settings;
                        this.firmSettingEmitter.emit(this.firmSettings);
                        this.checkFirmSettingValues();
                    })
                }

                this.hasChanges = false;
                this.hasChangesBlacklist = false;
                if (!this.firmData && this.router.url.indexOf('/company-admin') > -1) {
                    this.updateFirmData();
                }
            }


        });
    }



    public checkFirmSettingValues() {
        Object.keys(this.firmSettings).forEach((settingKey: string) => {
            if (this.firmSettings[settingKey].value === 'false') {
                this.firmSettings[settingKey].value = false;
            }

            if (this.firmSettings[settingKey].value === 'true') {
                this.firmSettings[settingKey].value = true;
            }
        });
    }

    public getFirmVinStatistics() {
        if (this.userService.allow('FIRM_STATISTIC')) {
            this.autovistaRestService.getFirmVinStatisticRest(this.userService.accountData.firmId).subscribe(
                (firmStatistics) => {
                    this.firmStatistics = firmStatistics;
                },
                (error) => {
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_VIN_STATISTICS', 'error');
                });
        }
    }

    public updateFirmData() {
        if (this.userService.allow('FIRM-ADMIN')) {
            this.getFirmData().subscribe(
                (response) => {
                    this.firmData$.emit(response);
                    this.firmData = response;
                }
            );
        }
    }

    public getUserById(userID: number): Observable<any> {
        return this.http.get(
            `${environment.apiUrl}/firmadmin/login/${userID}`
        );
    }

    public setUserData(userData: IAccountData, userID: number, showToast: boolean = false) {
        this.firmAdminRestService.setUserByID(userData, userID).subscribe(
            (success) => {
                this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_UPDATE', 'success');
                this.updateFirmData();
            },
            (error) => {
                this.errorHandlerService.throwError(error.status, showToast);
            }
        );
    }

    public getSuppliersRest(): Observable<ISupplierLoginData[]> {
        return this.http.get<ISupplierLoginData[]>(
            `${environment.apiUrl}/firmadmin/suppliers`
        );
    }

    public getUnassignedsuppliers(): Observable<ISupplier[]> {
        return this.http.get<ISupplier[]>(
            `${environment.apiUrl}/firmadmin/unassignedsuppliers`
        );
    }

    public getFirmDataById(firmId: number): Observable<IFirm> {
        return this.http.get<IFirm>(
            `${environment.apiUrl}/admin/firm/${firmId}`
        ).do(response => {
            this.firmData = response;
        });
    }


    public deleteSupplierRest(supp: any): Observable<any> {
        return this.http.delete(
            `${environment.apiUrl}/firmadmin/suppliers/${supp.id}`,
        );
    }

    public createUser(userData: IAccountData, showToast: boolean = false) {
        this.firmAdminRestService.createUser(userData).subscribe(
            (user) => {
                this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_UPDATE', 'success');
                this.updateFirmData();
                this.newUserData$.next(user);
            },
            (error) => {
                this.errorHandlerService.throwError(error.status, showToast);
                this.newUserData$.next(undefined);
            });
    }

    public createSupplierRest(supplier: ISupplierLoginData): Observable<any> {
        return this.http.post(
            `${environment.apiUrl}/firmadmin/suppliers`,
            supplier
        );
    }

    public updateSupplierRest(supplier: ISupplierLoginData): Observable<any> {
        return this.http.put(
            `${environment.apiUrl}/firmadmin/suppliers`,
            supplier
        );
    }

    public updateAllSuppliersRest(suppliers: ISupplierLoginData[]) {
        return this.http.put<any>(
            `${environment.apiUrl}/firmadmin/supplier/list`,
            suppliers
        )
    }

    public updateSettings(firmData: IFirmSetting): Observable<any> {
        return this.http.put<any>(
            `${environment.apiUrl}/firmadmin/firm/settings`,
            firmData
        );
    }

    public getFirmData(): Observable<IFirm> {
        return this.http.get<IFirm>(
            `${environment.apiUrl}/firmadmin/firm`
        );
    }

    public getFirmSettings(): Observable<Map<string, IFirmSetting>> {
        return this.http.get<Map<string, IFirmSetting>>(
            `${environment.apiUrl}/firmadmin/firm/settings`
        );
    }

    public saveFirm(firmData: any) {
        this.firmAdminRestService.updateFirm(firmData).subscribe(
            (response) => {
                if (!this.configService.getActiveForName('GLOBAL_FIRM_LOGO', 'GLOBAL_FIRM_LOGO')) {
                    this.userService.firmLogo = this.currentLogo;
                }
                this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_UPDATE', 'success');
            },
            (error) => {
                this.errorHandlerService.throwError(error.status);
            });
    }

    public deleteSupplier(supp: any) {
        return this.deleteSupplierRest(supp).subscribe(
            (success) => {
                this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_UPDATE', 'success');
                return true;
            },
            (error) => {
                this.errorHandlerService.throwError(error.status);
            });
    }

    public createSupplier(supplier: ISupplierLoginData) {
        this.createSupplierRest(supplier).subscribe(
            (success) => {
                this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_UPDATE', 'success');
                this.userService.getSuppliers(true);
                this.router.navigate(['company-admin/suppliers']);
            },
            (error) => {
                this.errorHandlerService.throwError(error.status);
            });
    }

    public updateSupplier(supplier: ISupplierLoginData) {
        this.updateSupplierRest(supplier).subscribe(
            (success) => {
                this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_UPDATE', 'success');
                this.userService.getSuppliers(true);
                this.router.navigate(['company-admin/suppliers']);
            },
            (error) => {
                this.errorHandlerService.throwError(error.status);
            });
    }
}
