import {Component, OnDestroy} from '@angular/core';
import {IData} from "../../assortment/interfaces/data";
import {Observable} from "rxjs";
import {debounceTime, filter, map} from "rxjs/operators";
import {AdminService} from "../../club/services/admin.service";
import {IDataSupplier} from "../../../shared/interfaces/DTO/firm/DataSuppliers";
import {ICalculationCheck, ICalculationCheckResponse} from "../../../shared/interfaces/DTO/firm/calculation";
import {HelperService} from "../../../shared/services/helper.service";
import {IArticleTreeItem} from "../../../articles/interfaces/article-tree-item";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-firm-check-calculation',
    templateUrl: './firm-check-calculation.component.html',
    styleUrls: ['./firm-check-calculation.component.scss']
})
export class FirmCheckCalculationComponent implements OnDestroy {
    public genArt: IArticleTreeItem;
    public manufacturer: IDataSupplier;
    public sellInToCalculate: number = 10;
    public checkResult: ICalculationCheckResponse = undefined;

    constructor(
        public helperService: HelperService,
        public adminService: AdminService,
        private translate: TranslateService) {
    }

    ngOnDestroy(): void {
        this.checkResult = undefined;
    }

    public resetCheck(): void {
        this.checkResult = undefined;
        this.genArt = undefined;
        this.manufacturer = undefined;
        this.adminService.highlightedCheckedCalculationId$.next(undefined);
    }

    public checkCalculation(): void {
        const mappedCalcValue: ICalculationCheck = {
            genArtId: this.genArt.genericArticleIds[0],
            brandId: this.manufacturer.id,
            sellInToCalculate: this.sellInToCalculate,
            calculationType: this.adminService.selectedCalculationType
        };
        this.adminService.checkCalculation(mappedCalcValue).subscribe(
            (res: ICalculationCheckResponse) => {
                this.checkResult = res;
                this.adminService.highlightedCheckedCalculationId$.next(res.id)
            },
            () => {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_GET', 'error');
            }
        )
    }

    formatterBrand = (item: IData) => (item.name);
    formatterGenArt = (item: IData) => (item.name);

    searchGenArt = (text$: Observable<string>) => text$.pipe(
        debounceTime(200),
        filter(term => term.length >= 1),
        map(term => {
            const leafNodesP = this.flattenTree(this.adminService.firmCalculationGenArtP);
            const leafNodesU = this.flattenTree(this.adminService.firmCalculationGenArtU);
            const universalLabel = this.translate.instant('FIRM_CALCULATION.UNIVERSAL_RELATED');

            const combinedNodes = [
                ...leafNodesP,
                ...leafNodesU.map(v => ({...v, name: `${v.name} (${universalLabel})`}))
            ];

            return combinedNodes.filter(v =>
                v.name.toLowerCase().indexOf(term.toLowerCase()) > -1 || v.id === Number(term)
            ).slice(0, 30);
        })
    )

    private flattenTree(nodes: any[]): any[] {
        let leafNodes: any[] = []
        nodes.forEach(node => {
            if (!node.children || node.children.length === 0) {
                leafNodes.push(node)
            } else {
                leafNodes = leafNodes.concat(this.flattenTree(node.children))
            }
        })
        return leafNodes
    }


    searchDataSupplier = (text$: Observable<string>) => text$.pipe(
        debounceTime(200),
        filter(term => term.length >= 1),
        map(term => {
            const dataSuppliers = this.adminService.selectedCalculationType === 'AFTER_MARKET'
                ? this.adminService.aftermarketDataSuppliers
                : this.adminService.oeDataSuppliers

            return dataSuppliers.filter(v =>
                v.name.toLowerCase().indexOf(term.toLowerCase()) > -1 || v.id === Number(term)
            ).slice(0, 30)
        })
    )
}
