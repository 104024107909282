import {EventEmitter, Injectable} from '@angular/core';
import {CarRestService} from './car.rest.service';
import {DatePipe} from '@angular/common';
import {ICarSearchTypes} from '../../components/car-selection/interfaces/car-search-types';
import {UserService} from '../user/user.service';
import {ICar, ICarNumberplate} from '../../interfaces/DTO/car/car';
import {CustomerDataService} from '../customerData.service';
import {AutovistaRestService} from '../../../graphical-part-search/services/autovista.rest.service';
import {HelperService} from '../helper.service';
import {DmsDataService} from "../dms-data.service";
import {DmsCarService} from "../dms-car.service";

@Injectable()
export class CarService {
    public selectedCar: ICar;
    public carHistory: ICar[];
    public toCarHistory: ICar = undefined;
    public carHistoryLoading = false;
    public showCarInfo = false;
    public selectedCarChanged$: EventEmitter<any> = new EventEmitter<any>();
    public setCarExternal$: EventEmitter<any> = new EventEmitter<any>();
    public setSelectedVinCar: EventEmitter<any> = new EventEmitter<any>();
    public changeCart$: EventEmitter<boolean> = new EventEmitter<boolean>();
    public changeVin$: EventEmitter<string> = new EventEmitter<string>();
    public showDMSLoader = true;
    public selected: string;
    public carSearchTypes: ICarSearchTypes[] = [];
    public newRecallInformation = true;
    public dmsKTypeCar: ICar[] = [];
    public dmsNatCodeCar: ICar[] = [];

    public fromDMS = false;
    public loadCar: string;
    public canEject = true;
    public noCarData: boolean;
    public isHsnTsnFix = false;
    public registrationDate: string;
    public autovistaVinResponse: any;
    public macsDiaLoaded: boolean;
    public dmsNoCatalog: boolean = false;
    public showPreSelection: boolean = false;
    public preSelectedVinCar: ICar[];
    public modalHidden: boolean = false;
    public showModalEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
    public typeNumber: string;
    public natcode: string;
    public selectedCarWithRawTools: ICar;

    constructor(
        private carRestService: CarRestService,
        private datePipe: DatePipe,
        private helperService: HelperService,
        private userService: UserService,
        private customerDataService: CustomerDataService,
        public autovistaRestService: AutovistaRestService) {
        this.userService.accountData$.subscribe((step) => {
            if (step) {
                this.newRecallInformation = true;
                if (step.selectedCar && this.userService.getCarChangeSettings() === 'PER_CAR') {
                    setTimeout(() => {
                        this.selectCar(step.selectedCar, true, true);
                    }, 50);
                }
                this.resetSelectedCar();
            }
        });
        this.selected = 'KBA_NUMBER';
    }


    public isCarArray(response: ICarNumberplate | ICar[]): response is ICar[] {
        return Array.isArray(response);
    }

    public isCarNumberplate(response: ICarNumberplate | ICar[]): response is ICarNumberplate {
        return response && typeof response === 'object' && 'numberplate' in response;
    }


    private addSearchType(name: string, sort: number, config?: any) {

        this.carSearchTypes.push({
            'name': name,
            'sort': sort,
            'config': config
        });

    }

    public replaceVin(vin: string) {
        if (!vin) {
            return '';
        }
        let temp = vin.toString().toUpperCase().replace(/O/g, '0');
        temp = temp.toString().toUpperCase().replace(/I/g, '1');

        return temp;
    }

    public openServiceBook() {
        window.open(this.selectedCar.digitalServiceBook);
    }

    public ejectCar(emitChange: boolean = true) {
        this.selectedCar = null;
        this.autovistaVinResponse = null;
        this.canEject = false;
        this.typeNumber = null;
        if (emitChange) {
            this.selectedCarChanged$.emit(null);
        }
        this.customerDataService.resetDataHolder();
        this.updateCarHistory();
    }

    public preloadByDMS(dmsData: any) {
        if (dmsData && dmsData.kTypeId) {
            this.preloadKTypeId(dmsData.kTypeId);
        }
        if (dmsData && dmsData.natcode) {
            this.preloadNatCode(dmsData.natcode);
        }
    }

    private preloadNatCode(natcode: string) {
        if (natcode) {
            this.autovistaRestService.getCarsByNatcode(natcode).subscribe(
                (cars: ICar[]) => {
                    if (cars.length > 0) {
                        this.dmsNatCodeCar = cars;
                    }
                },
                (error) => {
                    this.dmsNatCodeCar = [];
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_CARDATA', 'error');
                }
            );
        }
    }

    public preloadKTypeId(kTypeId: number) {
        if (kTypeId) {
            this.carRestService.getCarsByKTypeNumber(kTypeId).subscribe(
                (response: ICar[]) => {
                    if (response === null) {
                        this.dmsKTypeCar = [];
                        this.helperService.showNotification('TOAST_MESSAGES.ERROR_TECDOC_RESPONSE', 'error');
                    } else {
                        this.dmsKTypeCar = response;
                    }
                },
                () => {
                    this.dmsKTypeCar = [];
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_CARDATA', 'error');
                }
            );
        }
    }

    public selectCar(car: ICar, addToHistory: boolean = true, keepCart: boolean = false) {
        if (car) {
            this.newRecallInformation = true;
            this.registrationDate = null;
            this.showDMSLoader = false;
            if (car && (!this.selectedCar || this.selectedCar && car.adcTypeId !== this.selectedCar.adcTypeId || this.selectedCar && car.kTypeId !== this.selectedCar.kTypeId) || this.isHsnTsnFix) {
                this.selectedCar = car;
                if (car) {
                    car.fromDMS = this.fromDMS;
                    car.keepCart = keepCart;
                }
                if (car.vin && (this.customerDataService.getVin().length < 1)) {
                    this.customerDataService.setVin(car.vin);
                }
                if (car.firstRegistrationDate && !this.customerDataService.getRegistrationDate()) {
                    const year = parseInt(car.firstRegistrationDate.substr(car.firstRegistrationDate.lastIndexOf('.') + 1));
                    const month = parseInt(car.firstRegistrationDate.substr(car.firstRegistrationDate.indexOf('.') + 1, car.firstRegistrationDate.lastIndexOf('.'))) - 1;
                    const day = parseInt(car.firstRegistrationDate.substr(0, car.firstRegistrationDate.indexOf('.')));
                    const date = new Date(year, month, day);
                    this.customerDataService.setRegistrationDate(date);
                }
                this.selectedCarChanged$.emit(car);
                this.fromDMS = false;

                if (this.selectedCar && this.selectedCar.serviceInfos && this.selectedCar.serviceInfos.length > 0) {
                    if (typeof this.selectedCar.serviceInfos[0].validFrom === 'number') {
                        for (let i = 0; i < this.selectedCar.serviceInfos.length; i++) {
                            this.selectedCar.serviceInfos[i].validFrom = this.datePipe.transform(
                                new Date(this.selectedCar.serviceInfos[i].validFrom), 'dd.MM.yyyy'
                            );
                            this.selectedCar.serviceInfos[i].validTo = this.datePipe.transform(
                                new Date(this.selectedCar.serviceInfos[i].validTo), 'dd.MM.yyyy'
                            );
                            this.selectedCar.serviceInfos[i].classification = this.selectedCar.serviceInfos[i].classification.toUpperCase();
                        }
                    }
                }

                if (car && addToHistory) {
                    this.carRestService.addToCarHistory(car).subscribe(() => {
                            this.updateCarHistory();
                        },
                        (error) => {
                            if (error.status === 409) {
                                this.handleCarConflict();
                            }
                        });
                }

                this.macsDiaLoaded = false;
            }
            // Workaround MSP-1446
            if (car.adcTypeId === this.selectedCar.adcTypeId && car.kTypeId === this.selectedCar.kTypeId) {
                this.isHsnTsnFix = true;
            }
            this.getAutovistaVinResponse();
        }
    }

    public handleCarConflict():void {
        this.helperService.showNotification('TOAST_MESSAGES.ERROR_CAR_CONFLICT_NEW_WK', 'warning');
        this.changeCart$.emit(true);
    }

    public resetSelectedCar() {
        this.selectedCar = undefined;
    }

    public showPreselectionModal() {
        this.showModalEmitter.emit(true);
    }

    public getAutovistaVinResponse() {
        if (this.selectedCar.vin) {
            this.autovistaRestService.getAutovistaVinRest(this.replaceVin(this.selectedCar.vin), true).subscribe(
                (response: any) => {
                    if (!response.errorCode && response.carDtos.length === 1) {
                        this.autovistaVinResponse = response;
                        this.getRegistrationDate();
                    } else {
                        this.autovistaVinResponse = undefined;
                    }
                },
                (error) => {
                    this.autovistaVinResponse = undefined;
                    if (error.status === 409) {
                        this.handleCarConflict();
                    }
                });
        }
    }

    public getRegistrationDate() {
        if (this.autovistaVinResponse) {
            if (this.autovistaVinResponse['carDtos']['0'] && this.autovistaVinResponse['carDtos']['0']['autovistaVinResponse']) {
                const registrationDate = this.autovistaVinResponse['carDtos']['0']['autovistaVinResponse']['specification']['registrationDate'];
                this.setDate(registrationDate);
            }
        }
    }

    public setDate(date) {
        const year = date.substring(0, 4);
        const month = date.substring(4, 6);
        const day = date.substring(6, 8);
        date = day + '.' + month + '.' + year;
        this.registrationDate = date;
    }

    public updateCarHistory() {
        this.carHistoryLoading = true;
        this.carRestService.getCarHistory().subscribe(
            (response) => {
                this.carHistory = response;
                this.carHistoryLoading = false;
                if (this.toCarHistory) {
                    this.addHistoryEntry(this.toCarHistory);
                    this.toCarHistory = undefined;
                }
            }
        );
    }

    public setFirstEntryInHistory(car: ICar) {
        this.updateCarHistory();
    }

    public addHistoryEntry(car: ICar) {
        this.carHistory.unshift(car);

        if (this.carHistory.length === 21) {
            this.carHistory.splice(this.carHistory.length - 1, 1);
        }
    }

    public getAdcTypeId() {
        if (this.selectedCar) {
            return this.selectedCar.adcTypeId;
        } else {
            return 0;
        }
    }

    public getKTypeId() {
        if (this.selectedCar) {
            return this.selectedCar.kTypeId;
        } else {
            return 0;
        }
    }

    public getHsn() {
        if (this.selectedCar) {
            return this.selectedCar.hsn;
        } else {
            return '';
        }
    }

    public getTsn() {
        if (this.selectedCar) {
            return this.selectedCar.tsn;
        } else {
            return '';
        }
    }

    public getCarProducerName() {
        if (this.selectedCar) {
            return this.selectedCar.carProducerName;
        } else {
            return '';
        }
    }

    public getHsnTsn() {
        if (this.selectedCar && this.selectedCar.kbaNumbers && this.selectedCar.kbaNumbers.length > 0) {
            return this.selectedCar.kbaNumbers[0].substring(0, this.selectedCar.kbaNumbers[0].indexOf('_')).toUpperCase() + ' '
                + this.selectedCar.kbaNumbers[0].substring(this.selectedCar.kbaNumbers[0].indexOf('_') + 1).toUpperCase();
        } else {
            return undefined;
        }
    }


    public getAutoVistaVinResponse() {
        if (this.selectedCar && this.selectedCar.autovistaVinResponse) {
            return this.selectedCar.autovistaVinResponse;
        } else {
            return undefined;
        }
    }

    public getVin() {
        if (this.selectedCar && this.selectedCar.vin) {
            return this.selectedCar.vin;
        } else {
            return '';
        }
    }

    public getNatcode() {
        if (this.selectedCar && this.natcode) {
            return this.natcode;
        } else {
            return '';
        }
    }

    public getSwissTypeNo() {
        if (this.selectedCar && this.typeNumber) {
            return this.typeNumber;
        } else {
            return '';
        }
    }

}
