import {Component, OnDestroy, OnInit} from '@angular/core';
import {AdminService} from '../../club/services/admin.service';
import {CompanyAdminService} from "../services/company-admin.service";
import {ActivatedRoute} from '@angular/router';
import {ICalculation} from "../../../shared/interfaces/DTO/firm/calculation";
import {HelperService} from "../../../shared/services/helper.service";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {forkJoin} from "rxjs";
import {finalize} from "rxjs/operators";
import {IGenArtTreeNode} from "../../../shared/interfaces/DTO/firm/genArtTree";

@Component({
    selector: 'app-firmcalculation',
    templateUrl: './firm.calculation.html',
    styleUrls: ['./firm.calculation.scss']
})

export class FirmCalculationComponent implements OnInit, OnDestroy {
    private firmId: string;
    public editing: boolean = false;
    public selectedCalculation: ICalculation;
    public calculationMoved: boolean = false;
    public loading: boolean = true;
    public disableDragDrop: boolean = false;
    public searchTermGenArt: string = '';
    public searchTermSupplier: string = '';


    constructor(
        public adminService: AdminService,
        public companyAdminService: CompanyAdminService,
        private helperService: HelperService,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
        this.firmId = this.route.snapshot.params['id'];
        if (this.firmId === undefined) {
            this.firmId = this.companyAdminService.firmData.firmId.toString();
        }
        this.adminService.getFirmCalculations(this.firmId);
        forkJoin({
            genArtTreeU: this.adminService.getGenArtTreeRest('U'),
            genArtTreeP: this.adminService.getGenArtTreeRest('P'),
            afterMarketDataSuppliers: this.adminService.getDataSuppliersRest(),
            oeDataSuppliers: this.adminService.getOeDataSuppliersRest()
        }).pipe(
            finalize(() => this.loading = false)
        ).subscribe(results => {
            const {genArtTreeU, genArtTreeP, afterMarketDataSuppliers, oeDataSuppliers} = results;
            this.adminService.aftermarketDataSuppliers = afterMarketDataSuppliers;
            this.adminService.oeDataSuppliers = oeDataSuppliers;
            this.adminService.firmCalculationGenArtP = genArtTreeP;
            this.adminService.firmCalculationGenArtU = genArtTreeU;
        });
    }

    ngOnDestroy() {
        this.adminService.highlightedCheckedCalculationId$.next(undefined);
    }


    public revertChanges(): void {
        this.loading = true;
        this.adminService.firmCalculations = this.adminService.orgFirmCalculations.filter((calc: ICalculation) => calc.type === this.adminService.selectedCalculationType);
        this.calculationMoved = false;
        setTimeout(() => {
            this.loading = false;
        }, 100);
    }

    public filterCalculationsByCalcType(isAfterMarket: boolean): void {
        if (this.calculationMoved) {
            this.revertChanges();
        }
        this.loading = true;
        isAfterMarket ? this.adminService.selectedCalculationType = 'AFTER_MARKET' : this.adminService.selectedCalculationType = 'OE_ARTICLE';
        this.adminService.highlightedCheckedCalculationId$.next(undefined);
        this.adminService.firmCalculations = this.adminService.orgFirmCalculations.filter((calc: ICalculation) => calc.type === this.adminService.selectedCalculationType);
        setTimeout(() => {
            this.loading = false;
        }, 100);
    }

    public drop(e: CdkDragDrop<ICalculation[]>): void {
        this.calculationMoved = true;
        const calculationsCopy = [...this.adminService.firmCalculations];
        const filteredCalculations = calculationsCopy.filter(calc => calc.type === this.adminService.selectedCalculationType);
        moveItemInArray(filteredCalculations, e.previousIndex, e.currentIndex);
        filteredCalculations.forEach((calc, index): void => {
            calc.sortId = index + 1;
        });
        this.adminService.firmCalculations = calculationsCopy.map(calc => {
            const updatedCalc = filteredCalculations.find(fCalc => fCalc.id === calc.id);
            return updatedCalc ? {...calc, sortId: updatedCalc.sortId} : calc;
        });
        this.adminService.firmCalculations.sort((a: ICalculation, b: ICalculation) => a.sortId - b.sortId);
    }

    public filterCalculationsBySelectedType(calculations: ICalculation[]): ICalculation[] {
        return calculations.filter(calc => calc.type === this.adminService.selectedCalculationType);
    }

    public filterCalculationsBySearch(): void {
        this.disableDragDrop = !!(this.searchTermGenArt || this.searchTermSupplier);
        let reducedCalculations: ICalculation[] = this.adminService.orgFirmCalculations
            .filter((calc: ICalculation) => calc.type === this.adminService.selectedCalculationType);

        if (this.searchTermSupplier) {
            const supplierIds = this.getSupplierIdsByName(this.searchTermSupplier);
            reducedCalculations = reducedCalculations.filter((calc: ICalculation) =>
                calc.brandIds.some((id: number) => supplierIds.includes(id))
            );
        }

        if (this.searchTermGenArt) {
            const genArtIds = this.getGenArtIdsByName(this.searchTermGenArt);
            reducedCalculations = reducedCalculations.filter((calc: ICalculation) =>
                calc.genArtIds.some((id: number) => genArtIds.includes(id))
            );
        }
        this.adminService.firmCalculations = reducedCalculations;
    }

    public resetFilter(): void {
        this.adminService.firmCalculations = this.adminService.orgFirmCalculations.filter((calc: ICalculation) => calc.type === this.adminService.selectedCalculationType);
        this.disableDragDrop = false;
    }

    public editCalculation(calculation: ICalculation | undefined): void {
        this.selectedCalculation = calculation;
        this.editing = true;
    }

    public updateCalculations(): void {
        this.calculationMoved = false;
        this.loading = true;
        const updatedCalculations = this.adminService.orgFirmCalculations.map(originalCalc => {
            const updatedCalc = this.adminService.firmCalculations.find(calc => calc.id === originalCalc.id);
            return updatedCalc ? {...originalCalc, ...updatedCalc} : originalCalc;
        });
        this.adminService.updateCalculationList(updatedCalculations, this.firmId).pipe(finalize(() => this.loading = false)).subscribe(
            (calculations): void => {
                this.adminService.orgFirmCalculations = calculations.sort((a, b): number => a.sortId - b.sortId);
            },
            () => {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_UPDATE', 'error');
            }
        );
    }

    public deleteCalculation(id: number) {
        this.adminService.deleteCalculationRest(id, this.firmId).subscribe(
            (calculations: ICalculation[]) => {
                this.adminService.orgFirmCalculations = calculations.sort((a, b) => a.sortId - b.sortId);
                this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_DELETE', 'success');
            },
            () => {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_DELETE', 'error');
            }
        );
    }

    public getSupplierNameById(brandId: number, type: string): string {
        if (type === 'AFTER_MARKET') {
            return this.adminService.aftermarketDataSuppliers?.find(s => s.id === brandId)?.name || '';
        }
        if (type === 'OE_ARTICLE') {
            return this.adminService.oeDataSuppliers?.find(s => s.id === brandId)?.name || '';
        }
        return '';
    }

    public getSupplierIdsByName(name: string): number[] {
        const lowerCaseName = name.toLowerCase();
        let matchingIds: number[] = [];

        switch (this.adminService.selectedCalculationType) {
            case 'AFTER_MARKET':
                matchingIds = this.adminService.aftermarketDataSuppliers
                    ?.filter(s => s.name.toLowerCase().includes(lowerCaseName))
                    .map(s => s.id) || [];
                break;
            case 'OE_ARTICLE':
                matchingIds = this.adminService.oeDataSuppliers
                    ?.filter(s => s.name.toLowerCase().includes(lowerCaseName))
                    .map(s => s.id) || [];
                break;
        }

        return matchingIds;
    }


    public getGenArtIdsByName(name: string): number[] {
        const findIdsInTree = (node: IGenArtTreeNode, matchingIds: number[]): void => {
            if (node.name.toLowerCase().includes(name.toLowerCase())) {
                matchingIds.push(...node.genericArticleIds);
            }
            if (node.children && node.children.length > 0) {
                for (const child of node.children) {
                    findIdsInTree(child, matchingIds);
                }
            }
        };

        const matchingIds: number[] = [];
        const uNodes = this.adminService.firmCalculationGenArtU || [];
        for (const node of uNodes) {
            findIdsInTree(node, matchingIds);
        }
        const pNodes = this.adminService.firmCalculationGenArtP || [];
        for (const node of pNodes) {
            findIdsInTree(node, matchingIds);
        }
        return matchingIds;
    }

    public getGenArtNameById(genArtId: number): string {
        const findNameInTree = (node: IGenArtTreeNode): string => {
            if (node.genericArticleIds[0] === genArtId) {
                return node.name;
            }

            if (node.children && node.children.length > 0) {
                for (const child of node.children) {
                    const result = findNameInTree(child);
                    if (result) {
                        return result;
                    }
                }
            }
            return '';
        };
        const uNodes = this.adminService.firmCalculationGenArtU || [];
        for (const node of uNodes) {
            const resultU = findNameInTree(node);
            if (resultU) {
                return resultU;
            }
        }
        const pNodes = this.adminService.firmCalculationGenArtP || [];
        for (const node of pNodes) {
            const resultP = findNameInTree(node);
            if (resultP) {
                return resultP;
            }
        }
        return '';
    }
}
