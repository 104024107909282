import {Component, OnInit, ElementRef} from '@angular/core';
import {UserService} from '../../../shared/services/user/user.service';
import {Router} from '@angular/router';
import {AuthService} from '../../../auth/services/auth.service';
import {ThemeService} from '../../../shared/services/theme.service';
import {ConfigService} from '../../../shared/services/config.service';
import {ExternalNotificationService} from '../../../shared/services/external-notification.service';
import {HelperService} from '../../../shared/services/helper.service';
import {Location} from "@angular/common";

@Component({
    selector: 'app-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss'],
    host: {
        '(document:click)': 'onOutsideClick($event)',
    }
})
export class UserMenuComponent implements OnInit {
    public showUserMenu: boolean = false;
    public showLangMenu: boolean = false;
    public showThemeMenu: boolean = false;

    constructor(
        private router: Router,
        public userService: UserService,
        private authService: AuthService,
        public themeService: ThemeService,
        private _eref: ElementRef,
        public configService: ConfigService,
        public helperService: HelperService,
        public externalNotificationService: ExternalNotificationService
    ) {
    }

    ngOnInit() {
    }

    public openBETAAdminGUI(): void {
        const { protocol, hostname } = window.location;
        window.location.href = `${protocol}//${hostname}/management`;
    }

    public navigate(path: string) {
        this.showUserMenu = false;
        this.showLangMenu = false;
        this.router.navigate([path]);
    }

    private logout() {
        this.showUserMenu = false;

        // TODO: clear search history

        if (this.themeService.auth.logoutUrl && this.themeService.auth.logoutUrl !== '') {
            document.location.href = this.themeService.auth.logoutUrl;
        } else {
            this.authService.logout()
        }
    }

    private onOutsideClick(event: any) {
        if (!this._eref.nativeElement.contains(event.target) && event.target.className !== 'fa fa-eye-slash') {
            this.showUserMenu = false;
            this.showLangMenu = false;
            this.externalNotificationService.showNotifications = false;
        }
    }

}
