<ng-container *ngFor="let node of tree">
    <div class="d-flex align-items-center mb-1">
        <div class="d-flex justify-content-center align-items-center mr-2" style="width: 1.5rem; height: 1.5rem;">
            <i  *ngIf="node.children && node.children.length > 0" (click)="toggle(node.id)"
                [ngClass]="{'fa-caret-down': isExpanded[node.id], 'fa-caret-right': !isExpanded[node.id]}" class="fa"></i>
        </div>
        <div class="row mb-1">
        <label class="ml-3 form-check-label" for="{{randomNumberForUniqueIdInForm}}-{{node.name}}">{{ node.name }}</label>
        <input [checked]="node.active"
               [indeterminate]="node.indeterminate"
               (change)="onCheckboxChange(node, $event)"
               class="form-check-input ml-2" type="checkbox"
               name="{{node.name}}" id="{{randomNumberForUniqueIdInForm}}-{{node.name}}"/>
        </div>
    </div>
    <div *ngIf="node.children && node.children.length > 0" [@expandCollapse]="isExpanded[node.id] ? 'open' : 'closed'"
         class="overflow-hidden pl-4">
        <app-tree *ngIf="isExpanded[node.id]" [tree]="node.children" (nodeChanged)="nodeChanged.emit($event)"></app-tree>
    </div>
</ng-container>
