<div class="global-order-data">
  <div class="heading blue" >
    <span class="clickable" (click)="globalAdminService.reset()" routerLink="/global-admin">
        <i class="fa fa-th" aria-hidden="true"></i>
        {{'ADMIN_PANEL.OVERVIEW' | translate}}
    </span>
    <span class="clickable" (click)="globalAdminService.reset()" routerLink="/global-admin/communicationslist">
      <i class="fa fa-caret-right"></i>
      Communication Log
    </span>
    <span class="clickable">
      <i class="fa fa-caret-right"></i>
      <span>{{globalAdminService?.communicationLog?.value?.created | date : 'dd.MM.yyy hh:mm:ss'}}</span>
    </span>
  </div>
  <div class="panel-content center-content">
    <div class="table-container big-header">
      <div class="list">
        <div class="row">
          <div class="col-2">
            <strong>Datum:</strong>
          </div>
          <div class="col-4">
            <span>{{globalAdminService?.communicationLog?.value?.created | date : 'dd.MM.yyy'}}</span>
          </div>
          <div class="col-2">
            <strong>Uhrzeit:</strong>
          </div>
          <div class="col-4">
            <span>{{globalAdminService?.communicationLog?.value?.created | date : 'hh:mm:ss'}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-2">
            <strong>Club Name:</strong>
          </div>
          <div class="col-4">
            <span>{{globalAdminService?.communicationLog?.value?.clubName}}</span>
          </div>
          <div class="col-2">
            <strong>Firm Name:</strong>
          </div>
          <div class="col-4">
            <span>{{globalAdminService?.communicationLog?.value?.firmName}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-2">
            <strong>Login Name:</strong>
          </div>
          <div class="col-10">
            <span>{{globalAdminService?.communicationLog?.value?.loginName}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-2">
            <strong>Method:</strong>
          </div>
          <div class="col-10">
            <span>{{ globalAdminService?.communicationLog?.value?.method }}</span>
          </div>
        </div>
      </div>

      <br><br>
      <h4>Headers</h4>
      <ul class="item-list vinlog" *ngIf="globalAdminService?.communicationLog?.value?.headers">
        <li *ngIf="globalAdminService?.communicationLog?.value?.headers">
          {{ ((globalAdminService?.communicationLog?.value?.headers).length>200)? (globalAdminService?.communicationLog?.value?.headers | slice:0:200)+ ' (...)' :(globalAdminService?.communicationLog?.value?.headers) }}
          <i class="fa fa-copy" style="float: right" (click)="copy(globalAdminService?.communicationLog?.value?.headers.toString())"></i>
        </li>
      </ul>

      <br><br>
      <h4>Request</h4>
      <ul class="item-list vinlog" *ngIf="globalAdminService?.communicationLog?.value?.response">
        <li *ngIf="globalAdminService?.communicationLog?.value?.request">
          {{ ((globalAdminService?.communicationLog?.value?.request).length>200)? (globalAdminService?.communicationLog?.value?.request | slice:0:200)+ ' (...)' :(globalAdminService?.communicationLog?.value?.request) }}
          <i class="fa fa-copy" style="float: right" (click)="copy(globalAdminService?.communicationLog?.value?.request.toString())"></i>
        </li>
      </ul>

      <br>
      <h4>Response</h4>
      <ul class="item-list vinlog" *ngIf="globalAdminService?.communicationLog?.value?.response">
        <li>
         {{((globalAdminService?.communicationLog?.value?.response | json) | slice:0:200)  + ' (...)'}}
          <i class="fa fa-copy" style="float: right" (click)="copy(globalAdminService?.communicationLog?.value?.response.toString())"></i>
        </li>
      </ul>

      <button class="base-btn back-btn" routerLink="/global-admin/communicationslist">Zurück</button>

    </div>
  </div>
</div>
