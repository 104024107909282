import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {leftTreeAnimation} from '../../animations/general.animation';
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-left-tree',
    templateUrl: './left-tree.component.html',
    styleUrls: ['./left-tree.component.scss'],
    animations: [leftTreeAnimation]
})
export class LeftTreeComponent implements OnInit{
    @Input() public nodeTree: any[];
    @Input() private nodeActive: number;
    @Input() private mainOnlyOpen: boolean;
    @Input() private treeFilter: any;
    @Input() private firstNode: boolean;
    @Output() private onActivate: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private translate: TranslateService) {
    }

    ngOnInit() {
        this.nodeTree = this.addAllGroupsNode(this.nodeTree);
    }

    public addAllGroupsNode(data: any[]): any[] {
        if (!this.firstNode && data && data.length > 1) {
            const alreadyHasAllNode = data.some(d =>
                d.id && typeof d.id === 'string' && d.id.startsWith('all_groups_')
            );
            if (!alreadyHasAllNode) {
                const allIds = data.reduce((res, node) => {
                    if (node.genericArticleIds) {
                        node.genericArticleIds.forEach(genArtID => res.push(genArtID));
                    }
                    return res;
                }, []);
                data.unshift({
                    name: this.translate.instant('ARTICLE_SELECTION.ALL'),
                    // Eindeutige ID für den 'Alle'-Knoten
                    id: 'all_groups_' + Math.random(),
                    genericArticleIds: allIds,
                    adcTypeId: data[0]?.adcTypeId,
                    kTypeId: data[0]?.kTypeId,
                    open: false
                });
            }
        }
        return data;
    }


    private toggle(open, children) {
        if (children && open) {
            this.closeSubChild(children);
        }
        return !open;
    }

    public treeFilterByChild(node: any, treeFilter: any) {
        if (node && node.value && node.value.children) {
            if (node.value.isFiltered) {
                return true;
            }
            const childs = [];
            for (const child of node.value.children) {
                if (treeFilter && treeFilter.articleCategoryIds &&
                    treeFilter.articleCategoryIds.indexOf(child.id) > -1) {
                    node.value.open = true;
                    node.value.isFiltered = true;
                    childs.push(child);
                }
            }

            if (childs.length > 0) {
                node.value.children = childs;
                return true;
            }
        }
        return false;
    }

    private onClickActivate(value: any, open: boolean) {
        let data = {
            data: value
        };
        if (!open && (!value.children || value.children.length === 0)) {
            this.onActivate.emit(value);
        } else {
            value.open = this.toggle(value.open, value.children);
        }
    }

    private closeSubChild(children) {
        for (let child of children) {
            if (child.open) {
                child.open = false;
                if (child.children) {
                    this.closeSubChild(child.children);
                }
            }
        }
    }
}
