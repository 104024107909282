<fieldset class="material-form-input"
          [ngClass]="{'disabled': disabled, 'numberType': type === 'number', 'show-validation': showValidation, 'show-error': showError}"
          [formGroup]="form">
    <input (ngModelChange)="onModelChange()" step="{{step}}" maxlength="{{maxLength}}"
           pattern="{{pattern}}" min="{{min}}" max="{{max}}"
           minlength="{{minLength}}" placeholder="{{placeholder}}" #inputField type="{{type}}"
           [autocomplete]="canAutocomplete()" [formControlName]="'_value'" required (focus)="selected.emit(null)"
           autofocus="{{autofocus}}">
    <hr *ngIf="(form?.valid && !showError) || !showValidation">
    <div *ngIf="((form?.invalid) || (showError)) && showValidation" class="validationErrorMessage">
        <div>{{validationErrorMessage}}</div>
    </div>
    <label>{{label}}</label>
</fieldset>
<div class="text-secondary mt-1"
     *ngIf="vinSearchCounter">{{'CAR_SELECTION.VIN_COUNTER' | translate:{'counter': vinSearchCounter} }}</div>
<div *ngIf="vinInfoText" class="mt-1 text-secondary pre-line">{{vinInfoText}}</div>
