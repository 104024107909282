import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ICalculation} from "../../../shared/interfaces/DTO/firm/calculation";
import {AdminService} from "../../club/services/admin.service";
import {IDataSupplier} from "../../../shared/interfaces/DTO/firm/DataSuppliers";
import {ActivatedRoute} from "@angular/router";
import {CompanyAdminService} from "../services/company-admin.service";
import {HelperService} from "../../../shared/services/helper.service";
import {IGenArtTreeNode, IMainTree, INodeChanged} from "../../../shared/interfaces/DTO/genArtDataTree";
import {BehaviorSubject, combineLatest} from "rxjs";

@Component({
    selector: 'app-firm-edit-calculation',
    templateUrl: './firm-edit-calculation.html',
    styleUrls: ['./firm-edit-calculation.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FirmEditCalculationComponent implements OnInit {
    @Input() calculation: ICalculation | undefined;
    @Output() closeEditing: EventEmitter<boolean> = new EventEmitter<boolean>();

    private treeTypeSubject = new BehaviorSubject<string>('P');
    private genArtIdsSubject = new BehaviorSubject<number[]>([]);
    public articleTreeCar$ = new BehaviorSubject<IMainTree>(<IMainTree>{});
    public articleTreeUniversal$ = new BehaviorSubject<IMainTree>(<IMainTree>{});
    public suppsLoadingToggleAll$ = new BehaviorSubject<boolean>(false);
    public genartLoadingToggleAll$ = new BehaviorSubject<boolean>(false);

    private firmId: string;
    public readonly VALUE_TYPES = ['PERCENTAGE_MARKUP', 'FIX_MARKUP']
    public supplierSearchTerm: string = '';
    public genArtIds$ = this.genArtIdsSubject.asObservable();
    public treeType$ = this.treeTypeSubject.asObservable();

    constructor(
        public adminService: AdminService,
        public helperService: HelperService,
        private route: ActivatedRoute,
        private companyAdminService: CompanyAdminService
    ) {
    }

    ngOnInit(): void {
        this.firmId = this.route.snapshot.params['id'] || this.companyAdminService.firmData.firmId.toString();
        this.initializeCalculation();
        this.initializeTrees();
        this.initializeObservables();
    }

    private initializeCalculation(): void {
        if (!this.calculation) {
            this.calculation = {
                allGenArtsCarRelated: false,
                allGenArtsUniversalRelated: false,
                value: 0,
                type: 'AFTER_MARKET',
                valueType: 'PERCENTAGE_MARKUP',
                genArtIds: [],
                brandIds: [],
                sortId: -1,
            };
        }
    }

    private initializeTrees(): void {
        if (this.calculation.type === 'OE_ARTICLE') {
            this.sortDataSuppliers(this.adminService.oeDataSuppliers);
        } else if (this.calculation.type === 'AFTER_MARKET') {
            this.sortDataSuppliers(this.adminService.aftermarketDataSuppliers);
        }

        this.articleTreeUniversal$.next({
            tree: this.sortTree(
                this.adminService.firmCalculationGenArtU?.[0]?.children?.filter(Boolean) || []),
            hasActiveElements: this.hasActiveElements(this.adminService.firmCalculationGenArtU?.[0]?.children || [])
        });
        this.articleTreeCar$.next({
            tree: this.sortTree(this.adminService.firmCalculationGenArtP?.filter(Boolean) || []),
            hasActiveElements: this.hasActiveElements(this.adminService.firmCalculationGenArtP || [])
        });
        this.updateTreeStates(this.calculation.genArtIds);
    }

    private sortTree(tree: IGenArtTreeNode[]): IGenArtTreeNode[] {
        return tree?.sort((a: IGenArtTreeNode, b: IGenArtTreeNode): number => a.name.localeCompare(b.name)) || [];
    }

    private initializeObservables(): void {
        this.genArtIdsSubject.next(this.calculation?.genArtIds || []);

        combineLatest([this.genArtIds$]).subscribe(([genArtIds]: [number[]]) => {
            this.updateTreeStates(genArtIds);
        });
    }

    public get filteredSuppliers(): IDataSupplier[] {
        let suppliers: IDataSupplier[] = [];
        if (this.calculation?.type === 'AFTER_MARKET') {
            suppliers = this.adminService.aftermarketDataSuppliers;
        } else if (this.calculation?.type === 'OE_ARTICLE') {
            suppliers = this.adminService.oeDataSuppliers;
        }
        if (!this.supplierSearchTerm || this.supplierSearchTerm.trim() === '') {
            return suppliers;
        }
        return suppliers.filter((s: IDataSupplier) =>
            s.name.toLowerCase().includes(this.supplierSearchTerm.toLowerCase())
        )
            ;
    }

    private setNextSortId(): void {
        const calculations: ICalculation[] = this.adminService.orgFirmCalculations || [];
        const maxSortId: number = calculations.length ? Math.max(...calculations.map((calc: ICalculation) => calc.sortId
            )) :
            0;
        this.calculation.sortId = maxSortId + 1;
    }

    public closeEditingCalculation(): void {
        this.closeEditing.emit(true);
    }

    public updateCalculation(): void {
        let arrayWithNewCalculation = [...this.adminService.orgFirmCalculations];
        if (this.calculation.sortId === -1) {
            this.setNextSortId();
            if (!this.adminService.orgFirmCalculations) {
                this.adminService.orgFirmCalculations = [];
            }
            arrayWithNewCalculation = [...this.adminService.orgFirmCalculations, this.calculation];
        }

        // making genArtId's unique and remove duplicates
        arrayWithNewCalculation.forEach((calc: ICalculation) => {
            const uniqueGenArtIds = new Set<number>(calc.genArtIds);
            calc.genArtIds = Array.from(uniqueGenArtIds);
        });

        this.adminService.updateCalculationList(arrayWithNewCalculation, this.firmId).subscribe(
            (calculations: ICalculation[]): void => {
                this.adminService.orgFirmCalculations = calculations.sort((a: ICalculation, b: ICalculation) => a.sortId - b.sortId);
                this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_UPDATE', 'success');
            },
            () => {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_UPDATE', 'error');
            }
        );
    }


    public hasGenArtsInTree(treeType: 'U' | 'P'): boolean {
        const genArtIds = this.calculation?.genArtIds || [];
        const tree = treeType === 'U' ? this.articleTreeUniversal$.getValue().tree : this.articleTreeCar$.getValue().tree;

        return this.checkGenArtsInTree(tree, genArtIds);
    }


    private checkGenArtsInTree(nodes: IGenArtTreeNode[] | undefined, genArtIds: number[]): boolean {
        if (!nodes) return false;
        for (const node of nodes) {
            if (node.genericArticleIds.some(id => genArtIds.includes(id))) {
                return true;
            }
            if (this.checkGenArtsInTree(node.children, genArtIds)) {
                return true;
            }
        }
        return false;
    }

    public toggleValueType(type: Event): void {
        const target = type.target as HTMLSelectElement;
        this.calculation.valueType = target.value as 'PERCENTAGE_MARKUP' | 'FIX_MARKUP';
    }

    public toggleType(isAftermarket: boolean): void {
        this.calculation.type = isAftermarket ? 'AFTER_MARKET' : 'OE_ARTICLE';
        isAftermarket ? this.sortDataSuppliers(this.adminService.aftermarketDataSuppliers) : this.sortDataSuppliers(this.adminService.oeDataSuppliers);
        this.calculation.brandIds = [];
        this.genArtIdsSubject.next([]);
        this.updateTreeStates([]);
    }

    public toggleBrandId(brandId: number, event: Event): void {
        const checked: boolean = (event.target as HTMLInputElement).checked;
        if (checked) {
            this.calculation.brandIds.push(brandId);
        } else {
            this.calculation.brandIds = this.calculation.brandIds.filter(id => id !== brandId);
        }
    }

    public sortDataSuppliers(suppliers: IDataSupplier[]): IDataSupplier[] {
        return suppliers.sort((a, b) => {
            const aExists = this.calculation.brandIds.includes(a.id);
            const bExists = this.calculation.brandIds.includes(b.id);
            if (aExists === bExists) {
                return a.name.localeCompare(b.name);
            }
            return aExists ? -1 : 1;
        });
    }

    public changeTreeType(type: string): void {
        this.treeTypeSubject.next(type);
    }

    public toggleChangeAllGenArts(event: Event): void {
        this.genartLoadingToggleAll$.next(true);
        const isChecked = (event.target as HTMLInputElement).checked;
        const treeType = this.treeTypeSubject.getValue();
        const treeToUpdate = treeType === 'U' ? this.articleTreeUniversal$.getValue().tree : this.articleTreeCar$.getValue().tree;

        if (treeType === 'U') {
            this.calculation.allGenArtsUniversalRelated = isChecked;
        } else {
            this.calculation.allGenArtsCarRelated = isChecked;
        }

        this.setAllChildrenInTree(treeToUpdate, isChecked);
        this.updateGenArtIdsBasedOnType(isChecked, treeToUpdate);

        if (treeType === 'U') {
            this.articleTreeUniversal$.next(this.articleTreeUniversal$.value);
        } else {
            this.articleTreeCar$.next(this.articleTreeCar$.value);
        }

        setTimeout(() => {
            this.genartLoadingToggleAll$.next(false);
        }, 100);
    }


    private collectAllGenericArticleIds(nodes: IGenArtTreeNode[] | undefined, ids: number[]): void {
        if (!nodes) return;
        nodes.forEach(node => {
            ids.push(...node.genericArticleIds);
            this.collectAllGenericArticleIds(node.children, ids);
        });
    }

    public toggleChangeAllSuppliers(event: Event): void {
        this.suppsLoadingToggleAll$.next(true);
        const isChecked = (event.target as HTMLInputElement).checked;
        if (isChecked) {
            let suppliers: IDataSupplier[] = [];
            if (this.calculation?.type === 'AFTER_MARKET') {
                suppliers = this.adminService.aftermarketDataSuppliers;
            } else if (this.calculation?.type === 'OE_ARTICLE') {
                suppliers = this.adminService.oeDataSuppliers;
            }

            this.calculation.brandIds = suppliers.map(supplier => supplier.id);
        } else {
            this.calculation.brandIds = [];
        }
        setTimeout(() => {
            this.suppsLoadingToggleAll$.next(false);
        }, 100);
    }

    public areAllBrandIdsInDataSuppliers(): boolean {
        if (this.calculation?.type === 'AFTER_MARKET') {
            return this.calculation.brandIds.length === this.adminService.aftermarketDataSuppliers.length;
        } else if (this.calculation?.type === 'OE_ARTICLE') {
            return this.calculation.brandIds.length === this.adminService.oeDataSuppliers.length;
        }
        return false;
    }

    private updateTreeStates(calcGenArts: number[]) {
        const calcGenArtsMap = new Map<number, boolean>();
        calcGenArts.forEach(genArt => calcGenArtsMap.set(genArt, true));

        const updatedCarTree = this.updateNodes(this.articleTreeCar$.value.tree, calcGenArtsMap);
        const updatedUniversalTree = this.updateNodes(this.articleTreeUniversal$.value.tree, calcGenArtsMap);

        this.articleTreeCar$.next({
            tree: updatedCarTree,
            hasActiveElements: this.hasActiveElements(updatedCarTree)
        });
        this.articleTreeUniversal$.next({
            tree: updatedUniversalTree,
            hasActiveElements: this.hasActiveElements(updatedUniversalTree)
        });
        this.calculation.allGenArtsCarRelated = (updatedCarTree?.every(item => item.active || item.childActive) || false);
        this.calculation.allGenArtsUniversalRelated = (updatedUniversalTree?.every(item => item.active || item.childActive) || false);
    }

    private updateNodes(nodes: IGenArtTreeNode[] | undefined, calcGenArtsMap: Map<number, boolean>): IGenArtTreeNode[] | undefined {
        if (!nodes) return undefined;

        return nodes.map(node => {
            const newNode = {...node};
            newNode.active = newNode.genericArticleIds.every(id => calcGenArtsMap.has(id));

            if (newNode.children?.length) {
                newNode.children = this.updateNodes(newNode.children, calcGenArtsMap);
                newNode.allChildsActive = newNode.children.every(child => child.active || child.allChildsActive);
                newNode.childActive = newNode.children.some(child => child.active || child.childActive);
            }
            newNode.indeterminate = !newNode.active && newNode.childActive;
            return newNode;
        });
    }

    public genArtsChanged(event: INodeChanged): void {
        let updatedGenArtIds = [...this.genArtIdsSubject.value];
        switch (event.type) {
            case 'add':
                event.ids.forEach(genArtId => {
                    if (!updatedGenArtIds.includes(genArtId)) {
                        updatedGenArtIds.push(genArtId);
                    }
                });
                break;
            case 'remove':
                updatedGenArtIds = updatedGenArtIds.filter(genArt => !event.ids.includes(genArt));
                break;
        }
        this.genArtIdsSubject.next(updatedGenArtIds);
        this.calculation.genArtIds = updatedGenArtIds;
    }

    private updateGenArtIdsBasedOnType(isChecked: boolean, tree: IGenArtTreeNode[]): void {
        let updatedGenArtIds = [...this.calculation.genArtIds];
        if (isChecked) {
            this.collectAllGenericArticleIds(tree, updatedGenArtIds);
        } else {
            const idsToRemove = [];
            this.collectAllGenericArticleIds(tree, idsToRemove);
            updatedGenArtIds = updatedGenArtIds.filter(id => !idsToRemove.includes(id));
        }
        this.calculation.genArtIds = updatedGenArtIds;
        this.genArtIdsSubject.next(updatedGenArtIds);
    }

    private setAllChildrenInTree(nodes: IGenArtTreeNode[] | undefined, active: boolean): void {
        if (!nodes) return;

        const stack: IGenArtTreeNode[] = [...nodes];

        while (stack.length > 0) {
            const node = stack.pop()!;
            const newNode = {...node, active, indeterminate: false};
            if (newNode.children) {
                stack.push(...newNode.children.map(child => ({...child})));
            }
            this.replaceNodeInTree(nodes, node, newNode);
        }
        this.articleTreeCar$.next({...this.articleTreeCar$.value, tree: [...this.articleTreeCar$.value.tree]});
        this.articleTreeUniversal$.next({
            ...this.articleTreeUniversal$.value,
            tree: [...this.articleTreeUniversal$.value.tree]
        });
    }

    private replaceNodeInTree(nodes: IGenArtTreeNode[], oldNode: IGenArtTreeNode, newNode: IGenArtTreeNode): void {
        for (let i = 0; i < nodes.length; i++) {
            if (nodes[i] === oldNode) {
                nodes[i] = newNode;
                return;
            } else if (nodes[i].children) {
                this.replaceNodeInTree(nodes[i].children, oldNode, newNode);
            }
        }
    }

    private hasActiveElements(nodes: IGenArtTreeNode[] | null | undefined): boolean {
        if (!nodes) return false;

        const stack: IGenArtTreeNode[] = [...nodes];
        while (stack.length > 0) {
            const node = stack.pop()!;
            if (node.active) {
                return true;
            }
            if (node.children) {
                stack.push(...node.children);
            }
        }
        return false;
    }
}
