<div *ngIf="service?.selectedArticle" class="app-article-details">
    <div class="article-details-header">
        <div class="row no-margin-right">
            <div class="col-6">
                <div>
                    <span class="headLine description">{{ service.selectedArticle.description }}</span>
                    <span class="data">{{ service.selectedArticle.brandName }}</span>
                    <br/>
                    <span class="price price_label"
                          *ngIf="!userService.sale && !service?.selectedArticle?.pseudoArticle && !(cartService?.cartCount?.calculationData)">{{ 'SPAREPARTS_VIEW.SEARCH_RESULTS.RECOMMENDED_PRICE' | translate }}</span>
                    <span class="price"
                          *ngIf="service.selectedArticle.uvp > 0 && !service?.selectedArticle?.pseudoArticle">&nbsp;{{ service.selectedArticle.uvpFormatted }}</span>
                    <span class="price no_price"
                          *ngIf="!(service.selectedArticle.uvp > 0) && !service?.selectedArticle?.pseudoArticle"> {{ 'SPAREPARTS_VIEW.DETAILS_PANEL.NA' | translate }}</span>
                </div>
                <div class="spacer" *ngIf="service.selectedArticle.productName">
                    <span class="headLine">{{ 'SPAREPARTS_VIEW.DETAILS_PANEL.PRODUCT_NAME' | translate }}</span>
                    <span class="data">{{ service.selectedArticle.productName }}</span>
                </div>
                <div class="spacer">
                    <span class="headLine">{{ 'SPAREPARTS_VIEW.DETAILS_PANEL.ARTICLE_NUMBER' | translate }} </span>
                    <span class="data">{{ service.selectedArticle.ipc }}</span>
                </div>
                <div class="spacer" *ngIf="service.selectedArticle.ean">
                    <span class="headLine">{{ 'SPAREPARTS_VIEW.DETAILS_PANEL.EAN' | translate }} </span>
                    <span class="data">{{ service.selectedArticle.ean }}</span>
                </div>
                <div class="spacer" *ngIf="service.selectedArticle.vpe">
                    <span class="d-flex">
                        <span class="headLine">{{ 'SPAREPARTS_VIEW.DETAILS_PANEL.PACKING_UNIT_SHORT' | translate }} </span>
                        <span class="vpe-info" triggers="mouseenter:mouseleave" [openDelay]="0"
                              [ngbPopover]="'SPAREPARTS_VIEW.DETAILS_PANEL.PACKING_UNIT_HINT' | translate"
                              container="body">
                            <i class="fa fa-info-circle"></i>
                        </span>
                    </span>
                    <span class="data">{{ service.selectedArticle.vpe }}</span>
                    <span *ngIf="service.selectedArticle.quantityPerVPE">
                        <span class="headLine">{{ 'SPAREPARTS_VIEW.DETAILS_PANEL.PACKING_UNIT' | translate }} </span>
                        <span class="data">{{ service.selectedArticle.quantityPerVPE }}</span>
                    </span>
                </div>
                <div class="spacer" *ngIf="service.selectedArticle.replacesArticles">
                    <span class="headLine">{{ 'SPAREPARTS_VIEW.DETAILS_PANEL.REPLACES_ARTICLE' | translate }}</span>
                    <span *ngFor="let replace of service.selectedArticle.replacesArticles; let last = last" class="data clickable"
                          (click)="onDirectArticleSearch(replace)">
                        {{ replace}} <i class="ml-2 fa fa-external-link"></i><span *ngIf="!last">, </span>
                    </span>
                </div>
                <div class="spacer" *ngIf="service.selectedArticle.isReplacedByArticles">
                    <span class="headLine">{{ 'SPAREPARTS_VIEW.DETAILS_PANEL.IS_REPLACED_BY_ARTICLE' | translate }}</span>
                    <span *ngFor="let isReplacedByArticle of service.selectedArticle.isReplacedByArticles; let last = last" class="data clickable"
                          (click)="onDirectArticleSearch(isReplacedByArticle)">
                        {{isReplacedByArticle}} <i class="ml-2 fa fa-external-link"></i><span *ngIf="!last">, </span>
                    </span>
                </div>
                <div class="spacer" *ngIf="service.selectedArticle.exchangePart">
                    <span class="headLine">{{ 'SPAREPARTS_VIEW.EXCHANGE_PARTS' | translate }}</span>
                    <span class="data">{{ 'SPAREPARTS_VIEW.EXCHANGE_VALUE' | translate }} {{ service.selectedArticle.exchangeValue | currency:helperService.getCurrency(service.selectedArticle):symbol:'.2-2' }}</span>
                </div>
                <div class="spacer" *ngIf="service.selectedArticle.hasPartList && showArtikelLists">
                    <button class="base-btn"
                            (click)="articleService.isInPseudoArticleView$.next(true);  articlesService.currentPseudoArticle$.next(service.selectedArticle); service.updateByArticlePartlist(); articleService.setPseudoArticle(service.selectedArticle); service.hidePartsListPseudo = true">
                        <span *ngIf="!service?.selectedArticle?.pseudoArticle">{{ 'SPAREPARTS_VIEW.DETAILS_PANEL.PARTS_LIST' | translate }}</span>
                        <span *ngIf="service?.selectedArticle?.pseudoArticle">{{ 'SPAREPARTS_VIEW.DETAILS_PANEL.PARTS_LIST_PSEUDO' | translate }}</span>
                    </button>
                </div>
                <div class="spacer" *ngIf="service.selectedArticle.hasAccessoriesList && showArtikelLists">
                    <button class="base-btn" (click)="service.getAccessoriesListlist()">
                        <span>{{ 'SPAREPARTS_VIEW.DETAILS_PANEL.ACCESSORIES_LIST' | translate }}</span>
                    </button>
                </div>
            </div>
            <div class="col-6">
                <div class="row no-margin-right">
                    <div class="image">
                        <img [src]="service.selectedArticle.previewImageUrl"
                             class="image clickable"
                             (click)="service.showGallery = true; service.selectedImage = undefined">
                    </div>
                </div>
                <div class="quantity-in-cart" *ngIf="service?.selectedArticle?.quantityInCart > 0">
                    <span class="mr-2">{{ service.selectedArticle.quantityInCart }} x <i
                            class="fa fa-shopping-cart"></i></span>
                </div>
            </div>
        </div>
        <div class="row no-margin-right" *ngIf="service.selectedArticle.pseudoArticle">
            <div class="col">
                <div class="disclaimer row">
                    <div class="col-2">
                        <i class="fa fa-info-circle"></i>
                    </div>
                    <div class="col-10">
                        {{ 'SPAREPARTS_VIEW.DETAILS_PANEL.PSEUDO_DISCLAIMER' | translate }}
                        <strong>'{{ 'SPAREPARTS_VIEW.DETAILS_PANEL.PARTS_LIST_PSEUDO' | translate }}
                            '</strong>{{ 'SPAREPARTS_VIEW.DETAILS_PANEL.PSEUDO_DISCLAIMER2' | translate }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="service.selectedArticle.requestQuantity">
            <div class="col">
                <div class="data requestQuantity">
                            <span *ngIf="service.selectedArticle.requestQuantity.quantity">
                                {{ service.selectedArticle.requestQuantity.amount }} {{ service.selectedArticle.requestQuantity.quantity }}
                            </span>
                    <span *ngIf="!(service.selectedArticle.requestQuantity.quantity)">
                                {{ service.selectedArticle.requestQuantity.amount }} {{ 'CART.PIECE' | translate }}
                            </span>
                    {{ 'ARTICLE_SELECTION.NEEDED_SMALL' | translate }}
                </div>
            </div>
        </div>
    </div>

    <ngb-tabset class="tab-container" [class.three-tabs]="service?.selectedArticle?.promotions?.length > 0">
        <ngb-tab title="{{'ARTICLE_SELECTION.DETAILS' | translate}}">
            <ng-template ngbTabContent>
                <div class="alert alert-danger text-center"
                     *ngIf="carService.getAdcTypeId() < 0 && carService.selectedCar && carService.selectedCar.kTypeId > 0">
                    <span>{{ 'SPAREPARTS_VIEW.DETAILS_PANEL.NO_TECDOC_DATA' | translate }}</span>
                </div>

                <app-availability *ngIf="!service?.selectedArticle?.pseudoArticle" [article]="service.selectedArticle"
                                  [service]="service"></app-availability>
                <ng-container *ngFor="let qualComData of service.selectedArticle.workPositions | cat">
                    <ng-container
                            *ngIf="qualComData.value && qualComData.value.groups && qualComData.value.groups.length > 0">
                        <app-collapsible-panel
                                panelTitle="{{ 'SPAREPARTS_VIEW.BELONGING_WORK' | translate }}"
                                additionalTitle="{{qualComData.value.displayName}}"
                                [folded]="false"
                                [noPadding]="true"
                                *ngIf="carService.selectedCar && service.selectedArticle.workPositions && userService.allow('BELONGING_WORK') && showBelongingWork"
                                class="striped-panel">
                            <table class="striped-table"
                                   *ngIf="qualComData.value.groups">

                                <tr *ngFor="let workPosition of qualComData.value.groups">
                                    <td>{{ workPosition.mountPositionText + ' ' + workPosition.repairText }}
                                        <div *ngIf="workPosition.itemQualifierText != 'Standard'"><b>{{
                                                workPosition.itemQualifierText
                                            }}</b></div>
                                    </td>
                                    <td>
                                        <span>{{ workPosition.quantity }} {{ 'GENERAL.VALUES.' + workPosition.quantityUnit | translate }}</span>&nbsp;
                                        <span *ngIf="workPosition.workPositions?.length > 0">

                                        <ng-template #popContent>

                                            <div class="work-position" *ngFor="let item of workPosition.workPositions">
                                                <span class="work-time">{{ item.quantity }} {{ 'GENERAL.VALUES.' + item.quantityUnit | translate }}</span> -
                                                <span class="work-text">{{ item.mountPositionText + ' ' + item.repairText }}</span>
                                            </div>

                                        </ng-template>

                                        <i triggers="mouseenter:mouseleave"
                                           [ngbPopover]="popContent"
                                           container="body"
                                           class="clickable">
                                        <i class="fa fa-info"></i>
                                      </i>
                                    </span>
                                    </td>
                                    <td>
                                        <div class="custom-checkbox white-background mt-1">
                                            <app-checkbox [isChecked]="workPosition.posIdInCart > 0"
                                                          [isDisabled]="cartService.isOrder()"
                                                          (onChange)="onWorkPosStateChange($event, workPosition)"></app-checkbox>
                                        </div>
                                    </td>
                                </tr>
                            </table>

                        </app-collapsible-panel>
                    </ng-container>
                </ng-container>


                <app-collapsible-panel panelTitle="{{ 'SPAREPARTS_VIEW.GENERAL_DATA' | translate }}"
                                       [noPadding]="true"
                                       [folded]="!service?.selectedArticle?.pseudoArticle"
                                       *ngIf="service.selectedArticle.criterias">
                    <table class="info-table">
                        <tbody>
                        <tr *ngFor="let criteria of service.selectedArticle.criterias">
                            <td *ngIf="criteria.id !== '8'">{{ criteria.name }}</td>
                            <td *ngIf="criteria.id !== '8'">{{ criteria.displayValue }}</td>
                        </tr>
                        </tbody>
                    </table>
                </app-collapsible-panel>

                <app-collapsible-panel panelTitle="{{'SPAREPARTS_VIEW.IMAGES' | translate}}" class="striped-panel"
                                       [noPadding]="true" [folded]="true"
                                       *ngIf="service?.selectedArticle?.images?.length > 0">
                    <table class="striped-table document-table">
                        <tr *ngFor="let image of service?.selectedArticle?.images"
                            ng-init="documentStateHolder[$index] = false">

                            <td *ngIf="service.isImage(image.name)">
                                <div (click)="service.showGallery = true; service.selectedImage = image">
                                    <span>{{ image.name }}</span>
                                </div>
                            </td>

                            <td class="text-right" *ngIf="service.isImage(image.name)">
                                <div (click)="service.showGallery = true; service.selectedImage = image">
                                    <i class="fa fa-image"></i>
                                </div>
                            </td>
                        </tr>
                    </table>
                </app-collapsible-panel>

                <app-collapsible-panel panelTitle="{{ 'SPAREPARTS_VIEW.DOCUMENTS' | translate }}" class="striped-panel"
                                       [noPadding]="true"
                                       [folded]="true"
                                       *ngIf="service.selectedArticle?.documents?.length > 0">
                    <table class="striped-table document-table">
                        <tr *ngFor="let document of service.selectedArticle.documents"
                            ng-init="documentStateHolder[$index] = false">

                            <td *ngIf="!service.isImage(document.name)">
                                <a [href]="document.url" [target]="'_blanc'">
                                    {{ document.name }}
                                </a>
                            </td>

                            <td class="text-right" *ngIf="!service.isImage(document.name)">
                                <a [href]="document.url" [target]="'_blanc'">
                                    <i class="fa fa-download"></i>
                                </a>
                            </td>
                        </tr>
                    </table>
                </app-collapsible-panel>

                <app-collapsible-panel panelTitle="{{ 'SPAREPARTS_VIEW.OE_NUMBERS' | translate }}" class="striped-panel"
                                       [noPadding]="true"
                                       [folded]="true"
                                       *ngIf="(service.selectedArticle.oeNumbers | json) != '{}'">
                    <table class="striped-table" *ngIf="service.selectedArticle.oeNumbers">
                        <tr class="border-bottom-light-grey"
                            *ngFor="let item of service.selectedArticle.oeNumbers | keys">
                            <td class="pt-2 pb-2">{{ item.key }}</td>
                            <td class="pt-2 pb-2">
                                <div *ngFor="let oeNumber of item.value"
                                     (click)="onDirectArticleSearch(oeNumber.oeNumber); this.articlesService.resetToDefaultTree()"
                                     class="hoverable text-right">
                                    {{ oeNumber.oeNumber }}
                                </div>
                            </td>
                        </tr>
                    </table>
                </app-collapsible-panel>

                <app-collapsible-panel panelTitle="{{ 'SPAREPARTS_VIEW.COMPAREABLE_NUMBERS' | translate }}"
                                       class="striped-panel" [noPadding]="true"
                                       [folded]="true"
                                       *ngIf="(service.selectedArticle.comparableNumbers | json) != '{}'">
                    <table class="striped-table" *ngIf="service.selectedArticle.comparableNumbers">
                        <tr class="border-bottom-light-grey"
                            *ngFor="let item of service.selectedArticle.comparableNumbers | keys">
                            <td class="pt-2 pb-2">{{ item.key }}</td>
                            <td class="pt-2 pb-2">
                                <div *ngFor="let compNumber of item.value"
                                     (click)="onDirectArticleSearch(compNumber.compareNumber);this.articlesService.resetToDefaultTree();"
                                     class="hoverable text-right">
                                    {{ compNumber.compareNumber }}
                                </div>
                            </td>
                        </tr>
                    </table>
                </app-collapsible-panel>

                <app-collapsible-panel panelTitle="{{ 'SPAREPARTS_VIEW.USAGE_NUMBERS' | translate }}"
                                       class="striped-panel"
                                       [folded]="true"
                                       [noPadding]="true"
                                       *ngIf="service.selectedArticle?.usageNumbersList?.length">
                    <table class="striped-table" *ngIf="service.selectedArticle.usageNumbersList">
                        <tr *ngFor="let usageNumber of service.selectedArticle.usageNumbersList">
                            <td>
                                <div (click)="onDirectArticleSearch(usageNumber)" class="hoverable">{{ usageNumber }}
                                </div>
                            </td>
                        </tr>
                    </table>
                </app-collapsible-panel>

                <app-collapsible-panel panelTitle="{{ 'ARTICLE_SELECTION.MANUFACTURER_ADDRESS' | translate }}"
                                       class="striped-panel"
                                       [folded]="true"
                                       [noPadding]="true">
                    <div class="row mt-1 pl-4 pb-1 text-start">
                        <div class="w-50">{{ 'ARTICLE_SELECTION.MANUFACTURER_ADDRESS_DATA.MANUFACTURER' | translate }}</div>
                        <div class="w-50">
                            <div class="fw-600">
                                  <span *ngIf="service.selectedArticle?.contactAddress?.term1 || service.selectedArticle?.contactAddress?.term2">
                                    {{ service.selectedArticle?.contactAddress?.term1 }} {{ service.selectedArticle?.contactAddress?.term2 }}
                                  </span>
                                <span *ngIf="!service.selectedArticle?.contactAddress?.term1 && !service.selectedArticle?.contactAddress?.term2">
                                    -
                                  </span>
                            </div>
                        </div>
                        <div class="w-50">{{ 'ARTICLE_SELECTION.MANUFACTURER_ADDRESS_DATA.ADDRESS' | translate }}</div>
                        <div class="w-50">
                            <div class="fw-600">
                                  <span *ngIf="service.selectedArticle?.contactAddress?.street1 || service.selectedArticle?.contactAddress?.street2">
                                    {{ service.selectedArticle?.contactAddress?.street1 }} {{ service.selectedArticle?.contactAddress?.street2 }}
                                  </span>
                                <span *ngIf="!service.selectedArticle?.contactAddress?.street1 && !service.selectedArticle?.contactAddress?.street2">
                                    -
                                  </span>
                            </div>
                        </div>
                        <div class="w-50">{{ 'ARTICLE_SELECTION.MANUFACTURER_ADDRESS_DATA.ZIP_CITY' | translate }}</div>
                        <div class="w-50">
                            <div class="fw-600">
                                  <span *ngIf="service.selectedArticle?.contactAddress?.postCode || service.selectedArticle?.contactAddress?.city1">
                                    {{ service.selectedArticle?.contactAddress?.postCode }} {{ service.selectedArticle?.contactAddress?.city1 }}
                                  </span>
                                <span *ngIf="!service.selectedArticle?.contactAddress?.postCode && !service.selectedArticle?.contactAddress?.city1">
                                    -
                                  </span>
                            </div>
                        </div>
                        <div class="w-50">{{ 'ARTICLE_SELECTION.MANUFACTURER_ADDRESS_DATA.PHONE' | translate }}</div>
                        <div class="w-50"><span
                                class="fw-600">{{ service.selectedArticle?.contactAddress?.phone || '-' }}</span></div>
                        <div class="w-50">{{ 'ARTICLE_SELECTION.MANUFACTURER_ADDRESS_DATA.FAX' | translate }}</div>
                        <div class="w-50"><span
                                class="fw-600">{{ service.selectedArticle?.contactAddress?.fax || '-' }}</span></div>
                        <div class="w-50">{{ 'ARTICLE_SELECTION.MANUFACTURER_ADDRESS_DATA.EMAIL' | translate }}</div>
                        <div class="w-50"><span
                                class="fw-600">{{ service.selectedArticle?.contactAddress?.email || '-' }}</span></div>
                        <div class="w-50">{{ 'ARTICLE_SELECTION.MANUFACTURER_ADDRESS_DATA.WEB' | translate }}</div>
                        <div class="w-50">
                            <span class="fw-600">{{ service.selectedArticle?.contactAddress?.web || '-' }}</span>
                        </div>
                    </div>
                </app-collapsible-panel>
            </ng-template>
        </ngb-tab>
        <ngb-tab
                [disabled]="!(service?.selectedArticle?.hasCompatibleCars) || !(getCarLinkKeys()?.length > 0) || service?.selectedArticle?.pseudoArticle">
            <ng-template ngbTabTitle>
                <i *ngIf="service.loadCars" class="fa fa-spinner fa-fw mr-2"
                   [ngClass]="{'fa-pulse': !helperService.browserIsEdge() && !helperService.browserIsIE()}"></i>
                <span (click)="loadCars()">{{ 'SPAREPARTS_VIEW.CAR_LINKS' | translate }}</span>
            </ng-template>
            <ng-template ngbTabContent>
                <div>
                    <ng-container *ngFor="let manufacturer of service.selectedArticle.carLinks | keys">
                        <app-collapsible-panel panelTitle="{{ manufacturer.key }}"
                                               [noPadding]="true"
                                               [folded]="true">
                            <ng-container *ngFor="let model of manufacturer.value | keys">
                                <b class="modelName">{{ model.key }}</b>
                                <table class="striped-table">
                                    <tr *ngFor="let type of model.value">
                                        <td class="hoverable" (click)="onCarSelection(type.id)">{{ type.displayName }}
                                        </td>
                                    </tr>
                                </table>
                            </ng-container>
                        </app-collapsible-panel>
                    </ng-container>
                </div>
            </ng-template>
        </ngb-tab>
        <ngb-tab *ngIf=" service?.selectedArticle?.promotions?.length >0">
            <ng-template ngbTabTitle>
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                         stroke="currentColor" class="size-6">
                        <path stroke-linecap="round" stroke-linejoin="round"
                              d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z"/>
                    </svg>
                </span>
            </ng-template>
            <ng-template ngbTabContent>
                <ng-container *ngFor="let promo of service.selectedArticle.promotions">
                    <app-collapsible-panel panelTitle="{{ promo.title }} "
                                           [promotionIconManufacturer]="promo.type === 'MANUFACTURER'"
                                           [promotionIconSpecialChoice]="promo.type === 'SPECIAL_CHOICE'"
                                           [noPadding]="true"
                                           [folded]="false">
                        <div class="p-4">
                            <div class="spacer" *ngIf="promo.text">
                            <span class="headLine">
                                {{ 'SPAREPARTS_VIEW.DETAILS_PANEL.PROMO_DETAILS.DESCRIPTION' | translate }}
                            </span>
                                <span class="data">
                                {{ promo.text }}
                            </span>
                            </div>
                            <div class="spacer" *ngIf="promo.link">
                            <span class="headLine">
                               {{ 'SPAREPARTS_VIEW.DETAILS_PANEL.PROMO_DETAILS.URL' | translate }}
                            </span>
                                <span class="data">
                                <a href="{{promo.link}}" target="_blank">
                                     {{ 'SPAREPARTS_VIEW.DETAILS_PANEL.PROMO_DETAILS.TO_PROMO_INFOS' | translate }}
                                    <i class="ml-2 fa fa-external-link"></i>
                                </a>
                            </span>
                            </div>
                            <div class="spacer" *ngIf="promo.validFrom && promo.validTo">
                            <span class="headLine">
                                   {{ 'SPAREPARTS_VIEW.DETAILS_PANEL.PROMO_DETAILS.VALID_DATE' | translate }}
                            </span>
                                <span class="data">
                                {{
                                        'SPAREPARTS_VIEW.DETAILS_PANEL.PROMO_DETAILS.TIME_FROM_TO' | translate: {
                                            'timeFrom': promo.validFrom | date,
                                            'timeTo': promo.validTo | date
                                        }
                                    }}
                            </span>
                            </div>
                        </div>
                    </app-collapsible-panel>
                </ng-container>
            </ng-template>
        </ngb-tab>
    </ngb-tabset>

</div>

<script type="text/ng-template" id="workPositionsTemplate.html">
    <span>

		<div class="work-position" *ngFor="let item of workPosition.workPositions">
			<span class="work-time">{{ item.workTime }} {{ 'GENERAL.UNITS.HOURS' | translate }}</span> -
			<span class="work-text">{{ item.mountPositionText + ' ' + item.repairText }}</span>
		</div>

	</span>
</script>
