<div class="maintenance-service-info">
    <div class="maintenance-data">
        <div class="heading blue" *ngIf="location.path().indexOf('/calculation') > -1">
            <span routerLink="/calculation" class="clickable" (click)="maintenanceService.lastState = ''; cartService.showDocument = undefined; maintenanceService.showPdf = false"><i class="fa fa-th" aria-hidden="true"></i></span>
            <span routerLink="/cart" class="clickable" (click)="maintenanceService.lastState = ''; cartService.showDocument = undefined; maintenanceService.showPdf = false">{{'CART.TITLE' | translate}}</span>
            <span routerLink="/calculation" class="clickable"><i class="fa fa-caret-right"></i>{{'MAINTENANCE.TECRMI_CALCULATION' | translate}}</span>
            <span>
              <button class="print-btn pull-right" (click)="printIframe('tecrmicalculation')" *ngIf="maintenanceService?.tecRMICalculationUrl">
                <i class="fa fa-print"></i>
                <span>{{'PRINT_VIEW.PRINT' | translate}}</span>
              </button>
            </span>
        </div>
    </div>
    <div class="panel-content center-content"
         [ngClass]="{'print-content': maintenanceService?.selectedOption === 'MANUFACTURER_SPEC', 'setting' : !maintenanceService.showPdf}">
        <ng-container>
            <div class="maintenance-data_title">
                <h1>
                    <strong>{{ 'MAINTENANCE.TECRMI_CALCULATION' | translate}}</strong>
                </h1>
            </div>
            <div class="row" *ngIf="customerDataService.getDataHolder()">
                <div class="col-6">
                    <div class="row">
                        <div class="col">
                            <span class="headerType">{{'PRINT_VIEW.CUSTOMER_DATA' | translate}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <app-material-form-input [text]="customerDataService.getCustomerFirstName()"
                                                     (textChange)="customerDataService.setCustomerFirstName($event)"
                                                     [label]="'PRINT_VIEW.FORM.FIRSTNAME' | translate"></app-material-form-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <app-material-form-input [text]="customerDataService.getCustomerLastName()"
                                                     (textChange)="customerDataService.setCustomerLastName($event)"
                                                     [label]="'PRINT_VIEW.FORM.LASTNAME' | translate"></app-material-form-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <app-material-form-input [text]="customerDataService.getOrderNumber()"
                                                     (textChange)="customerDataService?.setOrderNumber($event)"
                                                     [label]="'PRINT_VIEW.FORM.ORDER_NO' | translate"
                                                     [(ngModel)]="customerDataService?.getDataHolder().reference"
                                                     name="orderNumber" ngDefaultControl
                            ></app-material-form-input>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <app-material-form-input [text]="customerDataService.getCustomerPhone()"
                                                     (textChange)="customerDataService?.setCustomerPhone($event)"
                                                     [label]="'USER_PANEL.PHONE' | translate"></app-material-form-input>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="row">
                        <div class="col">

                            <span class="headerType">{{'PRINT_VIEW.CAR_DATA' | translate}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <app-material-form-input [text]="customerDataService.getNumberplate()"
                                                     (textChange)="customerDataService.setNumberplate($event)"
                                                     [label]="'PRINT_VIEW.FORM.NUMBER_PLATE' | translate" ></app-material-form-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <app-material-form-input [text]="customerDataService?.getMileage()"
                                                     [type]="'number'"
                                                     (textChange)="customerDataService.setMileage($event)"
                                                     [label]="'PRINT_VIEW.FORM.MILEAGE' | translate"></app-material-form-input>
                        </div>
                    </div>
                    <div class="row pt-4">
                        <div class="col">
                            <app-checkbox [labelText]="'MAINTENANCE.SHOW_LETTER_HEAD' | translate" (onChange)="maintenanceService.showLetterHead = $event" [isChecked]="maintenanceService.showLetterHead"></app-checkbox>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="row">
                        <div class="col">
                            <button class="base-btn" (click)="maintenanceService?.getTecRMICalculation()"
                                    [ngClass]="{'disabled': (!(maintenanceService?.posByCar?.length > 0) && location.path().indexOf('/maintenance') > -1)}">
                                <i class="fa fa-refresh"></i>
                                <span>{{'MAINTENANCE.UPDATE' | translate}}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <hr *ngIf="maintenanceService?.tecRMICalculationUrl">
            <div class="row" *ngIf="showTecRMICalculation()">
                <div class="col" style="height: 800px">
                    <iframe width="100%" height="100%" [src]="maintenanceService?.tecRMICalculationUrl | iFrame"
                            id="tecrmicalculation"
                            onLoad="this.style.height=(this.contentDocument.body.scrollHeight + 30) + 'px';"></iframe>
                </div>
            </div>
            <div *ngIf="!showTecRMICalculation()">
                <p>
                    <ngb-alert [dismissible]="false" [type]="'danger'">
                        <i class="fa fa-times"></i>
                        <span> {{'MAINTENANCE.NO_SELECTED_MAINTENANCE' | translate}}</span>
                    </ngb-alert>
                </p>
            </div>
        </ng-container>
    </div>
</div>

