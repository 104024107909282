<div class="add-custom-article" *ngIf="configService.getActiveForName('EXTENDED_SETTINGS', 'FREE_ARTICLES')">
    <div class="panel-content center-content">
        <!--type-->
        <label class="font-weight-bold">{{'CART.TEMPLATE_TYPE' | translate}}</label>
        <div class="row">
            <div class="col">
                <button class="custom-article-type-button"
                        [ngClass]="{'active': freePosition?.type === 'AW'}" (click)="selectType('AW')">
                    <i class="icon-Arbeitsposition"></i>
                    <span>{{'TEMPLATE.WORK' | translate}}</span>
                </button>
                <button class="custom-article-type-button"
                        [ngClass]="{'active': freePosition?.type === 'ARTICLE'}" (click)="selectType('ARTICLE')">
                    <i class="icon-Artikelposition"></i>
                    <span>{{'TEMPLATE.ARTICLE' | translate}}</span>
                </button>
                <button class="custom-article-type-button"
                        [ngClass]="{'active': freePosition?.type === 'TEXT'}" (click)="selectType('TEXT')">
                    <i class="icon-Textposition"></i>
                    <span>{{'TEMPLATE.TEXT' | translate}}</span>
                </button>
            </div>
        </div>
        <ng-container *ngIf="mockLoadingForDom">
            <div class="d-flex justify-content-center align-items-center h-25">
                <i class="fa fa-spinner"
                   [ngClass]="{'fa-pulse': !helperService.browserIsEdge() && !helperService.browserIsIE()}"></i>
            </div>
        </ng-container>
        <ng-container *ngIf="!mockLoadingForDom">
            <div class="row" *ngIf="freePosition.type?.length > 0">
                <div class="col-10">
                    <div class="custom-dropdown clickable" *ngIf="freePosition.type == 'AW'">
                        <select class="clickable" [(ngModel)]="activePosition" (change)="takeValue(customPositionsAW)"
                                (click)="takeValue(customPositionsAW)">
                            <option disabled selected value="null">{{'CART.SELECT_TEMPLATE' | translate}}</option>
                            <option *ngFor="let position of customPositionsAW" [value]="position.id">{{position.ipc + ' ' +
                            position.description }}
                            </option>
                        </select>
                    </div>
                    <div class="custom-dropdown clickable" *ngIf="freePosition.type == 'ARTICLE'">
                        <select class="clickable" [(ngModel)]="activePosition" (change)="takeValue(customPositionsARTICLE)">
                            <option disabled selected value="null">{{'CART.SELECT_TEMPLATE' | translate}}</option>
                            <option *ngFor="let position of customPositionsARTICLE"
                                    [value]="position.id">{{position.ipc + ' ' +
                            position.description }}
                            </option>
                        </select>
                    </div>
                    <div class="custom-dropdown clickable" *ngIf="freePosition.type == 'TEXT'">
                        <select class="clickable" [(ngModel)]="activePosition" (change)="takeValue(customPositionsTEXT)">
                            <option disabled selected value="null">{{'CART.SELECT_TEMPLATE' | translate}}</option>
                            <option *ngFor="let position of customPositionsTEXT"
                                    [value]="position.id">{{position.ipc + ' ' +
                            position.description }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-2 dropdown-buttons clickable">
            <span (click)="deleteValue()" *ngIf="!(freePosition?.clubId > 0)">
                <i class="fa fa-trash-o"></i>
            </span>
                </div>
            </div>
            <!--new article-->
            <div class="row" *ngIf="freePosition.type?.length > 0">
                <div class="col">
                    <div class="row">
                        <div class="col">
                            <form [formGroup]="customArticlesForm" (ngSubmit)="addToCart()">
                                <label class="font-weight-bold">{{'GENERAL.ARTICLE' | translate}}</label>
                                <app-material-form-input [text]="freePosition.ipc"
                                                         name="ipcForm"
                                                         [showValidation]="true"
                                                         (textChange)="freePosition.ipc = $event; freePosition.id = ''"
                                                         [label]="'CART.ADD_ARTICLE_POPUP.NUMBER' | translate">
                                </app-material-form-input>
                                <app-material-form-input [text]="freePosition.description"
                                                         name="descriptionForm"
                                                         (textChange)="freePosition.description = $event; freePosition.id = ''"
                                                         [label]="'CART.ADD_ARTICLE_POPUP.DESCRIPTION' | translate">
                                </app-material-form-input>
                                <div class="row" *ngIf="freePosition?.type === 'ARTICLE'">
                                    <div class="col-6">
                                            <fieldset class="material-form-input show-validation" *ngIf="!genArtLoading">
                                                <input list="genarts" name="genarts" id="genart"
                                                       [ngClass]="{'invalid-border': genArtValidationError}"
                                                       (change)="setGenArt($event.target.value)"/>
                                                <datalist id="genarts">
                                                    <option *ngFor="let genart of genArts"
                                                            [value]="genart.description">{{genart.description}}</option>
                                                </datalist>
                                                <label>{{'CART.ADD_ARTICLE_POPUP.GENART' | translate}}</label>
                                            </fieldset>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <app-material-form-input [text]="freePosition.quantity"
                                                                 name="quantityForm"
                                                                 [showValidation]="true"
                                                                 [type]="'number'"
                                                                 [step]="0.01"
                                                                 (textChange)="checkValid($event); freePosition.id = ''"
                                                                 [label]="'CART.ADD_ARTICLE_POPUP.QUANTITY' | translate">
                                        </app-material-form-input>
                                    </div>
                                    <div class="col dropdown-padding-top"
                                         *ngIf="freePosition.type === 'ARTICLE' || freePosition.type === 'TEXT'">
                                        <div class="custom-dropdown clickable no-margin">
                                            <select class="clickable" [(ngModel)]="freePosition.quantityUnit"
                                                    [ngModelOptions]="{standalone: true}">
                                                <option selected [value]="'PIECE'">{{'CART.PIECE' | translate}}</option>
                                                <option [value]="'LITER'">{{'CART.LITER' | translate}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col dropdown-padding-top" *ngIf="freePosition.type === 'AW'">
                                        <div class="custom-dropdown clickable no-margin">
                                            <select class="clickable" [(ngModel)]="freePosition.quantityUnit"
                                                    [ngModelOptions]="{standalone: true}">
                                                <option selected [value]="'HOUR'">{{'CART.HOUR_FULL' | translate}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <app-material-form-input [text]="freePosition.sellOutNetPerPiece"
                                                     *ngIf="freePosition.type === 'ARTICLE' || freePosition.type === 'TEXT'"
                                                     (textChange)="freePosition.sellOutNetPerPiece = $event; freePosition.id = ''"
                                                     [step]="0.01"
                                                     [type]="'number'"
                                                     [label]="'CART.ADD_ARTICLE_POPUP.SINGLE_PRICE' | translate">
                            </app-material-form-input>
                            <div class="col dropdown-padding-top type-aw" *ngIf="freePosition.type === 'AW' && firmData">
                                <div class="custom-dropdown clickable no-margin">
                                    <select class="clickable" [(ngModel)]="freePosition.sellOutNetPerPiece">
                                        <option [value]="0" selected>{{'GENERAL.PLEASE_SELECT' | translate}}</option>
                                        <option [value]="wageRates.hourlyWageRate">{{wageRates.hourlyWageRate | currency:helperService.getCurrency(wageRates.hourlyWageRate)}} {{'USER_PANEL.HOURLY_RATE' | translate}}</option>
                                        <option [value]="wageRates.hourlyWageRateChassis">{{wageRates.hourlyWageRateChassis | currency:helperService.getCurrency(wageRates.hourlyWageRateChassis)}} {{'USER_PANEL.HOURLY_RATE_CHASSIS' | translate}}</option>
                                        <option [value]="wageRates.hourlyWageRateElectronic">{{wageRates.hourlyWageRateElectronic | currency:helperService.getCurrency(wageRates.hourlyWageRateElectronic)}} {{'USER_PANEL.HOURLY_RATE_ELECTRONIC' | translate}}</option>
                                        <option [value]="wageRates.hourlyWageRateMechanic">{{wageRates.hourlyWageRateMechanic | currency:helperService.getCurrency(wageRates.hourlyWageRateMechanic)}} {{'USER_PANEL.HOURLY_RATE_MECHANIC' | translate}}</option>
                                    </select>
                                </div>
                            </div>

                            <app-checkbox [isChecked]="freePosition.save"
                                          (onChange)="freePosition.save = $event"
                                          [labelText]="'CART.TEMPLATE' | translate"
                                          *ngIf="!freePosition?.id || freePosition?.id === ''"></app-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <!--buttons-->
            <div class="row" *ngIf="freePosition.type?.length > 0">
                <div class="col buttons">
                    <button class="base-btn pull-right" routerLink="/cart">
                        <span>{{'PROMPTS.BUTTONS.CANCEL' | translate}}</span>
                    </button>
                    <button class="base-btn no-top-margin" [ngClass]="{'success': validate()}" [type]="'submit'"
                            (click)="addToCart()" *ngIf="cartService?.isNewestCart()"
                            [disabled]="!validate() || genArtValidationError">
                        <span>{{'PROMPTS.BUTTONS.ADD' | translate}}</span>
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <app-error-handler *ngIf="errorHandlerService.hasError"></app-error-handler>
                </div>
            </div>
        </ng-container>
    </div>
</div>



