import {EventEmitter, Injectable} from '@angular/core';
import {IData} from '../interfaces/data';
import {CartRestService} from '../../cart/services/cart.rest.service';
import {IEndCustomerData} from '../../cart/interfaces/cart';
import {HelperService} from './helper.service';

@Injectable()
export class CustomerDataService {

    private _dataHolder: IData = {
        mileage: undefined,
        numberplate: '',
        customerLastName: '',
        customerFirstName: '',
        customerAddress: '',
        customerCity: '',
        customerEmail: '',
        customerPhone: '',
        customerZip: '',
        orderNumber: '',
        year: undefined,
        hsn: '',
        tsn: '',
        vsn: '',
        vin: '',
        typenumber: '',
        natcode: '',
        kTypeId: undefined,
        serviceDate: '',
        entryPoint: ''
    };

    public endCustomerInformationChanged: EventEmitter<any> = new EventEmitter<any>();


    constructor(private cartRestService: CartRestService,
                private helperService: HelperService) {
    }

    public resetDataHolder(): void {
        this._dataHolder = {
            mileage: undefined,
            numberplate: '',
            customerLastName: '',
            customerFirstName: '',
            customerAddress: '',
            customerCity: '',
            customerEmail: '',
            customerPhone: '',
            customerZip: '',
            orderNumber: '',
            year: undefined,
            hsn: '',
            tsn: '',
            vsn: '',
            natcode: '',
            vin: '',
            serviceDate: '',
            typenumber: '',
            kTypeId: undefined,
            entryPoint: ''
        };
    }

    public getDataHolder(): IData {
        return this._dataHolder;
    }

    public getMileage(): number {
        return this._dataHolder.mileage;
    }

    public getNumberplate(): string {
        return this._dataHolder.numberplate;
    }

    public getCustomerLastName(): string {
        return this._dataHolder.customerLastName;
    }

    public getCustomerFirstName(): string {
        return this._dataHolder.customerFirstName;
    }

    public getCustomerAddress(): string {
        return this._dataHolder.customerAddress;
    }

    public getCustomerCity(): string {
        return this._dataHolder.customerCity;
    }


    public getCustomerZip(): string {
        return this._dataHolder.customerZip;
    }

    public getCustomerPhone(): string {
        return this._dataHolder.customerPhone;
    }

    public getOrderNumber(): string {
        return this._dataHolder.orderNumber;
    }

    public getRegistrationDate(): Date {
        return this._dataHolder.year;
    }

    public getHsn(): string {
        return this._dataHolder.hsn;
    }

    public getTsn(): string {
        return this._dataHolder.tsn;
    }


    public getVin(): string {
        return this._dataHolder.vin;
    }

    public getKTypeId(): number {
        return this._dataHolder.kTypeId;
    }


    public getTypenumber(): string {
        return this._dataHolder.typenumber;
    }

    public getNatcode(): string {
        return this._dataHolder.natcode;
    }

    public getEntryPoint(): string {
        return this._dataHolder.entryPoint;
    }

    public setDataHolder(dataHolder: IData): void {
        if (dataHolder && dataHolder !== this._dataHolder) {
            this._dataHolder = dataHolder;
            this.setEndCustomerInformation();
        }
    }

    public setMileage(mileage: number): void {
        if (mileage && this._dataHolder.mileage !== mileage) {
            this._dataHolder.mileage = mileage;
        }
    }

    public setNumberplate(numberplate: string): void {
        if (numberplate && this._dataHolder.numberplate !== numberplate) {
            this._dataHolder.numberplate = numberplate;
        }
    }

    public setCustomerLastName(customerLastName: string): void {
        if (customerLastName && this._dataHolder.customerLastName !== customerLastName) {
            this._dataHolder.customerLastName = customerLastName;
        }
    }

    public setCustomerFirstName(customerFirstName: string): void {
        if (customerFirstName && this._dataHolder.customerFirstName !== customerFirstName) {
            this._dataHolder.customerFirstName = customerFirstName;
        }
    }

    public setCustomerAddress(customerAddress: string): void {
        if (customerAddress && this._dataHolder.customerAddress !== customerAddress) {
            this._dataHolder.customerAddress = customerAddress;
        }
    }

    public setCustomerCity(customerCity: string): void {
        if (customerCity && this._dataHolder.customerCity !== customerCity) {
            this._dataHolder.customerCity = customerCity;
        }
    }

    public setCustomerEmail(customerEmail: string): void {
        if (customerEmail && this._dataHolder.customerEmail !== customerEmail) {
            this._dataHolder.customerEmail = customerEmail;
        }
    }

    public setCustomerZip(customerZip: string): void {
        if (customerZip && this._dataHolder.customerZip !== customerZip) {
            this._dataHolder.customerZip = customerZip;
        }
    }

    public setCustomerPhone(customerPhone: string): void {
        if (customerPhone && this._dataHolder.customerPhone !== customerPhone) {
            this._dataHolder.customerPhone = customerPhone;
        }
    }

    public setOrderNumber(orderNumber: string): void {
        if (orderNumber && this._dataHolder.orderNumber !== orderNumber) {
            this._dataHolder.orderNumber = orderNumber;
        }
    }

    public setRegistrationDate(year: Date): void {
        if (year && this._dataHolder.year !== year) {
            this._dataHolder.year = year;
        }
    }

    public setHsn(hsn: string): void {
        if (hsn && this._dataHolder.hsn !== hsn) {
            this._dataHolder.hsn = hsn;
        }
    }

    public setTsn(tsn: string): void {
        if (tsn && this._dataHolder.tsn !== tsn) {
            this._dataHolder.tsn = tsn;
        }
    }

    public setVsn(vsn: string): void {
        if (vsn && this._dataHolder.vsn !== vsn) {
            this._dataHolder.vsn = vsn;
        }
    }

    public setVin(vin: string): void {
        if (vin && this._dataHolder.vin !== vin) {
            this._dataHolder.vin = vin;
        }
    }

    public setTypenumber(typenumber: string): void {
        if (typenumber && this._dataHolder.typenumber !== typenumber) {
            this._dataHolder.typenumber = typenumber;
        }
    }

    public setNatcode(natcode: string): void {
        if (natcode && this._dataHolder.natcode !== natcode) {
            this._dataHolder.natcode = natcode;
        }
    }

    public setKTypeId(kTypeId: number): void {
        if (kTypeId && this._dataHolder.kTypeId !== kTypeId) {
            this._dataHolder.kTypeId = kTypeId;
        }
    }

    public setServiceDate(serviceDate: string): void {
        if (serviceDate && this._dataHolder.serviceDate !== serviceDate) {
            this._dataHolder.serviceDate = serviceDate;
        }
    }

    public setEntryPoint(entryPoint: string): void {
        if (entryPoint && this._dataHolder.entryPoint !== entryPoint) {
            this._dataHolder.entryPoint = entryPoint;
        }
    }

    private setEndCustomerInformation(): void {
        const endCustomerInformation: IEndCustomerData = {
            firstName: this._dataHolder.customerFirstName,
            lastName: this._dataHolder.customerLastName,
            email: this._dataHolder.customerEmail,
            street: this._dataHolder.customerAddress,
            zip: this._dataHolder.customerZip,
            city: this._dataHolder.customerCity,
            phone: this._dataHolder.customerPhone,
            hsn: this._dataHolder.hsn,
            tsn: this._dataHolder.tsn,
            vin: this._dataHolder.vin,
            vsn: this._dataHolder.vsn,
            kTypeId: this._dataHolder.kTypeId,
            numberplate: this._dataHolder.numberplate,
            mileage: this._dataHolder.mileage,
            reference: this._dataHolder.orderNumber,
            registrationDate: this._dataHolder.year,
            serviceDate: this._dataHolder.serviceDate,
        };
        this.cartRestService.setEndCustomerInformation(endCustomerInformation).subscribe(
            () => {
                this.endCustomerInformationChanged.emit()
            },
            () => {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_SAVE_ENDCUSTOMER_INFORMATION', 'error');
            })
    }

}
