<div class="calculation">
    <div class="heading blue">
        <span class="clickable" routerLink="/company-admin">
            <i class="fa fa-th" aria-hidden="true"></i>
            {{ 'ADMIN_PANEL.OVERVIEW' | translate }}
        </span>
        <span>
            <i class="fa fa-caret-right"></i>
            {{ 'FIRM_CALCULATION.CALCULATION' | translate }}
        </span>
    </div>
    <div class="panel-content center-content white-background firm-edit-calculation">
        <div class="row">
            <div class="col-12 col-sm-6">
                <div class="row mb-2">
                    <div class="col d-flex">
                        <app-toggle-switch [isChecked]="calculation.type === 'AFTER_MARKET'"
                                           [textLeft]="'FIRM_CALCULATION.OE_ARTICLE' | translate"
                                           [textRight]="'FIRM_CALCULATION.AFTER_MARKET' | translate"
                                           (onChange)="toggleType($event)"></app-toggle-switch>
                    </div>
                </div>
                <div class="row">
                    <div class="col-11 d-flex position-relative">
                        <div class="w-75 mr-2">
                            <div class="custom-dropdown position-relative">
                                <select (change)="toggleValueType($event)">
                                    <option *ngFor="let vTtype of VALUE_TYPES" [value]="vTtype"
                                            [selected]="vTtype === calculation.valueType">{{ ('FIRM_CALCULATION.' + (vTtype | uppercase) | translate) }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="w-25 d-flex ">
                            <app-material-form-input
                                    [pattern]=" '^[1-9][0-9]*$' "
                                    [type]="'number'"
                                    [validationErrorMessage]="'VALIDATION.ERROR_NUMBER_HIGHER_ZERO' | translate"
                                    [showValidation]="true"
                                    [(text)]="calculation.value">
                            </app-material-form-input>
                        </div>
                        <span class="fixed-symbol">
                            {{ calculation.valueType === 'PERCENTAGE_MARKUP' ? '%' : helperService.getCurrencySymbol(undefined) }}
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        {{ 'FIRM_CALCULATION.SUPPLIER' | translate }}
                    </div>
                </div>
                <div class="row" *ngIf="calculation.type === 'AFTER_MARKET' || calculation.type === 'OE_ARTICLE'">
                    <div class="col">
                        <app-material-form-input [(text)]="supplierSearchTerm"
                                                 [placeholder]="'HOME.SEARCH' | translate">
                        </app-material-form-input>
                    </div>
                </div>
                <div class="row supp-list-wrapper" *ngIf="calculation.type === 'AFTER_MARKET'">
                    <div class="col">
                        <div class="row checkbox mb-3">
                            <div class="col">
                                <label class="checkboxLabel" for="all-supp">
                                    {{ 'FIRM_CALCULATION.ALL_SUPPLIERS' | translate }}
                                </label>
                                <input (change)="toggleChangeAllSuppliers($event)"
                                       [checked]="areAllBrandIdsInDataSuppliers()"
                                       class="checkbox" type="checkbox" name="all-supp" id="all-supp"/>
                            </div>
                        </div>
                        <app-default-loading-page *ngIf="suppsLoadingToggleAll$| async"></app-default-loading-page>
                        <ng-container *ngIf="!(suppsLoadingToggleAll$ |async)">
                            <div class="row checkbox" *ngFor="let supp of filteredSuppliers">
                                <div class="col">
                                    <label class="checkboxLabel">{{ supp.name }}</label>
                                    <input type="checkbox" value="{{supp}}"
                                           [checked]="calculation.brandIds.includes(supp.id)"
                                           (change)="toggleBrandId(supp.id, $event)"/>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="row supp-list-wrapper" *ngIf="calculation.type === 'OE_ARTICLE'">
                    <div class="col">
                        <div class="row checkbox mb-3">
                            <div class="col">
                                <label class="checkboxLabel" for="all-supp-oe">
                                    {{ 'FIRM_CALCULATION.ALL_SUPPLIERS' | translate }}
                                </label>
                                <input (change)="toggleChangeAllSuppliers($event)"
                                       [checked]="areAllBrandIdsInDataSuppliers()"
                                       class="checkbox" type="checkbox" name="all-supp-oe" id="all-supp-oe"/>
                            </div>
                        </div>
                        <app-default-loading-page *ngIf="suppsLoadingToggleAll$| async"></app-default-loading-page>
                        <ng-container *ngIf="!(suppsLoadingToggleAll$ |async)">
                            <div class="row checkbox" *ngFor="let supp of filteredSuppliers">
                                <div class="col">
                                    <label class="checkboxLabel">{{ supp.name }}</label>
                                    <input type="checkbox" value="{{supp}}"
                                           [checked]="calculation.brandIds.includes(supp.id)"
                                           (change)="toggleBrandId(supp.id, $event)"/>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>

            </div>
            <div class="col-12 col-sm-6">
                <div class="gen-art-list-wrapper"
                     *ngIf="(articleTreeCar$ | async)?.tree && (articleTreeUniversal$ | async)?.tree">
                    <div class="mb-3 border-bottom">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button (click)="changeTreeType('P')"
                                        class="nav-link position-relative pr-4 mt-2"
                                        [ngClass]="{'active': (treeType$ | async) === 'P'}"
                                        type="button" role="tab">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         stroke-width="1.5"
                                         stroke="currentColor" class="hero-icon  size-2"
                                         [ngClass]="{'highlight-color': (treeType$ | async) === 'P'}">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M8.25 18.75a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 0 1-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 0 0-3.213-9.193 2.056 2.056 0 0 0-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 0 0-10.026 0 1.106 1.106 0 0 0-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12"/>
                                    </svg>
                                    <span class="fw-bold lead"
                                          [ngClass]="{'highlight-color': (treeType$ | async) === 'P'}">
                                          {{ 'FIRM_CALCULATION.VEHICLE_RELATED' | translate }}
                                    </span>
                                    <span *ngIf="hasGenArtsInTree('P')" class="dot-circle position-absolute"></span>
                                </button>
                            </li>
                            <li class="nav-item" role="presentation" *ngIf="calculation.type === 'AFTER_MARKET'">
                                <button (click)="changeTreeType('U')"
                                        class="nav-link position-relative pr-4 mt-2"
                                        [ngClass]="{'active': (treeType$ | async) === 'U'}"
                                        type="button" role="tab">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         stroke-width="1.5"
                                         stroke="currentColor" class="hero-icon  size-2"
                                         [ngClass]="{'highlight-color': (treeType$ | async) === 'U'}">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M3.75 6A2.25 2.25 0 0 1 6 3.75h2.25A2.25 2.25 0 0 1 10.5 6v2.25a2.25 2.25 0 0 1-2.25 2.25H6a2.25 2.25 0 0 1-2.25-2.25V6ZM3.75 15.75A2.25 2.25 0 0 1 6 13.5h2.25a2.25 2.25 0 0 1 2.25 2.25V18a2.25 2.25 0 0 1-2.25 2.25H6A2.25 2.25 0 0 1 3.75 18v-2.25ZM13.5 6a2.25 2.25 0 0 1 2.25-2.25H18A2.25 2.25 0 0 1 20.25 6v2.25A2.25 2.25 0 0 1 18 10.5h-2.25a2.25 2.25 0 0 1-2.25-2.25V6ZM13.5 15.75a2.25 2.25 0 0 1 2.25-2.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25h-2.25A2.25 2.25 0 0 1 13.5 18v-2.25Z"/>
                                    </svg>
                                    <span class="fw-bold lead"
                                          [ngClass]="{'highlight-color': (treeType$ | async) === 'U'}">
                                          {{ 'FIRM_CALCULATION.UNIVERSAL_RELATED' | translate }}
                                    </span>
                                    <span *ngIf="hasGenArtsInTree('U')" class="dot-circle position-absolute"></span>
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <label class="form-check-label ml-3" for="all">
                            {{ 'FIRM_CALCULATION.ALL_ARTICLES' | translate }}
                        </label>
                        <input (change)="toggleChangeAllGenArts($event)"
                               [checked]="(treeType$ | async) === 'U' ? (calculation.allGenArtsUniversalRelated ) : (calculation.allGenArtsCarRelated)"
                               class="form-check-input ml-2" type="checkbox" name="all" id="all"/>
                        <hr class="w-25 mt-2 mb-4 ml-2 bg-light border-0 rounded">
                    </div>
                    <app-default-loading-page *ngIf="genartLoadingToggleAll$| async"></app-default-loading-page>
                    <ng-container *ngIf="!(genartLoadingToggleAll$| async)">
                        <div *ngIf="(treeType$ | async) === 'P'">
                            <app-tree [tree]="(articleTreeCar$ | async)?.tree"
                                      (nodeChanged)="genArtsChanged($event)"></app-tree>
                        </div>
                        <div *ngIf="(treeType$ | async) === 'U'">
                            <app-tree [tree]="(articleTreeUniversal$ | async)?.tree"
                                      (nodeChanged)="genArtsChanged($event)"></app-tree>
                        </div>
                    </ng-container>
                </div>

            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col">
                        <div class="buttons">
                            <button class="base-btn pull-right mr-3 button-top-margin"
                                    (click)="closeEditingCalculation()">{{ 'PROMPTS.BUTTONS.GO_BACK' | translate }}
                            </button>
                            <button class="base-btn success mr-3"
                                    [disabled]="calculation.genArtIds.length === 0 ||calculation.brandIds.length === 0 || calculation.value <= 0"
                                    (click)="updateCalculation()">{{ 'PROMPTS.BUTTONS.SAVE' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

